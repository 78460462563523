import { Button, Form, Input } from 'antd'
import React from 'react'
import { t } from '../../../helpers/translation'
import styles from './styles'

const PasswordUpdate = () => {
  return (
    <div style={styles.container}>
      <Form.Item
        name="password"
        label={t("new_user.new_password")}
        rules={[
          {
            required: true,
            message: t("common.validates.required", { field: t("new_user.new_password") })
          },
          {
            min: 8,
            message: t("new_user.password_length"),
          },
        ]}
        hasFeedback
      >
        <Input.Password style={styles.inputHeight} />
      </Form.Item>
      <Form.Item
        name="confirm"
        label={t("new_user.confirm_new_password")}
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: t("new_user.confirm_new_password_msg"),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error(t("new_user.password_not_match")));
            },
          }),
        ]}
      >
        <Input.Password style={styles.inputHeight} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={styles.button} className="primary-btn-bg">
          {t('account_setting.save')}
        </Button>
      </Form.Item>
    </div>
  )
}

export { PasswordUpdate }
