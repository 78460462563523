import axios from "axios"

const makeRequest = axios

export const validateEcCouponCode = async (data) =>
  makeRequest({
    method: "post",
    url: `/api/ec_coupons/validate_ec_coupon_code`,
    data: data
  })

export const validateQuantity = async (data) =>
  makeRequest({
    method: "post",
    url: `/api/order/check_monthly_quantity_limit`,
    data: data,
  })

export const validatePublishedProducts = async (data) =>
  makeRequest({
    method: "post",
    url: `/api/order/check_published_products`,
    data: data,
  })