import React, { useState } from "react"
import SnsUrls from '../SnsUrls'
import { Row, Col, Card, Typography, Space, Button } from 'antd'
import { get } from 'lodash'
import { t } from '../../helpers/translation'
import { addStylist, clearBooking } from '../../helpers/bookings'
import './styles.scss'

const header = document.getElementById('mr--header')
header.classList.add("mr--header__transparent");

const { Text } = Typography;
const { Paragraph } = Typography;

export const StylistEcInfo = ({ stylist }) => {
  const [ellipsis, setEllipsis] = useState(true);

  React.useEffect(() => {
    document.addEventListener(
      'scroll',
      (event) => {
        if (document.documentElement.scrollTop == 0) {
          header.classList.add("mr--header__transparent");  
        } else {
          header.classList.remove("mr--header__transparent");  
        }
      },
      { passive: true }
    );
  },[])

  const handleBooking = () => {
    clearBooking()
    addStylist(stylist)
    window.location = `/reservation_users/stores/${stylist.storeId}/bookings/schedule`
  }
  
  const disabledReservation = () => {
    return stylist && stylist?.notAllowNomination
  }

  return (
    <div className="stylist-info">
      <Row>
        <Col>
          <img src={get(stylist, 'avatar')} className="stylist-info__image" />
        </Col>
      </Row>
      <Row className="flex flex-row justify-center stylist-info__card-wrapper">
        <Col span={22}>
          <Card className="stylist-info__card">
            <Space direction="vertical" className="w-full">
              <Text className="stylist-info__store-name">{get(stylist, 'store.name')}</Text>
              <Text className="stylist-info__stylist-name">{get(stylist, 'displayName')}</Text>
              <Text className="stylist-info__stylist-good-image text-base font-bold my-4">{get(stylist, 'catchphrase')}</Text>
              <Paragraph ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: t('common.expandEllips') } : false}>{get(stylist, 'comment')}</Paragraph>
              {(get(stylist, 'youtubeUrl') || get(stylist, 'instagramUrl') || get(stylist, 'tiktokUrl') || get(stylist, 'twitterUrl')) && 
                <SnsUrls
                  mode="svg__black"
                  youtubeUrl={get(stylist, 'youtubeUrl')}
                  instagramUrl={get(stylist, 'instagramUrl')}
                  tiktokUrl={get(stylist, 'tiktokUrl')}
                  twitterUrl={get(stylist, 'twitterUrl')}
                />
              }
            </Space>
          </Card>
        </Col>
        <Col span={22}>
          <Space direction="vertical" className="w-full mt-10 mb-5">
            {/* {!disabledReservation() && 
              <Button
                className="btn-secondary btn" onClick={handleBooking}
              >
                {t('staff.checkScheduleBtn')}
              </Button>
            } */}
            {!disabledReservation() && 
            <>
              <Button className="btn-secondary btn" onClick={() => window.location = `/reservation_users/staffs/${stylist.id}`}>
                {t('staff.menus')}
              </Button>
              <Button className="btn-outline btn" onClick={() => window.location = `/reservation_users/staffs/${stylist.id}?tab=ec`}>
                {t('staff.seeRecommendedProductBtm')}
              </Button>
              <Button className="btn-outline btn" onClick={() => window.location = `/reservation_users/stores/${get(stylist, 'storeId')}`}>
                {t('staff.learnBrandBtn')}
              </Button>
            </>}

            {get(stylist, 'externalLinks').map(externalLink => {
                return (
                  <Button className="btn-outline btn external" onClick={() => window.location = `${get(externalLink, 'link')}`}>
                    {get(externalLink, 'label')}
                  </Button>
                )
            })}
          </Space>
        </Col>
      </Row>
    </div>
  )
}

export default StylistEcInfo
