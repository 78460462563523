import React from "react";
import { Typography, Card, Form, notification } from "antd";

import NewForm from "./NewForm";

const NewUser = ({ prefectures, init_data }) => {
  const [form] = Form.useForm();

  const notify_error = (type) => (error) =>
    notification[type]({
      message: window.i18n.t("new_user.new_user_error"),
      description: error,
    });

  const onFinish = (values) => {
    fetch("/users", {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user: values }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success && !redirect) {
          window.location.href = `${window.location.origin}/ec_users`;
        }
        else if (response.success && redirect) {
          window.location.href = `${window.location.origin}/ec_users/orders/new`;
        }
        else {
          notify_error("error")(response.message);
        }
      })
      .catch(notify_error("error"));
  };

  return (
    <>
      <Card>
        <Typography.Title level={3}>
          {window.i18n.t("new_user.new_member_registration")}
        </Typography.Title>
        <NewForm form={form} onFinish={onFinish} prefectures={prefectures} init_data={init_data} />
      </Card>
      <br />
    </>
  );
};

export default NewUser;
