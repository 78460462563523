export const setItem = (name, value) => {
  window.localStorage.setItem(name, JSON.stringify(value));
}

export const getItem = (name) => {
  try {
    const data = window.localStorage.getItem(name);
    return JSON.parse(data)
  } catch (error) {
    console.error(error)
  }
}

export const removeItem = (name) => {
  window.localStorage.removeItem(name)
}