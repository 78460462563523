import numeral from 'numeral';
import { t } from '../helpers/translation'

const rounded = (value) => {
  Math.round(parseFloat(n));
}

const currency = (n, isShowMark = false, currencyCode = '￥', precision = 2) => {
  if (n === null) {
    return null;
  }

  n = Math.round(parseFloat(n));

  return `${currencyCode}${numeral(n).format(`0,0.${'0'.repeat(precision)}`)}`.replace(/(\.0+|0+)$/, '') + `${isShowMark ? '～' : ''}`
};

const pointFormater = (n, precision) => {
  if (n === null) {
    return null;
  }

  n = Math.round(parseFloat(n));
  return `${numeral(n).format(`0,0.${'0'.repeat(precision)}`)} pt`.replace(/(\.0+|0+)$/, '')
}

const convertMinsToHour = (mins) => {
  let hour = Math.floor(mins / 60);
  let min = mins % 60;
  hour = hour < 10 ? '0' + hour : hour; 
  min = min < 10 ? '0' + min : min; 
  return `${t('common.hours', { hour: hour })}${t('common.mins', { min: min })}`;
}

const replaceWord = (value, replacingWord = '.', replaceWith = ',') => {
  return value.toString().replace(replacingWord, replaceWith)
}

export { currency, convertMinsToHour, replaceWord, pointFormater };
