import React from 'react';
import { Row, Col, Typography, Select } from 'antd'

import { yearsList, monthsList, daysList } from '../../helpers/customers'
import styles from './styles.js'

const Dob = ({ month, year, day, setMonth, setYear, setDay }) => {
  const { Option } = Select;
  
  return (
    <Row gutter={[4, 4]}>
      <Col span={8}>
        <Select defaultValue={year} size='large' onSelect={(y) => setYear(y)} className="w-full rounded-input">
          {
            yearsList().map((y, index) => {
              return (
                <Option value={y} key={index}>{y}</Option>
              )
            })
          }
        </Select>
      </Col>
      <Col span={8}>
        <Select defaultValue={month} size='large' onSelect={(m) => setMonth(m)} className="w-full rounded-input">
          {
            monthsList().map((m, index) => {
              return (
                <Option value={m} key={index}>{m}</Option>
              )
            })
          }
        </Select>
      </Col>
      <Col span={8}>
        <Select defaultValue={day} size='large' onSelect={(d) => setDay(d)} className="w-full rounded-input">
          {
            daysList(month, year).map((time, index) => {
              return (
                <Option value={time} key={index}>{time}</Option>
              )
            })
          }
        </Select>
      </Col>
    </Row>
  );
}

export default Dob;
