import React from "react"
import Slider from '../../components/ProductDetail/Slider'
import RelatedProducts from '../../components/ProductDetail/RelatedProducts'
import ProductContent from '../../components/ProductDetail/ProductContent'

const ProductDetail = ({ product, related_products, current_user, average_rating, current_user_rating, rating_users_count, product_variants }) => {
  const images = product.images
  
  if(product.image) {
    images.unshift({ original: product.image, thumbnail: product.image })
  }

  return (
    <>
      <Slider images={images} />
      <ProductContent 
        product={product} 
        current_user={current_user} 
        average_rating={average_rating} 
        current_user_rating={current_user_rating} 
        rating_users_count={rating_users_count}  
        product_variants={product_variants}
      />
      { related_products.length > 0 &&
        <RelatedProducts products={related_products} />
      }
    </>
  )
}

export default ProductDetail
