import React from 'react'
import { StylistCard } from '../../reservation_components/BookingInfo/StylistCard';
import { NavStep } from '../../reservation_components/BookingInfo/NavStep';
import { CouponMenu } from '../../reservation_components/BookingInfo/CouponMenu';
import { Schedule } from '../../reservation_components/BookingInfo/Schedule';
import { getStylist, getCoupon, getMenus, getSchedule, addStore, objectToQueryString } from '../../helpers/bookings'
import { Button } from 'antd';
import { t } from '../../helpers/translation';
import {fetchMenus, getCoupons} from "../../services/coupons";

const BookingSchedule = ({ store, staff_ids }) => {
  const [stylist, setStylist] = React.useState(getStylist())
  const [coupons, setCoupons] = React.useState(null)
  const [selectedMenus, setSelectedMenus] = React.useState([])
  const [schedule, setSchedule] = React.useState(null)

  React.useEffect(() => {
    addStore(store)
    setStylist(getStylist());
    const storageCoupons = getCoupon() || []
    const storageCouponIds = _.map(storageCoupons, (cp) => (cp.id))
    getCoupons({ store_id: store.id, staff_id: stylist?.id, ids: storageCouponIds, find_by_ids: true }).then((res) => {
      setCoupons(res.data);
    })
    const storageMenus = getMenus() || []
    const storageMenuIds = _.map(storageMenus, (mn) => (mn.id))
    fetchMenus({ store_id: store.id, staff_id: stylist?.id, ids: storageMenuIds, find_by_ids: true }).then((res) => {
      setSelectedMenus(res.data);
    })
    onChangeMenu();
  }, [])

  const onChangeMenu = () => {
    setSelectedMenus(getMenus());
  }

  const onChangeSchedule = () => {
    setSchedule(getSchedule());
  }

  const onGoConfirm = () => {
    window.location.href = `/reservation_users/stores/${store.id}/bookings/confirm`;
  }

  const onAddMenu = () => {
    if (stylist) {
      window.location.href = `/reservation_users/staffs/${stylist.id}`
    } else {
      const couponIds = coupons ? _.map(coupons, (cp) => (cp.id)) : []
      const menuIds = selectedMenus ? _.map(selectedMenus, (mn) => (mn.id)) : []
      const queryString = objectToQueryString({ 'coupon_ids[]': couponIds, 'menu_ids[]': menuIds, filter_data: true })
      window.location.href = `/reservation_users/stores/${store.id}?active_tab=3&from_booking_schedule=true&${queryString}`
    }
  }

  return (
    <>
      <NavStep step={2} title={t('booking.selectScheduleTitle')}/>
      <CouponMenu coupons={coupons} selectedMenus={selectedMenus} storeId={store.id} updateCouponList={setCoupons} updateMenuList={setSelectedMenus}/>
      <div className="my-4 mx-4">
        <Button className="btn btn-outline" onClick={onAddMenu}>
          {t('booking.selectAdditionalMenu')}
        </Button>
      </div>
      <StylistCard stylist={stylist} store={store} coupons={coupons} menus={selectedMenus} />
      <Schedule staffCapacity={store.staffCapacity} storeId={store.id} stylist={stylist} selectedMenus={selectedMenus} coupons={coupons} onChangeSchedule={onChangeSchedule} staffIds={staff_ids} />
      <div className="px-4 sticky bottom-20 w-full z-10 mb-8">
        <Button className="btn-default btn" onClick={onGoConfirm} disabled={!schedule || (!(selectedMenus || coupons)) || (stylist && stylist?.notAllowNomination)}>
          {t('booking.bookingBtn')}
        </Button>
      </div>
    </>
  )
}

export default BookingSchedule
