import React from "react"
import { Col, Row, Typography, Button } from "antd"
import { get } from 'lodash'
import styles from './styles'

const Review = ({ product }) => {
  if (!get(product, 'mainStaffComment.comment')) {
    return null
  }
  return (
    <Row style={styles.row} justify="space-between">
      <Col className="w-1/5">
        <img src={get(product, 'mainStaffComment.staff.avatar')} style={styles.avatar} />
      </Col>
      <Col style={styles.contentWrapper} className="w-4/5">
        <img src="/images/quote.jpeg" style={styles.quoteImage} />
        <Typography style={styles.content} className="w-5/5">
          {get(product, 'mainStaffComment.comment')}
        </Typography>
      </Col>
    </Row>
  )
}

export default Review
