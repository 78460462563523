import { Col, Row, Typography } from "antd";
import React from "react";
import brandSvg from "../../../assets/images/brand.svg";
import categorySvg from "../../../assets/images/category.svg";
import { t } from "../../helpers/translation";
import styles from "./styles.js";

const HomeProductSearch = () => {
	const handleRedirection = (search) => {
		if (search === "brand") {
			window.location.href = `${window.location.origin}/ec_users/products/search?selected_type=brand`;
		} else if (search === "category") {
			window.location.href = `${window.location.origin}/ec_users/products/search?selected_type=category`;
		}
	};

	return (
		<>
			<Row style={styles.typeRow}>
				<Col style={styles.typeCol}>
					<button onClick={() => handleRedirection("category")}>
						<div style={styles.defaultButton}>
							<img src={categorySvg} />
						</div>
					</button>
					<Typography>{t("product_search.home_search_by_category")}</Typography>
				</Col>
				<Col style={styles.typeCol}>
					<button onClick={() => handleRedirection("brand")}>
						<div style={styles.defaultButton}>
							<img src={brandSvg} />
						</div>
					</button>
					<Typography>{t("product_search.home_find_by_brand")}</Typography>
				</Col>
			</Row>
		</>
	);
};

export default HomeProductSearch;
