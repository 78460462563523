import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const LightBox = ({ images, currentIndex, handleChange, open, handleOpen }) => {
  return (
    <>
      {open && (
        <Lightbox
          mainSrc={images[currentIndex]}
          nextSrc={images[(currentIndex + 1) % images.length]}
          prevSrc={images[(currentIndex + images.length - 1) % images.length]}
          onCloseRequest={() => handleOpen(false)}
          onMovePrevRequest={() =>
            handleChange((currentIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            handleChange((currentIndex + 1) % images.length)
          }
        />
      )}
    </>
  );
};
export default LightBox;
