import React from "react"
import { Card, Typography, Button, notification, Modal  } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { get } from 'lodash'
import CompletedSvg from '-!react-svg-loader!/public/images/completed.svg';
import { t } from '../../../helpers/translation'
import { cancelReservation } from '../../../services/bookings'
import { clearBooking, addStylist, addMenu, addCoupon } from '../../../helpers/bookings'
import moment from "moment";
import { DATE_FORMAT } from '../../../utils/datetime'
import './styles.scss'

export const Success = ({ reservation, user, store, canCancel, isCancelled }) => {
  const [submit, setSubmit] = React.useState(false);
  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: t('booking.cancelConfirmation'),
      okText: t('booking.confirmBtn'),
      cancelText: t('booking.backBtn'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleCancelBooking()
      }
    });
  };

  const handleCancelBooking = async () => {
    try {
      setSubmit(true);
      await cancelReservation(reservation.id)
      window.location.href = `/reservation_users/stores/${store.id}/bookings/${reservation.id}/cancelled`
    } catch (err) {
      setSubmit(false);
      notification.error({
        message: get(err, 'response.data.message', t('common.system_error')),
      });
    }
  }
  const editReservation = () => {
    window.location.href = `/reservation_users/stores/${store.id}/bookings/${reservation.id}/change`
  }

  const disabled = () => {
    return reservation.status === 'cancelled' || submit || !canCancel || isCancelled;
  }

  return (
    <div className="text-center grid place-items-center px-4 mb-4">
      <CompletedSvg />
      <Typography className="mt-6 mb-3 font-bold">{t('booking.reservationConfirmHeading')}</Typography>
      <Typography>{t('booking.dateTimeVisit')}: {moment.utc(get(reservation, 'dateVisitAt')).format(DATE_FORMAT)} {get(reservation, 'timeVisitFrom')}</Typography>
      <div className="px-6 w-full py-8 flex space-x-1">
        <Button className="btn-outline-secondary btn btn-small mb-4" onClick={editReservation} disabled={disabled()}>
          <span className="font-bold">{t('booking.editSchedule')}</span>
        </Button>

        <Button className="btn-outline-secondary btn btn-small mb-4" onClick={showConfirm} disabled={disabled()}>
          <span className="font-bold">{t('booking.cancelHeading')}</span>
        </Button>
      </div>
      { (!canCancel || isCancelled) &&
        <Typography className="text-xs text-primary mb-10">{ isCancelled ? t('booking.reservationCancelled') : t('booking.canDeleteOrUpdateReservation')}</Typography>
      }
      <Typography className="text-xs">{t('booking.confirmIntroduction')}</Typography>
    </div>
  );
}
