import React from "react"
import { Row, Col, Typography } from "antd"
import styles from './styles.js'
import { t } from '../../../helpers/translation'

const Share = ({ searchText }) => {
  return (
    <Row style={styles.row}>
      <Col style={styles.col}>
        <img src="../../images/search.svg" />
      </Col>
      <Col>
        <Typography style={styles.text}>
          {searchText || t('products.search')}
        </Typography>
      </Col>
    </Row>
  )
}

export default Share