import React from "react"
import { Typography, Row, Col, Divider } from 'antd';
import { get } from 'lodash'
import { t } from '../../../helpers/translation'
import './styles.scss'

const StoreInfo = ({ store }) => {
  return (
    <div className="store-info-wrapper">
      <div>
        <div className="flex flex-row items-center my-6 mt-10">
          <div className="mr-2"><img src='/images/map.svg' /></div>
          <Typography className="store__map-title">{t('store.mapTitle')}</Typography>
        </div>
        <div>
          <div
            className="map__url"
            dangerouslySetInnerHTML={{ __html: get(store, 'mapUrl') }}></div>
        </div>
      </div>
      <div className="my-4 mb-16">
        <Row gutter={16} align="middle">
          <Col className="gutter-row" span={8}>
            <div className="font-bold">{t('store.address')}</div>
          </Col>
          <Col className="gutter-row" span={16}>
            <div>{get(store, 'address')}</div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}  align="middle">
          <Col className="gutter-row" span={8}>
            <div className="font-bold">{t('store.phone')}</div>
          </Col>
          <Col className="gutter-row" span={16}>
            <div className="flex flex-row items-center justify-center rounded py-2 phone-wrapper">
              <div className="mr-2" style={{ width: '14px', height: '14px' }}><img src='/images/phone.svg' /></div>
              <a className="store__phone" href={`tel:${get(store, 'phoneNumber')}`}>{get(store, 'phoneNumber')}</a>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}  align="middle">
          <Col className="gutter-row" span={8}>
            <div className="font-bold">{t('store.businessHour')}</div>
          </Col>
          <Col className="gutter-row" span={16}>
            <div>{get(store, 'businessHoursStr')}</div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default StoreInfo