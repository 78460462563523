import React from "react"
import { Col, Row, Typography } from "antd"
import './styles.scss'
import { t } from '../../../helpers/translation'

const { Paragraph } = Typography;

const CouponInfo = ({ staff }) => {
  return (
    <>
      <Row className='wrap-coupon-content'>
        <Col span={8}>
          <img src={staff.avatar} className='avatar' />
        </Col>
        <Col className='wrap-description' span={16}>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: t('common.expandEllips') }}>
            {staff.comment}
          </Paragraph>  
        </Col>
        <Row className='wrap-coupon'>
          <img src='/images/coupon.svg' />
          <div className='coupon-title'>{t('staff.coupons')}</div>
        </Row>
      </Row>
    </>
  );
}

export default CouponInfo