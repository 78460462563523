import React from 'react'
import { Button } from 'antd'
import './styles.scss'

const EcButton = (props) => {
  const { variant='primary', className='', ...rest } = props
  return (
    <Button 
      className={`rounded ec-btn ec-btn-${variant} ${className}`}
      {...rest} 
    />

  )
}

export default EcButton
