export default {
  card: {
    background: '#FFFFFF',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    marginBottom: 12,
    marginTop: 2,
    marginLeft: 2,
    overflow: 'visible',
  },
  content: {
    padding: '8px 12px 16px 12px',
    flexDirection: 'column',
  },
  image: {
    height: 162,
    width: '100%',
    objectFit: 'cover',
    borderRadius: '8px 8px 0 0'
  },
  contentFixed: {
    height: '90px',
  },
  brand: {
    fontSize: 10,
    color: '#03212F',
    opacity: 0.6,
  },
  name: {
    width: '100%',
    fontSize: 14,
    color: '#03212F',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontVariant: 'proportional-width',
  },
  price: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#03212F'
  },
  extraPrice: {
    fontSize: 10,
    color: '#03212F',
    alignSelf: 'center'
  },
  nameRow: {
    display: 'contents',
  }
}
