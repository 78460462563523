import api from '../../../dashboards/global/middleware.js'
import axios from "axios"

const makeRequest = axios

export const getListSettlement = async (params) => {
  return api.get('/dashboard/api/pos/settlements', { params })
}

export const cancelSettlement = async (data) =>
  makeRequest({
    method: 'put',
    url: '/dashboard/api/pos/settlement_histories/cancel',
    data: data
  })
