import consumer from "./consumer"
import { notification } from 'antd'
import { t } from '../../javascript/helpers/translation'
import { cancelSettlement } from '../../javascript/services/dashboards/pos/settlement.js';

const POS_SETTLEMENT_HISTORY = '/dashboard/settlement_histories'

consumer.subscriptions.create('RefundCashSettlementChannel', {
  received(data) {
    if(window.location.pathname == POS_SETTLEMENT_HISTORY && data.type == 'POS_WITHDRAWAL') {

      if(data.response == 'success'){
        handleSubmit(data.settlement_id, data.staff_id)
      } else if(data.response == 'error'){
        const ngContent = data.ng_content;

        if(ngContent) {
          notification.error({
            message: t(`settlement.cash_machine_errors.${ngContent}`),
          });
        } else {
          notification.error({
            message: t(`settlement.cash_machine_errors.undefined`),
          });
        }
      }
    }
  },

  subscribed: function() {
    return this.perform('subscribed');
  }
});

const handleSubmit = async (settlementId, staffId) => {
  try {
    const response = await cancelSettlement({
      id: settlementId,
      staff_id: staffId
    })

    if (response?.data?.success) {
      window.location.reload()
    }
  } catch(err) {
    notification.error({
      message: err?.response?.data?.message
    });
  }
}
