export default {
  btnContainer : {
    width: '100%',
  },
  btnPrimary: {
    backgroundColor: '#E96E75',
    width: '100%',
    borderRadius: 4,
    border: 'none',
    height: 48,
  },
  btnSecondary: {
    width: '100%',
    borderRadius: 4,
    height: 48
  },
  btnPrimaryText: {
    color: 'white',
    fontWeight: 700,
    fontSize: 14,
  }
}
