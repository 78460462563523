import React, { useState } from "react"
import StaffInfo from '../../reservation_components/Staffs/StaffInfo'
import StoreInfo from '../../reservation_components/Staffs/StoreInfo'
import StaffTab from '../../reservation_components/Staffs/StaffTab'
import Schedule from '../../reservation_components/Staffs/Schedule'

const StaffDetail = ({ staff, coupons, storeId, menus, activeTab, products }) => {
  const [active, setActiveTab] = useState(activeTab)

  return (
    <>
      <StaffInfo staff={staff} />
      <StaffTab storeId={storeId} menus={menus} staff={staff} coupons={coupons} products={products} activeTab={active} setActiveTab={setActiveTab} />
      {active === 'coupon_info' && <StoreInfo store={staff.store} />}
    </>
  )
}

export default StaffDetail
