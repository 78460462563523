import variables from '../../styles/ec_users/_variables.scss';

export default {
  infoWrapper: {
    marginLeft: 20,
    marginRight: 20,
  },
  textColor: {
    color: "#03212F",
    fontWeight: 100,
    paddingRight: 5
  },
  infoCard: {
    boxShadow: '0px 2px 12px rgba(0, 0, 0, 0.12)',
    borderRadius: 12,
  },
  cardOther: {
    marginTop: 30,
  },
  title: {
    fontSize: 20,
    color: "#03212F",
  },
  link: {
    color: "#03212F",
    fontSize: 14,
    paddingLeft: 0
  },
  divider: {
    margin: '18px 0'
  },
  name: {
    fontSize: 20,
    fontWeight: 100
  },
  hello: {
    fontSize: 12,
  },
  bodyStyle: {
    padding: '15px 20px',
  },
  pointStyleSymbol: {
    color: variables.gray777,
    fontSize: 12,
    fontWeight: 'bold',
  },
  pointStyle: {
    color: variables.primaryColor,
    fontWeight: 'bold',
    fontSize: 24
  },
  mr: {
    fontSize: 12
  }
}
