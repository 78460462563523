import React from 'react'
import { difference } from 'lodash'
import { NavStep } from '../../reservation_components/BookingInfo/NavStep';
import { Summary } from '../../reservation_components/BookingInfo/Summary';
import { getStylist, getCoupon, getMenus, getSchedule, clearBooking, addStore } from '../../helpers/bookings'
import { discountValue } from '../../helpers/bookings'
import { confirmReservation } from '../../services/bookings'
import { currency } from '../../utils/number'
import { Button, Form, notification } from 'antd';
import { get } from 'lodash'
import { t } from '../../helpers/translation';
import moment from 'moment';
import {getCoupons} from "../../services/coupons";

const BookingConfirm = ({ store, current_user, pointOptions }) => {
  const [stylist, setStylist] = React.useState(null)
  const [coupons, setCoupons] = React.useState(null)
  const [schedule, setSchedule] = React.useState(null)
  const [selectedMenus, setSelectedMenus] = React.useState([])
  const [saving, setSaving] = React.useState(false)
  const [isUsePoint, setIsUsePoint] = React.useState(0)
  

  const [form] = Form.useForm();
  
  React.useEffect(() => {
    addStore(store)
    const staff = getStylist()
    setStylist(staff);
    const storageCoupons = getCoupon() || []
    const storageCouponIds = _.map(storageCoupons, (cp) => (cp.id))
    getCoupons({ store_id: store.id, staff_id: staff?.id, ids: storageCouponIds, find_by_ids: true }).then((res) => {
      setCoupons(res.data);
    })
    setSchedule(getSchedule());
    onChangeMenu();
  }, [])

  const handleUsePoint = (value) => {
    setIsUsePoint(value)
  }

  const onChangeMenu = () => {
    setSelectedMenus(getMenus());
  }

  const handleConfirmation = () => {
    form.validateFields()
        .then((values) => {
          submitBooking(values);
        })
        .catch((info) => {
          console.log("Validate Failed:", info);
        });
  }

  const submitBooking = async(values) => {
    const menuIds = selectedMenus?.map(item => item.id)
    const couponIds = coupons?.map(item => item.id)
    const data = {
      reservation:
        {
          coupon_ids: couponIds,
          staff_id: stylist?.id,
          store_id: store.id,
          menu_ids: menuIds,
          is_usage_point: !!values.usage_point ? 1 : 0,
          date_visit_at: moment(schedule.day).format('L'),
          time_visit_from: schedule.time,
          visit_time: values.visit_time,
          reservation_note: values.agreeable_question,
          use_points: isUsePoint
        }
    }
    let res
    try {
      setSaving(true)
      res = await confirmReservation(data)
      clearBooking();
      window.location.href = `/reservation_users/stores/${store.id}/bookings/${res.data.reservation.id}/completed`
    } catch (err) {
      setSaving(false);
      notification.error({
        message: get(err, 'response.data.message', t('common.system_error')),
      });
    }
  }

  const onChangeReservation = () => {
    window.location.href = `/reservation_users/stores/${store.id}/bookings/schedule`
  }

  return (
    <div className='bg-white'>
      <NavStep step={3} title={t('booking.bookingAdditionalInfoTitle')}/>
      <Form
        form={form}
        name="booking"
        initialValues={{}}
      >
        <Summary
          store={store}
          schedule={schedule}
          stylist={stylist}
          coupons={coupons}
          selectedMenus={selectedMenus}
          currentUser={current_user}
          pointOptions={pointOptions}
          totalCouponPrice={currency(discountValue(coupons))}
          onUsePoint={handleUsePoint}
        />
        <div className="px-6 w-full z-10 py-8">
          <Button className="btn-outline-secondary btn btn-small mb-4" onClick={onChangeReservation}>
            <span className="font-bold">{t('booking.changeReservation')}</span>
          </Button>
          <Button className="btn-default btn" onClick={handleConfirmation} disabled={saving}>
            {t('booking.confirmBtn')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default BookingConfirm
