import i18n from "i18next";
import cartsJP from './locales/carts.json'
import ratingsJP from './locales/ratings.json'
import accountSettingJP from './locales/accountSettings.json'
import userJP from './locales/user.json'
import productSearchJP from './locales/productSearch.json'
import productsJP from './locales/products.json'
import ordersJP from './locales/orders.json'
import commonJP from './locales/common.json'
import stripeJP from './locales/stripe.json'
import inquiryJP from './locales/inquiry.json'
import storeJP from './locales/stores.json'
import staffJP from './locales/staffs.json'
import couponJP from './locales/coupons.json'
import menuJP from './locales/menus.json'
import freelancerJP from './locales/freelancers.json'
import bookingJP from './locales/bookings.json'
import brandJP from './locales/brands.json'

const resources = {
  ja: {
    translation: {
      account_setting: accountSettingJP,
      new_user: userJP,
      carts: cartsJP,
      product_search: productSearchJP,
      products: productsJP,
      orders: ordersJP,
      common: commonJP,
      ratings: ratingsJP,
      stripe: stripeJP,
      inquiry: inquiryJP,
      store: storeJP,
      staff: staffJP,
      coupon: couponJP,
      menu: menuJP,
      freelancer: freelancerJP,
      booking: bookingJP,
      brand: brandJP
    }
  }
};

i18n.init({
  resources,
  lng: "ja",
});

window.i18n = i18n
