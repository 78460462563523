export default {
  container: {
    height: 30,
    width: '100%'
  },
  row: {
    width: '100%'
  },
  iconCenter: {
    display: 'flex',
    justifyContent: 'center',
  }
}