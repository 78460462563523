import { Col, Row, Typography } from 'antd'
import React from 'react'
import List from '../../components/ProductList/List/index.js'
import styles from './styles.js'

const TagProducts = ({ products = [], tag }) => {
  return (
    <>
      <Row style={styles.row}>
        <Col>
          <Typography style={styles.text}>
            {tag.name}
          </Typography>
        </Col>
      </Row>
      <List products={products} />
    </>
  )
}

export default TagProducts
