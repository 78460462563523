import React, { useState, useEffect } from "react"
import { Col, Row, Typography, Button, Divider } from "antd"
import { OrderInfo } from '../../components/Orders/OrderInfo'
import { PaymentMethod } from '../../components/Orders/PaymentMethod'
import { PaymentInformation } from '../../components/Orders/PaymentInformation'
import { ShippingAddress } from '../../components/Orders/ShippingAddress'
import { getCartItems } from '../../helpers/carts'
import { validUser } from '../../helpers/user'
import { getCoupon, getUsePoint, discountValue, pointCalculate } from '../../helpers/carts'
import styles from './styles'
import { t } from "../../helpers/translation"
import { get } from 'lodash'

const DEFAULT_LAST4 = '****'

const OrderInformation = ({ user, shipping_fee, prefectures, pb_key }) => {
  const [cartItems, setCartItems] = useState([])
  const [userInfo, setUserInfo] = useState(user)
  const [last4, setLast4] = useState(DEFAULT_LAST4)

  useEffect(() => {
    const items = getCartItems();
    setCartItems(items)
  }, [])

  const handleCheckout = () => {
    window.location.pathname = 'ec_users/orders/show'
  }

  const handleLoginToCheckout = () => {
    window.location.href = '/users/sign_in?action=signin'
  }

  const handleRegistrationToCheckout = () => {
    window.location.href = '/users/sign_in?action=signup'
  }

  const coupon = getCoupon()
  const freeshipThreshold = get(shipping_fee, 'freeship_threshold', 0)
  const subTotalPrice = cartItems && cartItems.reduce((acc, item) => { return (acc + (item.priceWithTax * item.quantity))}, 0)
  const originalFee = shipping_fee ? shipping_fee.fee : 0
  const fee = subTotalPrice < freeshipThreshold ? originalFee : 0

  const isUsePoint = getUsePoint()
  const reedemedPoints = isUsePoint ? Number(isUsePoint) : 0
  const totalPriceExcludeDiscount = cartItems && cartItems.reduce((acc, item) => { return (acc + (item.priceWithTax * item.quantity))}, 0) + Number(fee)
  const totalDiscount = discountValue(coupon, totalPriceExcludeDiscount) || 0
  const totalPriceExceptPoints =  totalPriceExcludeDiscount - Number(totalDiscount)
  const pointUsage = pointCalculate(isUsePoint, totalPriceExceptPoints, reedemedPoints)
  const canContinue = last4 !== DEFAULT_LAST4 && validUser(userInfo)
  const disableStyle = !canContinue && { backgroundColor: 'gray' }

  return (
    <div style={styles.container}>
      {userInfo ?
        (
          <>
            <Row style={styles.header}>
              <Col>
                <img src="/images/order.svg" width={20} height={20} />
              </Col>
              <Col>
                <Typography style={styles.orderText}>
                  {t('orders.title')}
                </Typography>
              </Col>
            </Row>
            <OrderInfo items={cartItems} fee={fee} coupon={coupon} user={user} freeshipThreshold={freeshipThreshold} />
            <PaymentMethod setLast4={setLast4} pb_key={pb_key}/>
            <Divider />
            <ShippingAddress user={userInfo} prefectures={prefectures} onUpdateUserInfo={setUserInfo} />
            <Row>
              <Button style={{...styles.orderBtn, ...disableStyle }} onClick={handleCheckout} disabled={!canContinue}>
                <Typography style={styles.orderConfirmText}>
                  {t('orders.confirm_order')}
                </Typography>
              </Button>
            </Row>
          </>
        ) :
        (
          <>
            <Row justify="center">
              <Typography style={styles.loginText}>
                {t('carts.login_or_new_member_registration')}
              </Typography>
            </Row>
            <Row justify="center">
              <Button style={styles.loginToCheckoutWrapper} onClick={handleLoginToCheckout}>
                <Typography style={styles.loginToCheckout}>
                {t('carts.login_to_checkout')}
                </Typography>
              </Button>
            </Row>
            <Row justify="center">
              <Button style={styles.registerToCheckoutWrapper} onClick={handleRegistrationToCheckout}>
                <Typography style={styles.registerToCheckout}>
                {t('carts.register_to_checkout')}
                </Typography>
              </Button>
            </Row>
          </>
        )
      }
    </div>
  )
}

export default OrderInformation
