import { Typography } from "antd";
import { get } from "lodash";
import React from "react";
import styles from "./styles";

const BrandCard = ({ brand }) => {
  return (
    <div style={styles.card}>
      <Typography style={styles.name}>{get(brand, "name")}</Typography>
    </div>
  );
};

export default BrandCard;
