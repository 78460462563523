import { Col, Row, Button } from "antd";
import React, { useState } from "react"
import Review from '../../common/Review';
import ProductCard from '../ProductCard';
import styles from './styles.js';
import { t } from '../../../helpers/translation'

const MAX_PRODUCT = 10

const ListWithMoreButton = ({ products = [], stylist = {} }) => {
  const [recProducts, setRecProducts] = useState(products.slice(0, MAX_PRODUCT))
  const [showMoreBtn, setShowMoreBtn] = useState(products.length > MAX_PRODUCT)
  const [offsetProduct, setOffsetProduct] = useState(MAX_PRODUCT)

  const productDetailHref = (product) => {
    if (stylist && stylist.id) {
      return `/ec_users/products/${product.id}?staff_id=${stylist.id}`;
    }
    
    return `/ec_users/products/${product.id}`
  }

  const handleShowMoreProducts = () => {
    setRecProducts(products.slice(0, offsetProduct + MAX_PRODUCT))
    setOffsetProduct(offsetProduct + MAX_PRODUCT)
    if (offsetProduct + MAX_PRODUCT >= products.length) {
        setShowMoreBtn(false)
    }
  }

  return (
    <Row style={styles.container} gutter={12}>
      {recProducts.map((product, index) => {
        return (
          <Col span={12} key={index} style={styles.col}>
            <a href={productDetailHref(product)}>
              <ProductCard product={product} />
              <Review product={product} />
            </a>
          </Col>
        )
      })}
      { showMoreBtn &&
        <Col span={24}>
          <div className="px-20 bg-gray-50 w-100 mb-10">
            <Button className="btn-outline-primary btn btn-small" onClick={handleShowMoreProducts}>
              {t('staff.seeAllRecommendedProductBtn')}
            </Button>
          </div>
        </Col>
      }
    </Row>
  );
}

export default ListWithMoreButton
