import React from "react"
import { Card, Row, Tabs, Col } from 'antd';
import { CouponCard } from '../CouponCard';
import { Filter } from './Filter';
import { t } from '../../helpers/translation'
import './styles.scss'

const { Meta } = Card;
const { TabPane } = Tabs;

export const Coupons = ({ storeId, coupons, couponsFirstVisit, couponsSecondVisit, onGetCoupons, onGetFirstVisitCoupons, onGetSecondVisitCoupons, onGetMenus }) => {
  const [tabActive, setTabActive] = React.useState('1')

  const onChange = (key) => {
    setTabActive(`${key}`);
  }

  return (
    <Row className="w-full">
      <Col span={24}>
        <Tabs onChange={onChange} activeKey={tabActive} centered className="mirror__tab-info coupon__tab-info">
          <TabPane tab={t("coupon.regular")} key="1">
            <Filter onGetCoupons={onGetCoupons} onGetMenus={onGetMenus} />
            {coupons.map((coupon, idx) => {
              return (
                <CouponCard coupon={coupon} key={`regular-${coupon.id}`} storeId={storeId} />
              )
            })}
          </TabPane>
          <TabPane tab={t("coupon.firstVisit")} key="2">
            <Filter onGetCoupons={onGetFirstVisitCoupons} onGetMenus={onGetMenus}/>
            {couponsFirstVisit.map((coupon, idx) => {
              return (
                <CouponCard coupon={coupon} key={`first-${coupon.id}`} storeId={storeId} />
              )
            })}
          </TabPane>
          <TabPane tab={t("coupon.secondVisit")} key="3">
            <Filter onGetCoupons={onGetSecondVisitCoupons} onGetMenus={onGetMenus}/>
            {couponsSecondVisit.map((coupon, idx) => {
              return (
                <CouponCard coupon={coupon} key={`second-${coupon.id}`} storeId={storeId} />
              )
            })}
          </TabPane>
        </Tabs>
      </Col>
    </Row>
  );
}
