import React from "react"
import { Card, Divider, Row, Col } from 'antd';
import { get } from 'lodash'
import { currency } from '../../../utils/number'
import { t } from '../../../helpers/translation'
import './styles.scss'

export const PaymentAmount = ({ reservation }) => {
  return (
    <div  className="booking__payment">
      <Card type="inner" title={t('booking.paymentAmountHeading')}>
        <Row align="middle">
          <Col span={8} className="font-bold">
            {t('booking.totalAmount')}
          </Col>
          <Col span={16} className="text-right font-bold">
            {currency(get(reservation, 'paymentAmount'))}
            <span>
              { t('common.taxInclude') }
            </span>
          </Col>
          <Divider />
          <Col span={8} className="font-bold">{t('booking.pointsEarned')}</Col>
          <Col span={16} className="text-right">
            <span className='point-small'>
              {get(reservation, 'earnedPoints')}
            </span>
          </Col>
          { false && (
            <>
              <Divider />
              <Col span={8} className="font-bold">{t('booking.usagePoint')}</Col>
              <Col span={16} className="text-right text-sm">
                <span className='font-bold text-right point-small text-red-600'>
                  {get(reservation, 'usagePoint')}
                </span>
              </Col>
            </>
          )}
          <Divider />
          <Col span={8} className="font-bold">{t('booking.paymentAmount')}</Col>
          <Col span={16} className="text-right font-bold">
            {currency(get(reservation, 'paymentAmount'))}
            <span>
              { t('common.taxInclude') }
            </span>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
