import React, { useState } from 'react'
import { Row, Col, Typography, Button, Modal, Divider } from 'antd'
import { t } from '../../../helpers/translation'
import styles from './styles'
import { get } from 'lodash'
import { currency } from '../../../utils/number'

export const OrderConfirmationInfo = ({ orderLines, total_fee, order, shipping_fee }) => {
  const [isModalVisible, setModalVisibility] = useState(false)
  const handleOrderDetail = () => {
    setModalVisibility(true)
  }
  const subTotalPrice = orderLines && orderLines.reduce((acc, item) => { return (acc + (item.productPrice * item.quantity))}, 0)

  const renderProducts = () => {
    return orderLines?.map((item, idx) => {
      return (
        <React.Fragment key={idx}>
          <Col span={12}>
            <Row style={styles.row}>
              <Col style={styles.imageWrapper}>
                <img src={get(item, 'productImage')} style={styles.image} />
                <span style={styles.span}>{item.quantity}</span>
              </Col>
              <Col>
                <Typography style={styles.prdBrand}>
                  {item.productBrand}
                </Typography>
                <Typography style={styles.prdName}>
                  {item.productName}
                </Typography>
              </Col>
            </Row>
          </Col>
          <Col span={12} style={styles.rightCol}>
            <span style={styles.price}>
              {currency(item.totalAmount)}
            </span>
          </Col>
        </React.Fragment>
      )
    })
  }
  
  return (
    <div style={styles.container}>
      <Row style={styles.box} >
        <Col>
          <div className="flex items-center">
            <div className="text-xs mr-1">
              {`${t('orders.item_quantity')} : `}
            </div>
            <div>
              <b className="font-heading text-md">
                {orderLines.length}
              </b>
              <span>{t('orders.point')}</span>
            </div>
          </div>
          <div className="flex items-center">
            <div className="text-xs mr-1">
              {`${t('orders.total_amount')} : `}
            </div>
            <div>
              <b className="font-heading text-md">{currency(total_fee)}</b>
              <span>{t('orders.yen_tax_included')}</span>
            </div>
          </div>
        </Col>
        <Button style={styles.detailButton} onClick={handleOrderDetail}>
          <Typography style={styles.cancelOrderBtnText}>
            {t('orders.details')}
          </Typography>
        </Button>
        <Row style={styles.modalWrapper}>
          <Modal
            visible={isModalVisible}
            style={{ maxWidth: 400 }} 
            onCancel={() => setModalVisibility(false)}
            footer={null}
          >
          <Row justify="space-between" align="middle" style={styles.body}>
            {renderProducts()}
          </Row>

          <Row justify="space-between" align="middle">
            <Col>
              <Typography style={styles.label}>
                {t('orders.product_total_amount')}
              </Typography>
            </Col>
            <Col>
              <span style={styles.price}>
                {currency(subTotalPrice)}
              </span>
            </Col>
          </Row>
          
          <Row justify="space-between" align="middle">
            <Col>
              <Typography style={styles.label}>
                {t('carts.shipping_fee')}
              </Typography>
            </Col>
            <Col>
              <span style={styles.price}>
                {currency(Number(order.shipping_fee))}
              </span>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col>
              <Typography style={styles.label}>
                {t('orders.discount')}
              </Typography>
            </Col>
            <Col>
              <span style={styles.pointAndDiscount}>
                {currency(Number(order.total_discount))}
              </span>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col>
              <Typography style={styles.label}>
                {t('orders.used_points')}
              </Typography>
            </Col>
            <Col>
              <span style={styles.pointAndDiscount} className='point'>
                {order.usage_point}
              </span>
            </Col>
          </Row>
          <Divider />
          <Row justify="space-between" align="middle">
            <Col>
              <Typography style={styles.label}>
                {t('orders.total')}
              </Typography>
            </Col>
            <Col>
              <span style={styles.totalValue}>
                {currency(total_fee)}
              </span>
            </Col>
          </Row>
          </Modal>
        </Row>
      </Row>
    </div>
  )
}
