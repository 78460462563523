import React from 'react'
import { Row, Typography, Collapse } from 'antd'
import { getUsePoint } from '../../../helpers/carts'
import { t } from '../../../helpers/translation'
import styles from './styles'

const { Panel } = Collapse

export const PaymentInformation = ({ last4, coupon, pointUsage }) => {
  const isUsePoint = !!getUsePoint()
  const DEFAULT_LAST4 = '****'

  return (
    <div>
      <Row>
        <Typography style={styles.title}>{t('orders.payment_info')}</Typography>
      </Row>
      <div className="payment-info-collapse">
        <Collapse>
          <Panel header={t('carts.used_points')} key="1">
            <Typography style={styles.description}>
              {t('orders.points_used', { number: isUsePoint ? pointUsage : 0 })}
            </Typography>
          </Panel>
          <Panel header={t('orders.payment_method')} key="2">
            { last4 !== DEFAULT_LAST4 &&
              <Typography style={styles.description}>
                {t('orders.visa', { number: last4 })}
              </Typography>
            }
          </Panel>
          <Panel header={t('orders.coupon')} key="3">
            <Typography style={styles.description}>
              {coupon && coupon.coupon}
            </Typography>
          </Panel>
        </Collapse>
      </div>
    </div>
  )
}