import React, { useEffect, useState } from "react"
import { Col, Row, Typography, Button, Popover, Divider, notification } from "antd"
import { get } from 'lodash'
import Rating from '../Rating'
import styles from './styles'
import { addToCart, getCartItems } from '../../../helpers/carts'
import { t } from '../../../helpers/translation'
import { currency } from '../../../utils/number';
import { checkInventory } from '../../../services/orders'
import './styles.scss'

const MAX_ITEM_IN_CART = 10

const ProductContent = ({ product, current_user, average_rating, current_user_rating, rating_users_count, product_variants }) => {
  const [added, setAdded] = useState(false)
  const [soldOut, setSoldOut] = useState(product.stock <= 0)
  const [staffId, setStaffId] = useState(null)
  const [cartItems, setCartItems] = useState([])
  let canPurchaseProduct = product.canPurchaseProduct
  const mustLoginToPurchase = product.mustLoginToPurchase
  useEffect(() => {
    const items = getCartItems();
    setCartItems(items);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if(params.staff_id) {
      setStaffId(params.staff_id)
    }
  }, [])

  const handleAddToCart = async () => {
    if (soldOut) { return }

    if (cartItems && cartItems?.length >= MAX_ITEM_IN_CART) {
      notification.error({
        message: t('carts.maxItemInCart', { max: MAX_ITEM_IN_CART })
      });
      return
    }

    const productInCart = cartItems?.find(c => c.id === product.id)

    try {
      let res = await checkInventory({ product: {
          id : product.id,
          quantity: (!productInCart || productInCart.quantity < productInCart.limitQtyPerOrder) ? ((productInCart?.quantity || 0) + 1) : (productInCart?.quantity || 0)
        }
      });
      if(res.status == 200){ 
        if(!productInCart || productInCart.quantity < productInCart.limitQtyPerOrder){
          addToCart(product, staffId)
        }
        setAdded(true)
      }
    } catch (err) {
      notification.error({
        message: err.response.data.message
      });
    }
  }

  const handleViewCart = () => {
    window.location.href = "/ec_users/carts/show"
  }

  const handleContinueShopping = () => {
    window.location.href = window.location.origin
  }

  const handleNeedLoginClick = (event) => {
    event.preventDefault();
    document.cookie = `x-mirrorball-redirect-url=; path=/`;
    document.cookie = `x-mirrorball-redirect-url=${window.location.href}; path=/`;    
    window.location.href = '/users/sign_in?action=signin'
  }

  const needLoginContent = (
    <div>
      <a href="#" onClick={handleNeedLoginClick}>{t('carts.needLogin')}</a>
    </div>
  )

  return (
    <Row style={styles.container}>
      <Row style={styles.content}>
        <Row style={styles.row}>
          <Typography style={styles.variantSize} className="w-full mb-2">
            {t('products.variant_size')}
          </Typography>
          <div className="flex flex-wrap gap-x-2">
            {product_variants.map((item, idx) => {
              return (
                <a style={styles.variantBlock} className={item.id == product.id ? 'variant__current' : ''} key={`category-${idx}`} href={`/ec_users/products/${item.id}`}>
                  {get(item, 'variantName')}
                </a>
              )
            })}
          </div>
        </Row>
        <Divider />
        <Row style={styles.row}>
          <div className="flex mb-5 flex-wrap gap-x-2">
            {get(product, 'categories').map((item, idx) => {
              return (
                <span key={`category-${idx}`} className="category">
                  {get(item, 'name')}
                </span>
              )
            })}
          </div>
        </Row>
        <Row style={styles.row}>
          <Typography style={styles.brand}>
            {get(product, 'productBrand.name')}
          </Typography>
        </Row>
        <Row style={styles.row}>
          <Typography style={styles.name}>
            {get(product, 'name')}
          </Typography>
        </Row>
        <Row style={{...styles.row, ...styles.rating}}>
          <Col xs={12} sm={12}>
            <Rating
              product_id = {get(product, 'id')} 
              current_user={current_user} 
              average_rating={average_rating}  
              current_user_rating={current_user_rating}
              rating_users_count={rating_users_count}
            />
          </Col>
          <Col xs={12} sm={12} style={styles.priceWrapper}>
            <span style={styles.price}>
              {currency(get(product, 'priceWithTax'))}
            </span>
            <span style={styles.extraPrice}>
              { t('common.taxInclude') }
            </span>
          </Col>
        </Row>
        { (product?.limited || product?.isMilbon) &&
          <>
            <Row style={styles.row}>
              { product?.limited &&
                <span className="text-sm" style={styles.accessGuest}>
                  { t('carts.guest') }
                </span>
              }
              { product?.isMilbon &&
                <span className="text-sm" style={styles.accessGuest}>
                  { product?.isMilbonDirect ? t('carts.milbon_direct') : t('carts.milbon')}
                </span>
              }
            </Row>
          </>
        }
        { product?.limited &&
          <>
            <Row style={styles.row}>
              <Typography className="text-sm" style={styles.accessGuestDesc}>
                {t('carts.guestDesc')}
              </Typography>
            </Row>
          </>
        }
        <Row style={styles.row}>
          <Typography className="text-sm" style={styles.accessGuestDesc} dangerouslySetInnerHTML={{ __html: t('carts.refund_clause') }}>
          </Typography>
        </Row>
        { !mustLoginToPurchase && 
          <Row style={styles.row}>
            {added ? <Button className="btn btn-default" onClick={handleViewCart} disabled={ soldOut || !canPurchaseProduct }>
              {soldOut ? t('carts.soldOut') : t('carts.viewCart')}
            </Button> : <Button className="btn btn-default" onClick={handleAddToCart} disabled={ soldOut || !canPurchaseProduct }>
              {soldOut ? t('carts.soldOut') : t('carts.addToCart')}
            </Button>}
          </Row>
        }
        { mustLoginToPurchase && 
            <Popover content={needLoginContent}>
              <Button style={styles.buttonPopover} disabled>{t('carts.addToCart')}</Button>
            </Popover>
        }
        <div className="w-full">
            <a
              className="font-bold text-primary text-sm mt-2 flex justify-center items-center mt-4"
              onClick={() => handleContinueShopping() }
            >
              {t('products.continue_shopping')}
            </a>
        </div>
      </Row>
      <Row style={styles.reviewWrapper}>
        <Col span={24} style={styles.row}>
          <Typography style={styles.title}>
            {t('products.product_description_title')}
          </Typography>
        </Col>
        <Col span={24} style={styles.row}>
          <pre className="whitespace-pre-wrap text-sm mb-5 break-word">
            {get(product, 'description')}
          </pre>
        </Col>
        {get(product, 'mainStaffComment.comment') && <Row style={{ ...styles.row, ...styles.review }}>
          <Col span={2}>
            <img src={get(product, 'mainStaffComment.staff.avatar')} style={styles.avatar} />
          </Col>
          <Col span={1} />
          <Col span={21}>
            <Typography className="reviewDescription" style={styles.reviewDescription}>
              {get(product, 'mainStaffComment.comment')}
            </Typography>
          </Col>
          {get(product, 'mainStaffComment.staff.name') && <Row>
            <Typography style={styles.storeText}>
              {get(product, 'mainStaffComment.store.name')}, {get(product, 'mainStaffComment.staff.name')}
            </Typography>
          </Row>}
        </Row>}
      </Row>
    </Row>
  )
}

export default ProductContent
