import React, { useState, useEffect } from "react"
import { resendOtpCode } from '../../services/users'
import { notification, Row, Button } from "antd"
import OtpInput from 'react-otp-input';
import { t } from '../../helpers/translation';
import { authenticateOtp, cancelUser } from '../../services/users'

const SmsAuthentication = ({ currentUser }) => {
  const [otp, setOtp] = useState('')
  const [stateMessage, setStateMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [otpMaxLimitMessage, setOtpMaxLimitMessage] = useState('')
  const [disableOtpInput, setDisableOtpInput] = useState(false)

  const notify_error = (type) => (error) =>
    notification[type]({
      message: window.i18n.t("new_user.new_user_error"),
      description: error,
    });

  const handleResend = async () => {
    setOtp('')
    setStateMessage('')
    setErrorMessage('')
    setOtpMaxLimitMessage('')

    try {
      const res = await resendOtpCode(currentUser)

      if (res?.data?.success) {
        setStateMessage(res.data.message)
      }
    } catch (error) {
      if (error?.response.status == 423) {
        setOtpMaxLimitMessage(error?.response.data.message)
        setDisableOtpInput(true)
      } else {
        setErrorMessage(error?.response?.data?.message);
      }
    }
  };

  const handleChange = async (otp) => {
    setOtp(otp)
    setErrorMessage('')
    setOtpMaxLimitMessage('')
    setStateMessage('')

    if(otp.length === 4){
      try {
        const res = await authenticateOtp({
          user: {
            otp_code: otp,
          }
        })
        if (res?.data?.success) {
          window.location.href = `${window.location.origin}/users/profiles/edit`;
        } else {
          setErrorMessage(res?.data?.message);
        }
      } catch (error) {
        if (error?.response.status == 423) {
          setOtpMaxLimitMessage(error?.response.data.message)
          setDisableOtpInput(true)          
        } else {
          setErrorMessage(error?.response?.data?.message);
        }
      }
    }
  }

  const redirectToLogin = () => {
    window.location.href = `/users/sign_in`
  }

  const onCancel = async () => {
    const res = await cancelUser()
    if (res && res.data && res.data.success) {
      window.location.href = '/';
    }
  }

  useEffect(() => {
    if (otpMaxLimitMessage) setTimeout(redirectToLogin, 3000)
  }, [otpMaxLimitMessage])

  return (
    <div className="flex flex-col justify-between mx-5 my-10 h-112 sms-auth">
      <h1 className="text-2xl font-body font-thin text-textColor-300">
        {window.i18n.t("new_user.sms_authenticate_title")}
      </h1>
      <div className="text-center mt-10">
        <p className="font-body font-thin font-sm text-textColor-300">
          {window.i18n.t("new_user.sms_authenticate_description")}
        </p>
        <OtpInput
          containerStyle="align-center justify-center"
          inputStyle="inputStyle"
          value={otp}
          onChange={handleChange}
          numInputs={4}
          separator={<span className="w-5" />}
          isDisabled={disableOtpInput}
          shouldAutoFocus
          isInputNum
        />
        {stateMessage && <p className="text-green-300 mt-5">{stateMessage}</p>}
        {(errorMessage || otpMaxLimitMessage) && <p className="text-red-300 mt-5">{(errorMessage || otpMaxLimitMessage)}</p>}
      </div>
      <Row>
        <Button
          className="btn btn-default"
          onClick={handleResend} variant="no-style" disabled={disableOtpInput}>
          {window.i18n.t("new_user.resend_otp_button")}
        </Button>
      
        <Button className="btn btn-default btn-gray mt-6" onClick={onCancel}>
          {t('new_user.cancel_button')}
        </Button>
      </Row>
    </div>
  );
};

export default SmsAuthentication;
