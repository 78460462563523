import React from "react"
import { Typography } from 'antd';

import { t } from '../../helpers/translation'
import { StaffCard } from '../../reservation_components/BrandStaffs/StaffCard'

const BrandStaffs = ({ staffs }) => {
  return (
    <>
      <Typography className='font-bold text-2xl my-6'>
        {t('brand.select_a_person')}
      </Typography>
      {
        staffs.map((staff, idx) => {
          return(<StaffCard stylist={staff} />)
        })
      }
    </>
  );
}

export default BrandStaffs