export default {
  container: {
    backgroundColor: '#F6F6F6',
    padding: '32px 20px 0 20px',
    minHeight: 'calc(100vh - 24rem)',
  },
  orderConfirmText: {
    marginLeft: 10,
    fontWeight: 700,
    fontSize: 30,
    lineHeight: '48px'
  },
  cancelOrderBtn: {
    height: '34px',
    backgroundColor: '#white',
    width: '48%',
    borderRadius: 4,
    border: 'none',
    marginBottom: '20%',
  },
  continueShippingBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '34px',
    backgroundColor: '#E96E75',
    width: '48%',
    borderRadius: 4,
    border: 'none',
    marginBottom: '20%',
    marginLeft: '4%'
  },
  cancelOrderBtnText: {
    padding: '6px 32px 6px 32px',
    color: 'black',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '18px'
  },
  continueShippingBtnText: {
    padding: '8px 32px 8px 32px',
    color: 'white',
    fontWeight: 700,
    fontSize: 12,
    lineHeight: '18px'
  }
}
