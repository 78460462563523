import React, { useState } from 'react'
import { Row, Typography, Radio, Col } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import { ShippingAddressDialog } from '../../ShippingAddressDialog'

import { t } from '../../../helpers/translation'
import styles from './styles'

export const ShippingAddress = ({ onUpdateUserInfo, user, prefectures, isConfirmation }) => {
  const [showModal, setShowModal] = useState(false)

  const handleShowModal = () => {
    setShowModal(true)
  }

  const handleCancel = () => {
    setShowModal(false)
  };

  if (!user || !(user.municipality && user.prefecture && user.zip_code && user.address)) {
    return (
      <div style={styles.container}>
        <Row>
          <Typography style={styles.title}>
            {t('orders.add_address_title')}
          </Typography>
        </Row>
        <Row>
          <Radio onClick={handleShowModal}>
            <Typography style={styles.addNew}>
              {t('orders.add_new_address')}
            </Typography>
          </Radio>
        </Row>
        <ShippingAddressDialog
          title={t('new_user.add_new_shipping_address')}
          onUpdateUserInfo={onUpdateUserInfo}
          user={user}
          prefectures={prefectures} 
          isConfirmation={isConfirmation}
          showModal={showModal}
          onHideModal={setShowModal}
        />
      </div>
    )
  }

  return (
    <div style={styles.container}>
      <Row>
        <Typography style={styles.title}>
          {t('orders.add_address_title')}
        </Typography>
      </Row>
      <Row style={styles.box} onClick={handleShowModal}>
        <Typography style={styles.addressName}>
          {user.last_name} {user.first_name} 
        </Typography>
        <Typography style={styles.addressName}>
          {user.last_name_kana} {user.first_name_kana} 
        </Typography>
        <Typography style={styles.addressText}>
          {user.zip_code} {user.prefecture} {user.municipality} {user.address} {user.building}
        </Typography>
        <span style={styles.icon}>
          <RightOutlined />
        </span>
      </Row>
      <ShippingAddressDialog
        title={t('new_user.add_shipping_address')}
        onUpdateUserInfo={onUpdateUserInfo}
        user={user}
        prefectures={prefectures} 
        isConfirmation={isConfirmation}
        showModal={showModal}
        onHideModal={setShowModal}
      />
    </div>
  )
}
