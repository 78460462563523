import React, { useState } from "react"
import { Card, Typography, Button, Image } from 'antd';
import { get } from 'lodash'

import fallbackImage from 'images/fallback_image.png'
import { t } from '../../../helpers/translation'
import { currency } from '../../../utils/number'
import './styles.scss'

const { Paragraph } = Typography;

export const StaffCard = ({ stylist }) => {
  const [ellipsis, _setEllipsis] = useState(true);

  const handleReservationButton = (store_id) => {
    const url = window.location.origin + `/reservation_users/stores/${store_id}?active_tab=` + '3'
    window.location.href = url
  }

  const disabledReservation = () => {
    return stylist && stylist?.notAllowNomination
  }

  return (
    <Card className="staff__card my-4" bordered={false}>
      <div className="flex flex-row h-full">
        <div className="staff__avatar w-1/4">
          <Image
            width='100%'
            height={200}
            src={stylist.avatar}
            preview={false}
            fallback={fallbackImage}
            className='object-cover'
          />
        </div>
        <div className="py-2 px-4 w-3/4">
          <Typography className="staff__name">{get(stylist, 'name')}</Typography>
          <Typography className="staff__year">{t('staff.staffHistoryTitle', { count: get(stylist, 'staffHistory') })}</Typography>
          <div className="pl-4 py-2 w-full">
            <div className="comment-box">
              <Paragraph className="staff__comment" ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: t('common.expandEllips') } : false}>
                {get(stylist, 'comment')}
              </Paragraph>
            </div>
          </div>
          { get(stylist, 'nominated?') &&
            <Typography className="gray-777">
              {t('staff.nominationFeeValue', { fee: currency(get(stylist, 'nominationFeeValue')) })}
            </Typography>
          }

          <Button
            className="btn-default btn my-4"
            onClick={() => handleReservationButton(stylist.storeId)}
            disabled={disabledReservation()}
          >
            {t('staff.bookingBtn')}
          </Button>
        </div>
      </div>
    </Card>
  );
}
