import React from "react"
import { Card, Typography, Button, Divider, Row, Col, Avatar } from 'antd';
import { get, difference, sumBy  } from 'lodash'
import { CouponCard } from '../CouponCard'
import { discountValue } from '../../../helpers/bookings'
import { currency, convertMinsToHour } from '../../../utils/number'
import { TIME_FORMAT, DATE_FORMAT } from '../../../utils/datetime'
import { t } from '../../../helpers/translation'
import './styles.scss'

export const Reservation = ({ coupons, reservation, store, stylist, totalTreatmentTime, coupon, menus }) => {
  const [selectedMenuSummary, setSelectedMenuSummary] = React.useState(null);
  const [selectedMenuPrice, setSelectedMenuPrice] = React.useState(null);
  const [menuShowMark, setMenuShowMark] = React.useState(false);
  
  const individuallySelectedMenus = reservation?.menus

  React.useEffect(() => {
    if (!menus) { return };
    const menuSummary = menus.map((menu) => menu.name).join('+')
    const menuPrice = sumBy(individuallySelectedMenus, 'priceWithTax')
    const menuHasMark = individuallySelectedMenus.find((el) => el.isShowMark)
    setMenuShowMark(!!menuHasMark)
    setSelectedMenuPrice(menuPrice)
    setSelectedMenuSummary(menuSummary)
  }, [menus])

  return (
    <div  className="booking__reservation">
      <Card type="inner" title={t('booking.salonInfoTitle')}>
        <Row align="middle">
          <Col span={8} className="font-bold">{t('booking.salonName')}</Col>
          <Col span={16}>{get(store, 'name')}</Col>
          <Divider />
          <Col span={8} className="font-bold">{t('booking.dateTimeVisit')}</Col>
          <Col span={16}>{moment.utc(get(reservation, 'dateVisitAt')).format(DATE_FORMAT)}~{moment.parseZone(`${get(reservation, 'dateVisitAt')} ${get(reservation, 'timeVisitFrom')}`).format(TIME_FORMAT)}</Col>
          <Divider />
          <Col span={8} className="font-bold">{t('booking.treatmentTime')}</Col>
          <Col span={16}>{totalTreatmentTime && convertMinsToHour(totalTreatmentTime)}</Col>
          <Divider />
          {coupons && coupons.map(coupon => (
            <React.Fragment key={coupon.id}>
              <Col span={8} className="font-bold">{t('booking.coupon')}</Col>
              <Col span={16}><CouponCard coupon={coupon} /></Col>
              <Divider />
            </React.Fragment>
          ))
          }
          <Col span={8} className="font-bold">{t('booking.menuTitle')}</Col>
          <Col span={12}>{selectedMenuSummary}</Col>
          <Col span={4} className="font-bold text-right">
            {currency(selectedMenuPrice, menuShowMark)}
            <span className='text-xs'>{t('common.taxInclude')}</span>
          </Col>
          {(stylist && !stylist.isVirtual) &&
            <React.Fragment>
              <Divider />
              <Col span={8} className="font-bold">{t('booking.stylistTitle')}</Col>
              <Col span={12} className="font-bold">
                <div className="flex flex-row items-center justify-center">
                  <Col span={4}>
                    <Avatar src={get(stylist, 'avatar')}/>
                  </Col>
                  <Col span={20}>
                    <span className="break-all pl-2">{get(stylist, 'displayName')}</span>
                  </Col>
                </div>
              </Col>
              { get(stylist, 'nominated?') &&
                <Col span={4} className="font-bold text-right">
                  <Typography className="font-bold">{currency(get(stylist, 'nominationFeeValue'))}</Typography>
                </Col>
              }
            </React.Fragment>
          }
        </Row>
      </Card>
    </div>
  );
}
