export default {
  row: {
    margin: '32px 20px 24px 20px',
  },
  col: {
    display: 'flex',
    justifyConent: 'center',
    marginRight: 10,
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#03212F',
  }
}