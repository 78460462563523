import React, { useState, useEffect } from 'react'
import { Coupons } from '../../reservation_components/Coupons';
import { StylistCard } from '../../reservation_components/BookingInfo/StylistCard';
import { NavStep } from '../../reservation_components/BookingInfo/NavStep';
import { CouponMenu } from '../../reservation_components/BookingInfo/CouponMenu';
import { getCoupons, fetchMenus } from '../../services/coupons'
import { getStylist, getCoupon, getMenus, addStore } from '../../helpers/bookings'
import { t } from '../../helpers/translation';
import BookingMenuList from "../BookingMenu/BookingMenuList";

const Bookings = ({ store }) => {
  const [coupons, setCoupons] = useState([])
  const [stylist, setStylist] = useState(getStylist())
  const [couponsSelected, setCouponsSelected] = useState([])
  const [selectedMenus, setSelectedMenus] = useState([])
  const [firstVisitCoupons, setFirstVisitCoupons] = useState([])
  const [secondVisitCoupons, setCecondVisitCoupons] = useState([])
  const [menus, setMenus] = useState([])

  const onGetCoupons = async (fitler) => {
    const res = await getCoupons({...fitler, store_id: store.id, staff_id: stylist?.id, primary_category: 'category_everyone'});
    if (res) {
      setCoupons(res.data);
    }
  }

  const onGetMenus = async () => {
    const res = await fetchMenus({ store_id: store.id, staff_id: stylist?.id });
    
    if (res) {
      setMenus(res.data);
    }
  }

  const onGetFirstVisitCoupons = async (fitler) => {
    const res = await getCoupons({...fitler, store_id: store.id, staff_id: stylist?.id, primary_category: 'category_new' });
    if (res) {
      setFirstVisitCoupons(res.data);
    }
  }

  const onGetSecondVisitCoupons = async (fitler) => {
    const res = await getCoupons({...fitler, store_id: store.id, staff_id: stylist?.id, primary_category: 'category_return' });
    if (res) {
      setCecondVisitCoupons(res.data);
    }
  }

  useEffect(() => {
    onGetCoupons({});
    onGetMenus({});
    onGetFirstVisitCoupons({});
    onGetSecondVisitCoupons({});
  }, [stylist])

  useEffect(() => {
    addStore(store);
    const storageCoupons = getCoupon() || []
    const storageCouponIds = _.map(storageCoupons, (cp) => (cp.id))
    getCoupons({ store_id: store.id, staff_id: stylist?.id, ids: storageCouponIds, find_by_ids: true }).then((res) => {
      setCouponsSelected(res.data);
    })
    setSelectedMenus(getMenus() || []);
  }, [])

  return (
    <>
      <NavStep step={1} title={t('booking.couponMenuTitle')} />
      <CouponMenu coupons={couponsSelected} selectedMenus={selectedMenus} storeId={store.id} />
      <StylistCard stylist={stylist} store={store} />
      <div className="bg-white">
        <Coupons
          storeId={store.id}
          coupons={coupons}
          couponsFirstVisit={firstVisitCoupons}
          couponsSecondVisit={secondVisitCoupons}
          onGetCoupons={onGetCoupons}
          onGetFirstVisitCoupons={onGetFirstVisitCoupons}
          onGetSecondVisitCoupons={onGetSecondVisitCoupons}
        />
      </div>
      <div className="bg-white">
        <BookingMenuList storeId={store.id} onMenuSelected={setSelectedMenus} menus={menus} selectedMenus={selectedMenus} />
      </div>
    </>
  )
}

export default Bookings
