import api from 'dashboards/global/middleware.js'

export const getCoupons = async (fitler) => {
  return api.get(`/api/coupons`, { params: fitler })
}

export const fetchMenus = async (filter) => {
  return api.get(`/api/menus`, { params: filter })
}

export const getServiceCategories = async () => {
  return api.get(`/api/coupons/service_categories`)
}
