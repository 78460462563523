import variables from '../../../styles/ec_users/_variables.scss';

export default {
  card: {
    background: variables.white,
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    marginBottom: 12,
    marginTop: 2,
    minHeight: 300,
    marginLeft: 2,
    overflow: 'hidden',
  },
  content: {
    padding: '8px 12px 16px 12px',
  },
  brand: {
    fontSize: 10,
    color: variables.black,
    opacity: 0.6,
  },
  name: {
    fontSize: 14,
    color: variables.black
  },
  price: {
    fontSize: 16,
    fontWeight: 'bold',
    color: variables.black
  },
  extraPrice: {
    fontSize: 10,
    color: variables.black,
    alignSelf: 'center'
  },
  image: {
    width: 178,
    height: 178,
    objectFit: 'cover',
  },
  wrapper: {
    width: '100%',
  }
}