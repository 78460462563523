import React, { useState, useEffect, useRef, useMemo } from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { Button, Radio, Row, Col, Typography, notification } from 'antd'
import { getPaymentMethods, createCustomerInfo } from '../../../services/orders'
import { setPaymentMethod } from '../../../helpers/carts'
import { get, set } from 'lodash'
import styles from './styles'
import { t } from '../../../helpers/translation'
import { errorMaps, fundingNotSupported } from '../../../utils/stripe'

export const CheckoutForm = ({ secret, setMethods, setShowElement, setMethodID, setLast4, setSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    stripe.confirmCardSetup(secret, {
      payment_method: {
        card: cardElement,
      }
    }).then(async function (result) {
      let errorMessage = errorMaps[get(result, 'error.code')]
      if(get(result, 'error.decline_code')) {
        errorMessage = errorMaps[get(result, 'error.decline_code')]
      }
      if (errorMessage) {
        notification.error({
          message: errorMessage,
        });
        return
      }

      if(get(result, 'error')) {
        notification.error({
          message: get(result, 'error.message'),
        });
        return
      }
      const res = await getPaymentMethods()

      const methods = get(res, 'data.payment_method')
      if (methods) {
        const method = get(res, 'data.lastest_card')
        if(method && fundingNotSupported.includes(method.card.funding)) {
          const result = await createCustomerInfo()
          if (result && result.data) {
            setSecret(result.data.intent_secret)
          }
          notification.error({
            message: t('stripe.funding_not_supported'),
          });
          return
        }
        setMethods(methods)
        if (methods[0]) {
          setPaymentMethod(methods?.[0]?.id)
          setMethodID(methods?.[0]?.id)
          setLast4(methods?.[0]?.card?.last4)
        }
      }
      setShowElement(false)
     });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Typography style={styles.cardLabel}>
        {t('orders.new_payment_card_label')}
      </Typography>
      <CardElement options={{
        style: {
          base: {
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
        hidePostalCode: true,
      }}/>
      <Button type="primary" disabled={!stripe || !elements} htmlType="submit" style={styles.button} className="primary-btn-bg">
        {t('orders.add_new_card_button')}
      </Button>
    </form>
  );
};

export const PaymentMethod = ({ setLast4, pb_key }) => {
  const [secret, setSecret] = useState('')
  const [methods, setMethods] = useState([])
  const [methodID, setMethodID] = useState('')
  const [showElement, setShowElement] = useState(false)

  const stripePromise = useMemo(() => loadStripe(pb_key), [pb_key])

  useEffect(() => {
    stripePromise.current = loadStripe(pb_key, {locale: 'ja'});
  }, [pb_key])

  useEffect(() => {
    const getMethods = async () => {
      const res = await getPaymentMethods()

      const methods = get(res, 'data.payment_method')
      if (!methods || !methods.length) {
        const result = await createCustomerInfo()
        if (result && result.data) {
          setSecret(result.data.intent_secret)
        } else {
          // TODO: handle missing intent secret
        }
      } else {
        setMethods(methods)
        setPaymentMethod(methods?.[0]?.id)
        setMethodID(methods?.[0]?.id)
        setLast4(methods?.[0]?.card?.last4)
      }
    }
    getMethods()
  }, [])

  const onChange = value => {
    setPaymentMethod(value.id)
    setMethodID(value.id)
    setLast4(value?.card?.last4)
    setShowElement(false)
  };

  const handleShowElement = async () => {
    setMethodID('')
    setPaymentMethod('')
    setLast4('****')
    const result = await createCustomerInfo()
    if (result && result.data) {
      setSecret(result.data.intent_secret)
      setShowElement(true)
    }
  }

  if (methods && methods.length) {
    return (
      <div style={styles.container}>
        <Typography style={styles.cardLabel}>
          {t('orders.payment_card_label')}
        </Typography>
        {methods.map((me, idx) => {
          const cardBrand = me?.card?.brand.charAt(0).toUpperCase() + me?.card?.brand.slice(1)
          return (
            <Row key={idx} justify="start" style={styles.payRow}>
              <Col>
                <Radio checked={methodID === me.id && !showElement} value={me.id} onChange={() => onChange(me)}/>
              </Col>
              <Col>
                <Row>
                  <Typography style={styles.addNew}>
                    {`${cardBrand} **** **** **** ${me?.card?.last4}`}
                  </Typography>
                </Row>
                {me?.billing_details?.name && (
                  <Row>
                    <Typography style={styles.addNew}>
                      {me?.billing_details?.name}
                    </Typography>
                  </Row>
                )}
                <Row>
                  <Typography style={styles.addNew}>
                    {`有効期限: ${me?.card?.exp_year}/${me?.card?.exp_month}`}
                  </Typography>
                </Row>
              </Col>
            </Row>
          )
        })}
        <Row justify="start" style={styles.payRow}>
          <Col>
            <Radio onChange={handleShowElement} checked={showElement} />
          </Col>
          <Col>
            <Typography style={styles.addNew}>
              {showElement ? t('orders.register_new_payment') : t('orders.change_new_payment')}
            </Typography>
          </Col>
        </Row>
        {showElement && (
          <Elements stripe={stripePromise} options={{ locale: 'ja' }}>
            <CheckoutForm secret={secret} setMethods={setMethods} setShowElement={setShowElement} setMethodID={setMethodID} setLast4={setLast4} setSecret={setSecret}/>
          </Elements>
        )}
      </div>
    )
  }

  return (
    <div style={styles.container}>
      <Elements stripe={stripePromise} options={{ locale: 'ja' }}>
        <CheckoutForm secret={secret} setMethods={setMethods} setShowElement={setShowElement} setMethodID={setMethodID} setLast4={setLast4} setSecret={setSecret}/>
      </Elements>
    </div>
  )
}
