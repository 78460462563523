export default {
  row: {
    margin: '32px 20px 16px 20px',

  },
  col: {
    display: 'flex',
    justifyConent: 'center',
    marginRight: 10,
  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#03212F',
  },
  form: {
    width: '100%',
    position: 'relative'
  },
  searchBar: {
    height: '35px',
    width: '100%',
    border: 'none',
    borderRadius: 4,
  },
  searchButton: {
    height: '35px',
    border: 'none',
    backgroundColor: 'white',
    position: 'absolute',
    right: 5,
  },
  typeRow: {
    margin: '32px 20px 24px 20px',
  },
  defaultButton: {
    backgroundColor: 'white',
    padding: '16px',
    borderRadius: '50px',
    marginBottom: '5px',
  },
  clickedButton: {
    backgroundColor: '#E96E75',
    padding: '16px',
    borderRadius: '50px',
    marginBottom: '5px',
  },
  contentContainer: {
    marginLeft: 20,
    marginBottom: 24,
  },
  typeCol: {
    flex: '1',
    textAlign: 'center',
  },
  circle : {
    position: 'relative',
    height: 52,
    width: 52,
    borderRadius: '50%',
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    position: 'absolute',
  },
  searchError: {
    color: '#E96E75',
  },
  errorRow: {
    justifyContent: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '30px',
    marginBottom: '30px'
  }
}
