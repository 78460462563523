import React from "react"
import { Card } from 'antd';
import { get } from 'lodash'
import './styles.scss'

const { Meta } = Card;

export const Banner = ({ store }) => {
  return (
    <Card
      bordered={false}
      className="banner__card"
      style={{ width: '100%' }}
      bodyStyle={{ padding: 0, backgroundImage: `url("${get(store, 'image')}")` }}
    >
      <Meta title={get(store, 'name')} className="banner__store-name" />
    </Card>
  );
}
