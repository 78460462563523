import React, { useState, useEffect } from "react"
import { Col, Row, Typography, Button, Space, Spin, notification } from "antd"
import { CartItemList } from '../../components/Carts/CartItemList'
import { CartInfo } from '../../components/Carts/CartInfo'
import { Coupon } from '../../components/Carts/Coupon'
import { getCartItems, setCoupon, getCoupon, discountValue, MININUM_AMOUNT, pointCalculate, setUsePoint } from '../../helpers/carts'
import { currency } from '../../utils/number'
import styles from './styles'
import { t } from "../../helpers/translation"
import { get } from 'lodash'
import { ErrorDialogMilbon } from "../ErrorDialogMilbon"
import { validateQuantity, validatePublishedProducts } from '../../services/carts'

const EmptyCarts = () => {
  return (
    <div>
      <Row className="flex mx-5 items-center justify-center p-2 rounded-md color-gray-500 mt-16">
        <img src="/images/empty_cart.svg" />
      </Row>
      <Row justify="center">
        <Typography style={styles.noItem}>
          {t('carts.no_item')}
        </Typography>
      </Row>
      <Row justify="center">
        <Button href="/ec_users" style={styles.continueBuyingWrapper}>
          <Typography style={styles.continueBuying}>
            {t('carts.continue_shopping')}
          </Typography>
        </Button>
      </Row>
    </div>
  )
}

const Carts = ({ user, pointOptions, shipping_fee = { fee: 0 } }) => {
  const [cartItems, setCartItems] = useState([])
  const [couponDiscount, setCouponDiscount] = useState({ discount: 0, coupon: '' })
  const [isUsePoint, setIsUsePoint] = useState(0)
  const [loading, setLoading] = useState(true)
  const [errorMessages, setErrorMessages] = useState({})
  const [showErrorModal, setShowErrorModal] = useState(false)

  useEffect(() => {
    const items = getCartItems();

    if (!user && items) {
      window.location.href = '/ec_users/orders/new'
    } else {
      setCartItems(items)
      setLoading(false);
    }
  }, [])

  useEffect(() => {
    const coupon = getCoupon()
    if (!!coupon) {
      setCouponDiscount(coupon)
    } 
  }, [])

  const handleUsePoint = (value) => {
    setIsUsePoint(value)
  }

  const isCartEmpty = !cartItems || !cartItems.length;

  const handleRemoveItem = product => {
    if (!cartItems || !cartItems.length) {
      return
    }
    setCartItems(cartItems.filter(c => c.id !== product.id))
  }

  const handleAddItem = product => {
    setCartItems(cartItems.map(c => {
      if (c.id === product.id) {
        return {
          ...c,
          quantity: c.quantity + 1,
        }
      }
      return c
    }))
  }

  const handleSubItem = product => {
    if (cartItems.length === 1 && product.quantity === 1) {
      setCoupon(null);
      setUsePoint(false);
    }
    if (product && product.quantity === 1) {      
      handleRemoveItem(product)
      return;
    }
    setCartItems(cartItems.map(c => {
      if (c.id === product.id) {
        return {
          ...c,
          quantity: c.quantity - 1,
        }
      }
      return c
    }))
  }

  const handleSaveCoupon = coupon => {
    setCouponDiscount(coupon)
    setCoupon(coupon)
    const calcTotalDiscount = discountValue(coupon, totalPriceExcludeDiscount) || 0
    const calcTotalPriceExceptPoints =  totalPriceExcludeDiscount - Number(calcTotalDiscount)
    if(isUsePoint > calcTotalPriceExceptPoints) {
      const pointBalance = user && user.point_balances || null
      const pointUsageMax = pointCalculate(pointBalance, calcTotalPriceExceptPoints, pointBalance)
      console.log(pointUsageMax)
      
      setIsUsePoint(pointUsageMax)
      setUsePoint(pointUsageMax)
    }
  }

  useEffect(() => {
    if(isUsePoint > totalPriceExceptPoints) {
      const pointBalance = user && user.point_balances || null
      const pointUsageMax = pointCalculate(pointBalance, totalPriceExceptPoints, pointBalance)
      console.log(pointUsageMax)
      
      setIsUsePoint(pointUsageMax)
      setUsePoint(pointUsageMax)
    }
    setErrorMessages({})
  }, [cartItems])

  const handleErrorDialogCancel= async() => {
    setShowErrorModal(false);
  }

  const handleCheckout = async () => {

    const items = cartItems.map(item => { 
      
      return { product_id: item.id, quantity: item.quantity, product_name: item.name, is_milbon: item.isMilbon, is_milbon_direct: item.isMilbonDirect}
    })
    
    let i = 0
    let cont = true
    while(i < items.length){
      if (items[i+1] != null) {
        if(items[i].is_milbon_direct != items[i+1].is_milbon_direct) {
          setShowErrorModal(true);
          cont = false
          break;
        }
      }
      i++
    }

    if (cont) {
      const checkPublishedProductsResult = await validatePublishedProducts({
        order: {
          items: items
        }
      })
      if (checkPublishedProductsResult.data.success) {
          console.log(checkPublishedProductsResult.data)
          const result = await validateQuantity({
          order: {
            items: items
          }
          })
          if (result && result.data && result.data.success) {
            window.location.pathname = '/ec_users/orders/new'
          } else {
            window.scrollTo(0, 0);
            setErrorMessages(result.data.error_messages)
          }
      } else {
        window.scrollTo(0, 0);
        setErrorMessages(checkPublishedProductsResult.data.error_messages)
      }
    }
  }

  const freeshipThreshold = get(shipping_fee, 'freeship_threshold', 0)
  const totalTaxFee = cartItems && cartItems.reduce((acc, item) => { return (acc + item.calculatedTaxFee * item.quantity)}, 0) || 0
  const reedemedPoints = isUsePoint && user ? isUsePoint : 0
  const subTotalPrice = cartItems && cartItems.reduce((acc, item) => { return (acc + (item.priceWithTax * item.quantity))}, 0)
  const shippingFee = subTotalPrice < freeshipThreshold ? get(shipping_fee , 'fee', 0) : 0
  const totalPriceExcludeDiscount = subTotalPrice + Number(shippingFee)
  const totalDiscount = discountValue(couponDiscount, totalPriceExcludeDiscount) || 0
  const totalPriceExceptPoints =  totalPriceExcludeDiscount - Number(totalDiscount)
  const pointUsage = pointCalculate(isUsePoint, totalPriceExceptPoints, reedemedPoints)

  let totalPrice = Number(totalPriceExceptPoints) - pointUsage

  if (totalPrice < 0) {
    totalPrice = 0
  }

  const totalQuantity = cartItems && cartItems.reduce((acc, item) => { return (acc + item.quantity)}, 0)

  if (isCartEmpty) {
    return (
      <Spin spinning={loading}>
        <EmptyCarts />
      </Spin>
    )
  }

  return (
    <Spin spinning={loading}>
      <div style={styles.container}>
        <Row style={styles.header}>
          <Col>
            <img src="/images/cart.svg" width={20} height={20} />
          </Col>
          <Col>
            <Typography style={styles.cartText}>
              {t('carts.title')}
            </Typography>
          </Col>
        </Row>
        {cartItems.map((item, index) => {
          return (
            <CartItemList
              item={item}
              key={index}
              onRemove={handleRemoveItem}
              onAdd={handleAddItem}
              onSub={handleSubItem}
              errorMessage={errorMessages[item.id]}
            />
          )
        })}
        <CartInfo
          items={cartItems}
          fee={shippingFee}
          totalPrice={totalPrice}
          pointUsage={pointUsage}
          user={user}
          onUsePoint={handleUsePoint}
          totalTaxFee={totalTaxFee}
          totalDiscount={totalDiscount}
          pointOptions={pointOptions}
          totalPriceExceptPoints={totalPriceExceptPoints}
          freeshipThreshold={freeshipThreshold}
          subTotalPrice={subTotalPrice}
        />
        <Coupon onSetDiscount={handleSaveCoupon} totalPrice={totalPriceExcludeDiscount}/>
        <Button href="/ec_users" style={styles.continuePurchaseBtn}>
          <Typography style={styles.continuePurchaseText}>
            {t('carts.continue_purchase')}
          </Typography>
        </Button>
        <Row style={styles.footerCart}>
          <Col span={8}>
            <Row>
              <Typography style={styles.totalText}>
                {window.i18n.t('carts.total_money', { value: currency(totalPrice) })}
                <span className='text-xs'>{t('common.taxInclude')}</span>
              </Typography>
            </Row>
            <Row>
              <Typography style={styles.totalText}>
                {window.i18n.t('carts.total_quantity', { value: totalQuantity })}
              </Typography>
            </Row>
          </Col>
          <Col span={16}>
            <Button style={styles.checkoutBtn} onClick={handleCheckout}>
              <Typography style={styles.checkoutText}>
                {t('carts.checkout')}
              </Typography>
            </Button>
          </Col>
        </Row>
      </div>
      <ErrorDialogMilbon title={t('carts.milbon_products_and_standard_product_error')} visible={showErrorModal} onCancel={handleErrorDialogCancel} />
    </Spin>
    
  )
}

export default Carts
