import React from "react"
import { Card, Typography } from 'antd';
import CompletedSvg from '-!react-svg-loader!/public/images/completed.svg';
import { t } from '../../../helpers/translation'

export const CancelSuccess = ({ reservation, user, store }) => {
  return (
    <div className="text-center grid place-items-center px-4 mb-4">
      <CompletedSvg />
      <Typography className="mt-6 mb-3 font-bold">{t('booking.cancelSuccessHeading')}</Typography>
    </div>
  );
}
