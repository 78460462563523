export default {
  container: {
    backgroundColor: 'white',
    width: '100%',
  },
  col: {
    marginBottom: 24,
  },
  elementContainer: {
		display: "grid",
		gridTemplateColumns: "50% 50%",
  },
}
