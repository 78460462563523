export default {
  button: {
    marginTop: 20,
    backgroundColor: '#E96E75',
  },
  container: {
    marginTop: 24
  },
  payRow: {
    marginBottom: 15
  },
  addNew: {
    fontSize: 16,
    color: '#03212F',
  },
  cardLabel: {
    fontSize: 16,
    color: '#03212F',
    marginBottom: 15
  }
}
