import React from "react"
import { Typography, Button, Image, Divider, Col, Row } from 'antd';
import { get } from 'lodash'
import phoneSvg from 'images/phone.svg'
import fallbackImage from 'images/fallback_image.png'
import { t } from '../../../helpers/translation'
import './cssStyles.scss'

const { Paragraph } = Typography;

export const StoreCard = ({ store }) => {
  const handleReservationButton = (store_id) => {
    const url = window.location.origin + `/reservation_users/stores/${store_id}?active_tab=` + '3'
    window.location.href = url
  }

  const handleStoreInfoButton = (store_id) => {
    window.location.pathname = `reservation_users/stores/${store_id}`
  }

  return (
    <Col >
      <Image
        width='100%'
        height={200}
        src={store?.images[0]?.original}
        preview={false}
        className='object-cover'
        fallback={fallbackImage}
      />

      <Typography className="store__name">{store?.name}</Typography>
      <Divider/>
      <Row>
        <Typography className='heading'>
          {t('brand.address')}
        </Typography>
        <Row className='details'>
          <Paragraph>
            {store?.address}
          </Paragraph>
        </Row>
      </Row>
      <Divider/>
      <Row>
        <Typography className='heading'>
          {t('brand.telephone')}
        </Typography>
        <Col className='telephone-details'>
          <img src={phoneSvg} className='telephone-image'/>
          <Typography className='telephone-number'>
            {store?.phoneNumber}
          </Typography>
        </Col>
      </Row>
      <Divider/>
      <Row>
        <Typography className='heading'>
          {t('brand.business_hours')}
        </Typography>
        <Col>
          { store.businessHoursStr }
        </Col>
      </Row>
      <Button className="btn btn-outline" onClick={() => handleStoreInfoButton(store.id)}>
        {t('brand.see_store_information')}
      </Button>
      <Button className="btn btn-default my-2" onClick={() => handleReservationButton(store.id)}>
        {t('brand.availability_reservation_btn')}
      </Button>
    </Col>
  );
}
