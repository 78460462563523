import React from "react"
import { Row, Collapse, Typography, Col, Image, Button } from 'antd';

import { CustomCarousel as Carousel } from '../../reservation_components/Home/Carousel'
import { StoreCard } from '../../reservation_components/BrandDetail/StoreCard'
import { SliderImage } from '../../reservation_components/Stores/TabInfo/Slider'
import shoppingSvg from 'images/shopping.svg'
import locationSvg from 'images/location.svg'
import fallbackImage from 'images/fallback_image.png'
import { t } from "../../helpers/translation"
import styles from './styles.js'
import './cssStyles.scss'

const BrandDetail = ({ banners, stores, brand }) => {
  const { Panel } = Collapse;

  const imageWithLocation = (location) => {
    return (
      <Row>
        <img src={locationSvg} />
        <Typography className='font-bold ml-2'>{location}</Typography>
      </Row>
    )
  }

  const handleBrandProducts = () => {
    window.location.pathname = `ec_users/brands/${brand.id}/brand_products`
  }

  const handleBrandAvailabilityAndReservation = () => {
    window.location.pathname = `/reservation_users/brands/${brand.id}/staffs`
  }

  const handleJobPostingBtn = () => {
    if (brand.jobPostingUrl) {
      window.open(brand.jobPostingUrl, '_blank').focus();
    }
  }

  return (
    <>
      <Image
        width='100%'
        height={200}
        src={brand.cover}
        preview={false}
        style={styles.brandImage}
        fallback={fallbackImage}
      />
      <div className="flex space-x-2">
        <Button className="btn btn-outline-default" onClick={handleBrandProducts}>
          {t('brand.check_the_product')}
        </Button>
        <Button className="btn btn-default" onClick={handleBrandAvailabilityAndReservation}>
          {t('brand.availability_reservation_btn')}
        </Button>
      </div>
      <Col className='store-catch-copy'>
        <SliderImage images={brand.styleImages} />
        <Row>
          <Typography className='store-catch-copy-heading'>
            {brand.name}
          </Typography>
        </Row>
        <Row>
          <Typography>
            {brand.description}
          </Typography>
        </Row>
        <Carousel images={banners} />
        <Button className="btn btn-secondary my-2" onClick={handleJobPostingBtn}>
          {t('brand.job_posting')}
        </Button>
      </Col>
      <Row>
        <Col className='shop-selection-details'>
          <img src={shoppingSvg} className='shop-selection-image'/>
          <Typography className='shop-selection'>
            {t('brand.shop_selection')}
          </Typography>
        </Col>
        <Collapse expandIconPosition='right' className='stores-collapse'>
          {stores.map((data, idx) => {
            return (
              <Panel header={imageWithLocation(data[0])} key={idx}>
                {data[1].map((store, idx) => {
                  return (
                    <StoreCard store={store} key={idx} />
                  )
                })}
              </Panel>
            )
          })}
        </Collapse>
      </Row>
    </>
  )
}

export default BrandDetail
