import consumer from "./consumer"
import { notification } from 'antd'
import { t } from '../../javascript/helpers/translation'

consumer.subscriptions.create("CashRegisterClosureChannel", {
  received(data) {
    if(data.response == 'error'){
      notification.error({
        message: t('common.close_cash_error'),
      });
    }
  },

  subscribed: function() {
    return this.perform('subscribed');
  }
});
