export default {
  container: {
    backgroundColor: '#F6F6F6',
    padding: '32px 20px 0 20px',
  },
  header: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  orderText: {
    marginLeft: 10,
    fontWeight: 700,
    fontSize: 20,
  },
  orderBtn: {
    height: '100%',
    backgroundColor: '#E96E75',
    width: '100%',
    borderRadius: 4,
    height: 64,
    border: 'none',
    marginTop: 24,
    marginBottom: 40,
  },
  orderConfirmText: {
    color: 'white',
    fontWeight: 700,
    fontSize: 14,
  },
  space: {
    height: 24
  },
  noItem: {
    marginTop: 24,
    marginBottom: 24,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#E96E75',
    textAlign: 'center',
  }
}
