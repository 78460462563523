import variables from '../../../styles/ec_users/_variables.scss';

export default {
  container: {
    backgroundColor: 'white'
  },
  content: {
    padding: '20px 20px',
    backgroundColor: '#FAFAFA'
  },
  reviewWrapper: {
    padding: '20px 20px',
  },
  row: {
    width: '100%',
  },
  category: {
    whiteSpace: 'nowrap',
    fontSize: 10,
    display: 'inline-block',
    color: '#03212F',
    borderColor: '#E3E3E3',
    borderWidth: 1,
    boxSizing: 'border-box',
    borderRadius: 2,
    padding: '4px 8px',
    marginBottom: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  brand: {
    fontSize: 12,
    color: '#777777',
    marginBottom: 12,
  },
  name: {
    fontWeight: 700,
    fontSize: 20,
    marginBottom: 16,
  },
  priceWrapper: {
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  price: {
    fontWeight: 700,
    fontSize: 24,
  },
  extraPrice: {
    fontSize: 12,
    fontWeight: 400,
    marginTop: 12,
    marginLeft: 4,
  },
  rating: {
    marginBottom: 12,
  },
  accessGuest: {
    color: '#E96E75',
    background: '#FCEEF1',
    boxSizing: 'border-box',
    borderRadius: 2,
    padding: '4px 8px',
    marginBottom: 12,
    marginRight: 6,
    fontWeight: 'bold'
  },
  accessGuestDesc: {
    color: '#777777',
    marginBottom: 24,
  },
  addToCartBtn: {
    background: '#E96E75',
    borderRadius: 4,
    borderWidth: 0,
    width: '100%',
    height: 48,
    marginBottom: 20,
  },
  addToCartText: {
    color: 'white',
    fontSize: 14,
    fontWeight: 700,
  },
  title: {
    color: '#03212F',
    fontSize: 16,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  review: {
    marginBottom: 32,
  },
  avatar: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  reviewDescription: {
    wordBreak: 'break-word',
    fontWeight: 400,
    fontSize: 12,
    backgroundColor: '#FAFAFA',
    borderRadius: 8,
    padding: 6,
  },
  storeText: {
    marginTop: 5,
    fontSize: 12,
    fontWeight: 400,
    color: variables.gray777
  },
  variantSize: {
    fontSize: 12
  },
  variantBlock: {
    height: 28,
    display: 'inline-block',
    fontSize: 14,
    backgroundColor: 'white',
    border: "2px solid",
    borderColor: variables.gray200,
    padding: "0 10px",
    borderRadius: 2,
    color: variables.black,
    fontWeight: 'bold'
  },
  buttonPopover: {
    display: 'block',
    margin: '0 auto',
    width: '100%',
    background: '#999999',
    borderRadius: '4px',
    borderWidth: 0,
    width: '100%',
    height: '48px',
    color: 'white',
    fontSize: '14px',
    fontWeight: '700'
  }
}
