import { Col, Row, Button } from "antd";
import React, { useState } from "react"
import Review from '../../../components/common/Review';
import ProductCard from '../../../components/ProductList/ProductCard';
import styles from './styles.js';
import { t } from '../../../helpers/translation'

const RecommendProducts = ({ products = [], stylist = {} }) => {
  const [recProducts, setRecProducts] = useState(products.slice(0, 2))
  const [showRecommendedProductsBtn, setShowRecommendedProductsBtn] = useState(products.length > 2)
  const [offsetProduct, setOffsetProduct] = useState(0)

  const productDetailHref = (product) => {
    if (stylist && stylist.id) {
      return `/ec_users/products/${product.id}?staff_id=${stylist.id}`;
    }
    
    return `/ec_users/products/${product.id}`
  }

  const handleShowRecommendedProducts = () => {
    setRecProducts(products.slice(0, offsetProduct + 10))
    setOffsetProduct(offsetProduct + 10)
    if (offsetProduct + 10 >= products.length) {
      setShowRecommendedProductsBtn(false)
    }
  }

  return (
    <Row style={styles.container} gutter={12}>
      {recProducts.map((product, index) => {
        return (
          <Col span={12} key={index} style={styles.col}>
            <a href={productDetailHref(product)}>
              <ProductCard product={product} />
              <Review product={product} />
            </a>
          </Col>
        )
      })}
      { showRecommendedProductsBtn &&
        <Col span={24}>
          <div className="px-20 bg-gray-50 w-100">
            <Button className="btn-outline-primary btn btn-small" onClick={handleShowRecommendedProducts}>
              {t('staff.seeAllRecommendedProductBtn')}
            </Button>
          </div>
        </Col>
      }
    </Row>
  );
}

export default RecommendProducts
