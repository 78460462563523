export default {
  contentContainer: {
    marginLeft: 20,
    marginBottom: 24,
  },
  col: {
    marginBottom: 24,
  },
  row: {
    margin: '32px 20px 24px 20px',

  },
  text: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#03212F',
  }
}
