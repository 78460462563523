import React from 'react'
import { Row, Col, Typography, Divider } from 'antd'
import { t } from '../../../helpers/translation'
import { currency } from '../../../utils/number'
import { getUsePoint, discountValue, pointCalculate } from '../../../helpers/carts'
import styles from './styles'

import { CartItemList } from '../../../components/Carts/CartItemList'

export const OrderInfo = ({ items = [], fee, coupon, user, freeshipThreshold }) => {
  const isUsePoint = getUsePoint()
  const reedemedPoints = isUsePoint ? Number(isUsePoint) : 0
  const subTotalPrice = items && items.reduce((acc, item) => { return (acc + (item.priceWithTax * item.quantity))}, 0)
  const totalPriceExcludeDiscount = subTotalPrice + Number(fee)
  const totalDiscount = discountValue(coupon, totalPriceExcludeDiscount) || 0
  const totalPriceExceptPoints =  totalPriceExcludeDiscount - Number(totalDiscount)
  const pointUsage = pointCalculate(isUsePoint, totalPriceExceptPoints, reedemedPoints)
  const pointsEarneds = items && items.reduce((acc, item) => { return (acc + item.pointEarned * item.quantity)}, 0) || 0
  let totalPrice = Number(totalPriceExceptPoints) - pointUsage

  if (totalPrice < 0) {
    totalPrice = 0
  }

  const renderProducts = () => {
    return (
      items && items.map((item, idx) => {
        return (
          <CartItemList
            item={item}
            key={idx}
            canEditQuantity={false}
          />
        )
      })
    )
  }

  return (
    <div style={styles.container}>
      <Row justify="space-between" align="middle">
        {renderProducts()}
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('orders.product_total_amount')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.value}>
            {currency(subTotalPrice)}
          </span>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('carts.shipping_fee')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.value}>
            {currency(fee)} 
          </span>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Typography className='text-xs'>
          {t('orders.threshold_of_free', { value: currency(freeshipThreshold, false, '') })}
        </Typography>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('orders.discount')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.pointAndDiscount}>
            {currency(totalDiscount)}
          </span>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('orders.used_points')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.pointAndDiscount} className='point'>
            {pointUsage}
          </span>
        </Col>
      </Row>
      <Divider />
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('orders.total')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.totalValue}>
            {currency(totalPrice)}
          </span>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('orders.remaining_points')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.value} className='point'>
            {pointsEarneds}
          </span>
        </Col>
      </Row>
    </div>
  )
}
