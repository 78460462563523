import { Col, Row } from "antd";
import React from "react";
import Review from '../../common/Review';
import ProductCard from '../ProductCard';
import styles from './styles.js';

const List = ({ products = [], stylist = {} }) => {

  const productDetailHref = (product) => {
    if (stylist && stylist.id) {
      return `/ec_users/products/${product.id}?staff_id=${stylist.id}`;
    }
    
    return `/ec_users/products/${product.id}`
  }

  return (
    <Row style={styles.container} gutter={12}>
      {products.map((product, index) => {
          return (
            <Col span={12} key={index} style={styles.col}>
              <a href={productDetailHref(product)}>
                <ProductCard product={product} />
                <Review product={product} />
              </a>
            </Col>
          )
        })}
    </Row>
  );
}

export default List
