import React from "react"
import { Card, Typography, Button, Divider, Row, Col } from 'antd';
import { get } from 'lodash'
import { t } from '../../../helpers/translation'
import './styles.scss'

export const StoreInfo = ({ store }) => {
  
  const goStoreDetail = () => {
    window.location.href = `/reservation_users/stores/${store.id}`
  }

  const goStoreCoupon = () => {
    window.location.href = `/reservation_users/stores/${store.id}?active_tab=3`
  }

  return (
    <div  className="booking__store">
      <Card type="inner" title={t('booking.summaryTitle')}>
        <div className="flex flex-row py-2">
          <div className="booking__store-image flex-none rounded" style={{ backgroundImage: `url("${get(store, 'image')}")` }} />
          <div className="pl-2 w-full">
            <div className="flex flex-row space-x-2">
              {store.brands.map(brand => {
                return (
                  <span key={brand.id} className="text-2xs tag">{brand.name}</span>
                )
              })}
            </div>
            <div>
              <Typography className="font-bold flex-2 my-2">{get(store, 'name')}</Typography>
              <Typography className="flex-2 gray-777">{get(store, 'address')}</Typography>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center bg-gray-50 rounded py-2 border mt-4">
          <div className="mr-2" style={{ width: '14px', height: '14px' }}><img src='/images/phone.svg' /></div>
          <a className="store__phone" href={`tel:${get(store, 'phoneNumber')}`}>{get(store, 'phoneNumber')}</a>
        </div>
        <div className="my-4 rounded overflow-hidden">
          <div
            className="map__url"
            dangerouslySetInnerHTML={{ __html: get(store, 'mapUrl') }}></div>
        </div>
        <div className="flex flex-row items-center justify-center space-x-2">
          <div className="w-2/4">
            <Button className="btn btn-small btn-outline-secondary" onClick={goStoreDetail}>
              <span className="font-bold">{t('booking.salonInfoTitle')}</span>
            </Button>
          </div>
          <div className="w-2/4">
            <Button className="btn btn-small btn-outline-secondary text-primary" onClick={goStoreCoupon}>
              <span className="font-bold">{t('booking.couponMenuInCancel')}</span>
            </Button>
          </div>
        </div>
      </Card>
    </div>
  );
}
