import { Row, Col, Typography } from "antd";
import "antd/dist/antd.css";
import { get } from 'lodash';
import React, { useState } from 'react'
import { currency } from "../../../utils/number";
import styles from './styles';
import { t } from '../../../helpers/translation'

const { Paragraph } = Typography;

const ProductCard = ({ product }) => {
  return (
    <div style={styles.card}>
      <Row>
        <img src={product.image} style={styles.image} />
      </Row>
      <div style={styles.content}>
        <div style={styles.contentFixed}>
          <Row>
            <Paragraph
              ellipsis={{
                rows: 1,
                expandable: false
              }}
              style={styles.brand}
            >
              {get(product, 'productBrand.name')}
            </Paragraph>
          </Row>
          <Row style={styles.nameRow}>
            <Typography style={styles.name}>
              {get(product, 'nameWithVariant')}
            </Typography>
          </Row>
          <Row>
            <span style={styles.price}>
              {currency(get(product, 'price'))}
            </span>
            <span style={styles.extraPrice}>
              { t('common.taxInclude') }
            </span>
          </Row>
        </div>
      </div>
    </div>
  )
}

export default ProductCard
