import React, { useState, useRef } from 'react'
import { Row, Col, Card, Typography, Button, Rate } from 'antd';
import { get } from 'lodash';
import { t } from '../../../helpers/translation';
import styles from './styles'
import './style.scss'
import { currency } from '../../../utils/number'
import rank1 from '../../../../assets/images/rank_1.svg'
import rank2 from '../../../../assets/images/rank_2.svg'
import rank3 from '../../../../assets/images/rank_3.svg'
import Rating from '../../ProductDetail/Rating'
import { toNumber, round } from 'lodash'

// app/javascript/components/ProductDetail/Rating/index.js
// app/javascript/components/Home/NewProductCard/index.js
export const NewProductCard = ({ product = {}, show_rank_image = false, rank_image }) => {
  const [soldOut, setSoldOut] = useState(product.stock <= 0)


  const handleGoToProductDetail = () => {
    window.location.pathname = `/ec_users/products/${product.id}`
  }

  const showRankImage = () => {
    if (!show_rank_image) { return };

    const ranks = [rank1, rank2, rank3]

    return (
      <div className="m-auto w-full flex justify-center mb-1 h-6 w-6">
        {rank_image <=3 && 
          <img src={ranks[rank_image-1]} className='h-6 w-6 rank-image' />
        }
      </div>
    )
  }

  const averageRating = toNumber(get(product, 'averageRating'));

  return (
    <Card style={styles.card} onClick={handleGoToProductDetail} className='home-product-card'>
      <Row>
        {showRankImage()}
        <img src={get(product, 'image')} style={styles.image} className='product-image'/>
      </Row>
      <Row className={`ml-1`}>
        <Row>
          <Typography style={styles.brandText}>
            {get(product, 'productBrand.name')}
          </Typography>
        </Row>
        <Row>
          <span style={styles.prdNameText}>
            {get(product, 'nameWithVariant')}
            {get(product, 'product_categories[0]') && (get(product, 'product_categories[0]'))}
          </span>
        </Row>
        <div className="flex items-center w-full gap-x-1">
          <Rate
            allowHalf
            value={averageRating}
            disabled
            style={{fontSize: 12}}
          />
          <span className="text-xs font-semibold">{parseFloat(averageRating.toFixed(1))}</span>
        </div>
        <Row>
          <Typography style={styles.priceText}>
            {currency(product.priceWithTax)}
          </Typography>
          <Typography style={styles.priceNote}>
            {t('products.price_note')}
          </Typography>
        </Row>
      </Row>
    </Card>
  );
}
