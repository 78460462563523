export default {
  card: {
    marginBottom: 20,
    cursor: 'pointer',
    padding: 0,
    overflow: 'hidden',
    border: 'none',
    background: 'none'
  },
  image: {
    width: '100%',
    height: 120,
    objectFit: 'cover',
    borderRadius: 4,
    mixBlendMode: 'multiply',
  },
  contentWrapper: {
    marginBottom: 14,
  },
  reviewerWrapper: {
    display: 'flex',
    marginBottom: 10,
  },
  avatar: {
    width: 24,
    height: 24,
    borderRadius: '50%',
    position: 'absolute',
    left: 0,
    bottom: 0,
  },
  brandText: {
    fontWeight: 400,
    fontSize: 10,
    marginTop: 10,
    marginBottom: 10,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    fontVariant: 'proportional-width',
  },
  prdNameText: {
    fontSize: 14,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontVariant: 'proportional-width',
  },
  priceText: {
    fontWeight: 700,
    fontSize: 14,
  },
  priceNote: {
    fontSize: 10,
    marginLeft: 5,
    alignSelf: 'end',
  }
}
