import { t } from '../helpers/translation'

const errorMaps = {
  invalid_number: t('stripe.invalid_number'),
  card_declined: t('stripe.card_declined'),
  card_not_supported: t('stripe.card_not_supported'),
  incorrect_cvc: t('stripe.incorrect_cvc'),
  incomplete_cvc: t('stripe.incomplete_cvc'),
  invalid_cvc: t('stripe.invalid_cvc'),
  expired_card: t('stripe.expired_card'),
  incorrect_number: t('stripe.incorrect_number'),
  bank_account_exists: t('stripe.bank_account_exists'),
  resource_already_exists: t('stripe.resource_already_exists'),
  invalid_expiry_year_past: t('stripe.invalid_expiry_year_past'),
  generic_decline: t('stripe.generic_decline'),
  insufficient_funds: t('stripe.insufficient_funds'),
  lost_card: t('stripe.lost_card'),
  stolen_card: t('stripe.stolen_card'),
  processing_error: t('stripe.processing_error'),
  default: t('stripe.default'),
}

const fundingNotSupported = []

export { errorMaps, fundingNotSupported }