import React from "react"
import styles from './styles'
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

export const Banner = ({ images }) => {
  const settings = {
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    dots: true,
    autoplay: true,
    arrows: false,
  };

  return (
    <div style={styles.container} className="home-banners-carousal">
      <Slider {...settings}>
        {images.map((data, index) => {
          return (
            <div style={styles.imageWrapper} key={index}>
              <a href={data.webLink} target="_blank">
                <img src={data.image} style={styles.imageStyle} className="home-banner p-0 m-0"/>
              </a>
            </div>
          )
        })}
        </Slider>
    </div>
  );
}
