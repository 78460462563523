export default {
  container: {
    paddingTop: '20px',
    paddingBottom: '30px',
    backgroundColor: '#F6F6F6',
    cursor: 'pointer'
  },
  imageWrapper: {
    position: 'relative',
  },
  imageStyle: {
    width: '-webkit-fill-available',
    height: 177,
    objectFit: 'cover',
    borderRadius: 12,
  }
};