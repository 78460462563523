import React from 'react'
import BrandCard from '../../components/Brands/BrandCard/index.js'
import styles from './styles.js'

const Brand = ({ brands }) => {
  return (
    <div style={styles.container}>
      <div style={styles.elementContainer}>
        {brands.map((brand, index) => {
        return (
          <div key={index}>
            <a href={`/ec_users/brands/${brand.id}/brand_products`}>
              <BrandCard brand={brand} />
            </a>
          </div>
        )
      })}
      </div>
    </div>
  )
}

export default Brand
