import React, { useEffect, useState } from "react"
import { Button, Calendar, Space, Typography, ConfigProvider, Tag } from "antd"
import "./styles.scss"
import { t } from '../../../helpers/translation'
import jp from 'antd/lib/locale/ja_JP'
import { getStaffSchedules } from '../../../services/staffs'
import moment from "moment"
import { addStylist, clearBooking } from '../../../helpers/bookings'

const { Text } = Typography;
const DATE_KEY_FORMAT = 'YYYYMMDD';

export const Schedule = ({ staff }) => {
  const [schedules, setSchedules] = useState({});
  const [currentDate, setCurrentDate] = useState(moment())

  const onPanelChange = (value, mode) => {
    setCurrentDate(value)
  }

  const disabledReservation = () => {
    return staff && !staff?.allowImmediateReservation
  }

  const onGetStaffSchedule = async (date) => {
    const dateFilter = date.month() === currentDate.month() ? currentDate : date;
    const res = await getStaffSchedules({
      date: dateFilter.format(DATE_KEY_FORMAT), staff_id: staff?.id,
      filter_by: 'month'
    });
    if (res) {
      setSchedules(res.data)
    }
  }

  useEffect(() => {
    onGetStaffSchedule(currentDate)
  }, [currentDate])

  const handleBooking = () => {
    clearBooking()
    addStylist(staff)
    window.location = `/reservation_users/stores/${staff.storeId}/bookings/new`
  }


  const dateCellRender = (dateCell) => {
    const dateKey = dateCell.format('YYYYMMDD');
    const dayOfWeeks = dateCell.day();
    const [isBusy, setIsBusy] = React.useState(true);
    const [isHoliday, setIsHoliday] = React.useState(false);

    React.useEffect(() => {
      const schedule = schedules[dateKey];
      setIsHoliday(!!schedule && schedule.isHoliday);
      if (schedule && schedule.timeRange.length > 0) {
        setIsBusy(false);
      } else {
        setIsBusy(true);
      }
    }, [schedules])

    return (
      <React.Fragment>
        {dateCell.isSame(currentDate, 'month') && 
          <div className="text-center">
            {isBusy
              ? <span>{isHoliday ? <Tag color="gray">{t('common.holiday')}</Tag> : '×'}</span>
              : <span>{isHoliday ? <Tag color="gray">{t('common.holiday')}</Tag> : '◯'}</span>
            }
          </div>
        }
      </React.Fragment>
    );  
  }

  return (
    <div className="staff-schedule">
      <Space className="staff-schedule__icon">
        <img src="/images/calendar.svg" />
        <span>{t('staff.schedule')}</span>
      </Space>
      {/* <ConfigProvider locale={jp}>
        <Calendar
          dateCellRender={dateCellRender}
          onPanelChange={onPanelChange}
        ></Calendar>
      </ConfigProvider> */}
      {!disabledReservation() &&
        <Button
          className="btn-default btn mt-10 mb-4"
          onClick={handleBooking}
          disabled={disabledReservation()}
        >
          {t('staff.bookingBtn')}
        </Button>
      }
      
    </div>
  )
}

export default Schedule