import { getItem, setItem } from './localStorage';

export const CARTS = 'CARTS'
export const COUPON = 'COUPON'
export const USE_POINT = 'USE_POINT'
export const PAYMENT_METHOD = 'PAYMENT_METHOD'
export const MININUM_AMOUNT = 100

export const addToCart = (product, staffId = null) => {
  const carts = getItem(CARTS)
  if (!carts || !carts.length) {
    setItem(CARTS, [{ ...product, quantity: 1, staffId: parseInt(staffId) }])
    document.getElementsByClassName('cart-item-number')[0].innerText = 1
  } else {
    const isExisted = carts.find(p => p.id === product.id)
    const productHasStaffId = carts.find(p => !!p.staffId)
    staffId = productHasStaffId ? null : staffId
    if (!isExisted) {
      setItem(CARTS, [...carts, { ...product, quantity: 1, staffId: parseInt(staffId) }])
      document.getElementsByClassName('cart-item-number')[0].innerText = carts.length + 1
    } else {
      setItem(CARTS, carts.map(c => {
        if (c.id === product.id) {
          if(!staffId && c.staffId) staffId = c.staffId
          return {
            ...c,
            quantity: c.quantity + 1,
            staffId: parseInt(staffId)
          }
        }
        return c
      }))
    }
  }
}

export const getCartItems = (product) => {
  return getItem(CARTS)
}

export const removeCartItem = (product) => {
  const carts = getItem(CARTS)

  if (!carts || !carts.length) {
    return;
  }
  setItem(CARTS, carts.filter(c => c.id !== product.id))
  document.getElementsByClassName('cart-item-number')[0].innerText = carts.length - 1
}

export const addCartItem = (product) => {
  const carts = getItem(CARTS)
  if (!carts || !carts.length) {
    return;
  }
  setItem(CARTS, carts.map(c => {
    if (c.id === product.id) {
      return {
        ...c,
        quantity: c.quantity + 1,
      }
    }
    return c
  }))
}

export const subCartItem = (product) => {
  if (product && product.quantity === 1) {
    removeCartItem(product)
    return;
  }

  const carts = getItem(CARTS)

  if (!carts || !carts.length) {
    return;
  }
  setItem(CARTS, carts.map(c => {
    if (c.id === product.id) {
      return {
        ...c,
        quantity: c.quantity - 1,
      }
    }
    return c
  }))
}

export const setCoupon = (coupon) => {
  setItem(COUPON, coupon)
}

export const getCoupon = () => {
  return getItem(COUPON)
}

export const setUsePoint = (value) => {
  setItem(USE_POINT, value)
}

export const getUsePoint = () => {
  return getItem(USE_POINT)
}

export const setPaymentMethod = (value) => {
  setItem(PAYMENT_METHOD, value)
}

export const getPaymentMethod = () => {
  return getItem(PAYMENT_METHOD)
}

export const clearAll = () => {
  localStorage.clear()
}

export const discountValue = (coupon, totalPriceExcludeDiscount) => {
  if (!coupon?.discount) { return 0 }
  return Math.round(parseFloat(coupon?.discount));
}

export const pointCalculate = (usePoint, totalPriceExceptPoints, reedemedPoints) => {
  if (!usePoint) { return 0 }
  return Math.floor(
    (reedemedPoints >= totalPriceExceptPoints ? (totalPriceExceptPoints - MININUM_AMOUNT <= MININUM_AMOUNT ? totalPriceExceptPoints : totalPriceExceptPoints - MININUM_AMOUNT) : reedemedPoints) / 100
  ) * 100
}
