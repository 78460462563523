import React, { useEffect } from "react"
import { Card, Typography, Divider, Row, Col, Avatar, Button, notification, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { get } from 'lodash'
import { currency } from '../../utils/number'
import { CouponCard } from '../../reservation_components/CouponCard'
import { DATE_FORMAT } from '../../utils/datetime'
import { t } from '../../helpers/translation'
import './styles.scss'
import { cancelReservation } from '../../services/bookings'
import { clearBooking, addStylist, addMenu, addCoupon } from '../../helpers/bookings'
import { getMomentJPTime } from '../../helpers/datetime'

const UpcomingReservation = ({ reservationsStore = [] }) => {
  const [reservations, setReservations] = React.useState([]);
  
  useEffect(() => {
    setReservations(reservationsStore)
  }, [reservationsStore])

  const { confirm } = Modal;

  const showConfirm = (id) => {
    confirm({
      title: t('booking.cancelConfirmation'),
      okText: t('booking.confirmBtn'),
      cancelText: t('booking.backBtn'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        handleCancelBooking(id)
      }
    });
  };

  const handleCancelBooking = async (id) => {
    try {
      await cancelReservation(id)
      const newReservations = reservations.filter(reservation => reservation.id !== id)
      setReservations(newReservations)
      notification.success({
        message: t("booking.cancelSuccess"),
      });
    } catch (err) {
      notification.error({
        message: get(err, 'response.data.message', t('common.system_error')),
      });
    }
  }

  const editReservation = (reservation) => {
    clearBooking();
    addStylist(reservation.staff)
    addMenu(reservation.menus)
    if (reservation.coupons[0]) {
      addCoupon(reservation.coupons[0])
    }
    window.location.href = `/reservation_users/stores/${reservation.store.id}/bookings/${reservation.id}/change`
  }

  const disabled = (reservation) => {
    return reservation.status === 'cancelled' || !reservation.isCancelled || reservation.scheduleStatus === 'settled' || reservation.scheduleStatus === 'unpaid' || reservation.scheduleStatus === 'accepted'
  }

  const minuteToTime = (totalMinutes) => {
    const hours = Math.floor(totalMinutes/60)
    const minutes = totalMinutes % 60
    return { hours: hours, minutes: minutes }
  }

  return (
    <div className="booking__summary">
      <Card type="inner" title={t('booking.summaryTitle')}>
        {reservations.map((reservation_store, idx) => {
          const reservation = reservation_store
          const store = reservation_store.store
          const coupons = reservation_store.coupons
          const stylist = reservation_store.staff

          return (
            <Row key={idx} align="middle">
              {idx !== 0 &&
                <>
                  <Divider />
                  <Divider />
                </>
              }
              <Col span={8} className="font-bold">{t('booking.salonName')}</Col>
              <Col span={16}>{get(store, 'name')}</Col>
              <Divider />
              <Col span={8} className="font-bold">{t('booking.dateTimeVisit')}</Col>
              <Col span={16}>{getMomentJPTime(get(reservation, 'dateVisitAt')).format(DATE_FORMAT)} {get(reservation, 'timeVisitFrom')}</Col>
              <Divider />
              <Col span={8} className="font-bold">{t('booking.treatmentTime')}</Col>
              <Col span={16}>{t('common.times', minuteToTime(get(reservation, 'timeRequired')))}</Col>
              <Divider />

              {coupons && coupons.map(coupon => (
                <>
                  <Col span={8} className="font-bold">{t('staff.coupons')}</Col>
                  <Col span={24}><CouponCard coupon={coupon} storeId={get(store, 'id')} showButtons={false}/></Col>
                  <Divider />
                </>
              ))
              }
              { reservation_store.menus.length > 0 &&
                <>
                  <div className="font-bold">{t('booking.menuTitle')}</div>
                  <div className="w-full mt-3">
                    {reservation_store.menus.map((menu, index) => {
                      return (
                        <Row key={index} className="mt-1">
                          <Col span={8}>{menu.name}</Col>
                          <Col span={16}>{currency(menu.price, menu.isShowMark)}{t("common.taxInclude")}</Col>

                        </Row>
                      )
                    })
                    }
                  </div>
                  <Divider />
                </>
              }
              {(stylist && !stylist.isVirtual) &&
                <>
                  <Col span={8} className="font-bold">{t('booking.stylistTitle')}</Col>
                  <Col span={12} className="font-bold">
                    <div className="flex flex-row items-center justify-center">
                      <Avatar src={get(stylist, 'avatar')}/>
                      <span className="break-all pl-2">{get(stylist, 'displayName')}</span>
                    </div>
                  </Col>
                  { get(stylist, 'nominated?') &&
                    <Col span={4} className="font-bold text-right">
                      <Typography className="font-bold">{currency(get(stylist, 'nominationFeeValue'))}</Typography>
                    </Col>
                  }
                  <Divider />
                </>
              }
              <Col span={8} className="font-bold">{t('booking.totalAmount')}</Col>
              <Col span={16}>{currency(get(reservation, 'paymentAmount'))}</Col>
              <Divider className="special-divider" />

              <div className="flex space-x-1 w-full">
                <Button className="btn-outline-secondary btn btn-small mb-4" onClick={() => editReservation(reservation)} disabled={disabled(reservation)}>
                  <span className="font-bold">{t('booking.editSchedule')}</span>
                </Button>
                <Button className="btn-outline-secondary btn btn-small" onClick={() => showConfirm(reservation.id)} disabled={disabled(reservation)}>
                  <span className="font-bold">{t('booking.cancelHeading')}</span>
                </Button>
              </div>
              { !reservation.isCancelled &&
                <Typography className="text-xs text-primary mx-auto">{t('booking.canDeleteOrUpdateReservation')}</Typography>
              }
            </Row>
          )
        })}
        {!reservations?.length && (
          <Typography>{t('booking.noReservations')}</Typography>
        )}
      </Card>
    </div>
  );
}

export default UpcomingReservation
