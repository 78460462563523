export default {
  container: {
    backgroundColor: '#F6F6F6',
    padding: '32px 20px 85px 20px',
  },
  header: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  orderText: {
    marginLeft: 10,
    fontWeight: 700,
    fontSize: 20,
  },
  orderBtn: {
    height: '100%',
    backgroundColor: '#E96E75',
    width: '100%',
    borderRadius: 4,
    height: 64,
    border: 'none',
    marginTop: 24,
    marginBottom: 40,
  },
  orderConfirmText: {
    color: 'white',
    fontWeight: 700,
    fontSize: 14,
  },
  notes: {
    marginLeft: 10,
    fontSize: 12,
    color: '#03212F'
  },
  loginText: {
    fontWeight: 100,
    fontSize: 30,
  },
  loginToCheckoutWrapper: {
    marginTop: 40,
    border: '1px solid #E96E75',
    backgroundColor: 'white',
    height: 64,
    width: '90%',
  },
  loginToCheckout: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#E96E75'
  },
  registerToCheckoutWrapper: {
    marginTop: 40,
    marginBottom: 40,
    border: '1px solid #E96E75',
    backgroundColor: '#E96E75',
    height: 64,
    width: '90%',
  },
  registerToCheckout: {
    fontSize: 16,
    fontWeight: 'bold',
    color: 'white'
  }
}
