import React from "react";
import { Row } from "antd"
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './styles.js'
import ProductCard from './ProductCard'
import Review from '../common/Review'

const RecommendProducts = ({ className, heading, products = [], stylist = {} }) => {
  return (
    <div style={styles.container} className={className}>
      <Row>
        <span style={styles.icon}>
          <img src="/images/gift.svg" />
        </span>
        <span style={styles.title}>
          {heading}
        </span>
      </Row>
      <Row style={styles.item} gutter={12} className='justify-between'>
        {products.map((product, index) => {
          return (
            <div style={styles.slider} key={index}>
              <ProductCard product={product} stylist={stylist} />
              <Review product={product} />
            </div>
          )
        })}
      </Row>
    </div>
  );
}

export { RecommendProducts }
