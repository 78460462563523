export default {
  container: {
    backgroundColor: 'white',
    marginRight: -20,
    marginLeft: -20,
    padding: '24px 20px',
  },
  label: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#03212F',
  },
  unit: {
    fontSize: 12,
    fontWeight: 'bold',
    color: "#03212F",
  },
  value: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#03212F',
  },
  usePoints: {
    fontSize: 16,
    color: '#03212F',
    marginLeft: 5,
  },
  pointAndDiscount: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#E96E75',
  },
  totalValue: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  image: {
    width: 48,
    height: 48,
    border: '1px solid #E3E3E3',
    borderRadius: 4,
  },
  prdName: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#03212F',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontVariant: 'proportional-width',
  },
  prdBrand: {
    fontSize: 12,
    color: '#03212F',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontVariant: 'proportional-width',
  },
  span: {
    position: 'absolute',
    top: -10,
    right: -10,
    backgroundColor: '#E96E75',
    width: 20,
    height: 20,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontWeight: 'bold'
  },
  imageWrapper: {
    position: 'relative',
    marginRight: 15,
  },
  row: {
    marginBottom: 15,
  },
  rightCol: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}
