export default {
  container: {
    marginRight: -20,
    marginLeft: -20,
    padding: '0 20px',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#03212F'
  },
  addNew: {
    marginTop: 20,
    fontSize: 16,
    color: '#03212F'
  },
  box: {
    width: '100%',
    border: '1px solid #E3E3E3',
    background: 'white',
    borderRadius: 4,
    padding: '12px 16px',
    position:'relative',
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 24,
    cursor: 'pointer',
  },
  addressName: {
    width: '100%',
    fontSize: 14,
    fontWeight: 700,
    color: '#03212F',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 24,
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontVariant: 'proportional-width',
  },
  addressText: {
    width: '100%',
    fontSize: 14,
    color: '#03212F',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: 24,
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontVariant: 'proportional-width',
  },
  icon: {
    position: 'absolute',
    right: 16,
  },
  inputHeight: {
    height: 45,
  },
  searchAddress: {
    margin: '0 auto',
    display: 'block',
    marginTop: 12
  }
}
