import moment from 'moment'
import momentTimezone from 'moment-timezone'

/**
  * Returns all days in a month that start in a certain day of the week
  *
  * @param weekday Day of the week, 0 Sunday, 1 Monday, ...
  * @param month 1 January, 2 February
*/

export const getDaysFromWeekdayAndMonth = (weekday, month) => {
  let d = new Date()
  let days = []

  if (weekday < 0 || weekday > 6) return []
  if (month < 1 || month > 12) return []

  d.setMonth(month - 1)
  d.setDate(1)

  // First day on our weekday in the month
  while (d.getDay() !== weekday) {
    d.setDate(d.getDate() + 1)
  }

  // Next days on our weekday in the month
  while (d.getMonth() === month - 1) {
    days.push(d.getDate())
    d.setDate(d.getDate() + 7)
  }

  return days
}

const DAY_FORMAT = 'YYYY-MM-DD'

export const getTimeRanges = (from, to) => {
  let startHour = from * 60;
  const endHour = to * 60;

  const minutesInterval = 30; //minutes interval
  let times = []; // time array

  for (let i=0;startHour < endHour; i++) {
    let hh = Math.floor(startHour/60);
    let mm = (startHour%60);
    times[i] = ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2);
    startHour = startHour + minutesInterval;
  }

  return times
}

export const getMomentJPTime = time => {
  return moment(time).utcOffset('+0900');
}

export const formatDateWithTimezone = (time) => {
  if(!time) return null
  return momentTimezone(time).tz('Asia/Tokyo').format(DAY_FORMAT)
}

export const formatDateTimeWithTimezone = (time) => {
  if(!time) return null
  return momentTimezone(time).format('YYYY-MM-DD HH:mm')
}

export const formatDateWithLocalTimezone = (time) => {
  if(!time) return null
  return moment(time).format(DAY_FORMAT);
}