export default {
  row: {
    width: '100%',
  },
  avatar: {
    width: 24,
    height: 24,
    left: 0,
    bottom: 0,
    borderRadius: 13,
    marginTop: 40,
  },
  contentWrapper: {
    position: 'relative',
  },
  quoteImage: {
    height: 66,
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 10,
    margin: 5,
    fontSize: 12,
    color: '#03212F',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    fontVariant: 'proportional-width',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    maxWidth: '90%',
  }
}
