// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "stylesheet/application"

import moment from 'moment'
import 'moment/locale/ja'


require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

moment.locale('ja')
window.moment = moment

var myCustomContext = require.context("ec_users_containers", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(myCustomContext)

// Custom Styles
import "styles/ec_users.scss";

//Component
import 'ec_users/menu.js'
import 'ec_users/i18n'
import 'ec_users/home.js'
