import React, { useState } from "react";
import { Typography, Card, Form, notification } from "antd";
import ProfileForm from "./ProfileForm";

const Profile = ({ user, isNewRegister, lineLinked }) => {
  const initDate = user && user.birthday && user.birthday != '' ? new Date(user.birthday) : new Date('1990-01-01')
  const [year, setYear] = useState(initDate.getFullYear())
  const [month, setMonth] = useState(initDate.getMonth() + 1)
  const [day, setDay] = useState(initDate.getDate())

  const notify_error = (type) => (error) =>
    notification[type]({
      message: window.i18n.t("new_user.new_user_error"),
      description: error,
    });

  const onFinish = (values) => {
    values.birthday = `${year}-${month}-${day}`
    fetch(`/users/profiles`, {
      credentials: "include",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user: values }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.success) {
          if (response?.redirect_to) {
            window.location.href = response?.redirect_to
          }
          else {
            window.location.href = `${window.location.origin}`
          }
        } else {
          notify_error("error")(response.message);
        }
      })
      .catch(notify_error("error"));
  };

  return (
    <Card className="profile-card">
      <div level={3} className="profile__title">
        {window.i18n.t("new_user.edit_profile")}
      </div>
      <ProfileForm
        onFinish={onFinish}
        day={day}
        user={user}
        month={month}
        year={year}
        setDay={setDay}
        setMonth={setMonth}
        setYear={setYear}
        isNewRegister={isNewRegister}
        lineLinked={lineLinked}
      />
    </Card>
  );
};

export default Profile;
