import React from "react";
import { Form, Input, DatePicker, Button, Select, Row, Checkbox, ConfigProvider, Col } from "antd";
import jp from 'antd/lib/locale/ja_JP';
import { get } from 'lodash';
import { t } from '../../helpers/translation';
import styles from './styles';

const NewForm = ({ onFinish, form, prefectures, init_data }) => {

  const disabledDate = (current) => {
    return current > moment().endOf('day');
  }

  return (
    <Form
      layout="vertical"
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{ name_kanji: get(init_data.info , 'name'), email: get(init_data.info , 'email') }}
      scrollToFirstError
    >
      <Form.Item
        name="email"
        label={t("new_user.email")}
        rules={[
          {
            type: "email",
            message: t("new_user.not_valid_email"),
          },
          {
            required: true,
            message: t("new_user.enter_email"),
          },
        ]}
        hasFeedback
      >
        <Input style={styles.inputHeight} />
      </Form.Item>

      <Form.Item
        name="password"
        label={t("new_user.password")}
        rules={[
          {
            required: true,
            message: t("new_user.enter_password"),
          },
          {
            min: 8,
            message: t("new_user.password_length_error"),
          },
        ]}
        hasFeedback
      >
        <Input.Password style={styles.inputHeight} />
      </Form.Item>

      <Form.Item
        name="name_kanji"
        label={t("new_user.name_kanji")}
        hasFeedback
      >
        <Input style={styles.inputHeight} />
      </Form.Item>

      <Form.Item
        name="name_kana"
        label={t("new_user.name_kana")}
        hasFeedback
      >
        <Input style={styles.inputHeight} />
      </Form.Item>

      <Row justify="space-between">
        <Col>
          <Form.Item label={window.i18n.t("new_user.birthday")}>
            <ConfigProvider locale={jp}>
              <DatePicker
                disabledDate={disabledDate}
                style={styles.dataInput}
              />
            </ConfigProvider>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name="postcode"
            label={window.i18n.t("new_user.postcode")}
            hasFeedback
          >
            <Input style={styles.inputHeight} />
          </Form.Item>
        </Col>
      </Row>
      {false && (
        <Row align="center">
          <Button>{t("new_user.address_search")}</Button>
        </Row>
      )}
      <Form.Item
        name="prefecture"
        label={t("new_user.prefecture")}
        hasFeedback
      >
        <Select>
          {prefectures.map((item) => (
            <Select.Option key={item["code"]} value={item["name"]}>{item["name"]}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="municipality"
        label={t("new_user.municipality")}
        hasFeedback
      >
        <Input style={styles.inputHeight} />
      </Form.Item>

      <Form.Item
        name="street"
        label={t("new_user.street")}
        hasFeedback
      >
        <Input style={styles.inputHeight} />
      </Form.Item>

      <Form.Item
        name="building"
        label={t("new_user.building")}
        hasFeedback
      >
        <Input style={styles.inputHeight} />
      </Form.Item>

      <Form.Item
        name="phone"
        label={t("new_user.phone")}
        rules={[
          {
            pattern: '^([0-9]{2,3})[-]?([0-9]{3,4})[-]?([0-9]{4})$',
            message: t("new_user.not_valid_phone"),
          }
        ]}
        hasFeedback
      >
        <Input style={styles.inputHeight} />
      </Form.Item>

      <Form.Item
        name="agreement"
        valuePropName="checked"
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error(t("new_user.accept_agreement"))),
          },
        ]}
      >
        <Checkbox>
          <a
            href="/terms_and_conditions"
            target="_blank"
            style={{ color: "black" }}
          >
            {t("new_user.agreement")}
          </a>
        </Checkbox>
      </Form.Item>

      <Form.Item>
        <Row>
          <Button style={styles.button} className="primary-btn-bg" type="primary" htmlType="submit">
            {window.i18n.t("new_user.register")}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default NewForm;
