import React from "react"
import { Card, Row, Button } from 'antd';
import { get } from 'lodash'
import { StylistCard } from '../../../StylistCard';
import { t } from '../../../../helpers/translation'
import { clearBooking } from '../../../../helpers/bookings'
import './styles.scss'

const { Meta } = Card;

export const Stylist = ({ store, stylists, onSetTabActive }) => {
  const bookWithoutNomination = () => {
    clearBooking();
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('from_booking_schedule')) {
      window.location.href = `/reservation_users/stores/${store.id}?active_tab=3`
    } else {
      onSetTabActive(3)
    }
  }

  return (
    <Row>
      <Button className="btn-outline btn my-4" onClick={() => bookWithoutNomination()}>
        {t('staff.nextWithoutNomination')}
      </Button>

      {stylists.map((stylist, idx) => {
        return (
          <StylistCard stylist={stylist} key={idx} />
        )
      })}
    </Row>
  );
}
