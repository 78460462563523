import React, { useState, useEffect } from 'react'
import { t } from '../../helpers/translation';
import {convertMinsToHour, currency} from "../../utils/number";
import {Button, Typography} from "antd";
import './styles.scss'

const MenuCard = ({ menu, onAddMenu, onDeleteMenu }) => {
  if (!menu) {
    return (<></>)
  }
  return (
    <div key={menu.id} className='menus-container'>
      <div className='header-section header-section flex flex-wrap gap-2 text-sm'>
        {menu.serviceCategories && menu.serviceCategories.slice(0, 8).map((serviceCategory) => (
          <div key={serviceCategory} className="header">{ serviceCategory }</div>
        ))}
      </div>
      <div className='flex'>
        <div className="w-24 text-xs w-full self-center">
          <div className="font-bold">{menu.name}</div>
        </div>
        <div className="text-right flex-none">
          <span>{`${currency(menu.priceWithTax, menu.isShowMark)}`}</span>
          <span className="mr-2">
            { t('common.taxInclude') }
          </span>
          <span>{convertMinsToHour(menu.requiredTime)}</span>
        </div>
      </div>
      <div className='flex content-right'>
        {
          onDeleteMenu && (
            <Button className="float-right-btn btn-default btn btn-small my-4 text-sm" onClick={onDeleteMenu}>
              {t('booking.delete')}
            </Button>
          )
        }
        {
          onAddMenu && (
            <Button className="float-right-btn btn-default btn btn-small my-4 text-sm" onClick={onAddMenu}>
              { t('common.addMenuItemBtn') }
            </Button>
          )
        }
      </div>
    </div>
  )
}

export default MenuCard
