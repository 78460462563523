export default {
  container: {
    backgroundColor: '#F6F6F6',
    padding: '32px 20px 0 20px',
  },
  orderCancellationText: {
    marginLeft: 10,
    fontWeight: 700,
    fontSize: 30,
    lineHeight: '48px'
  },
  continuePurchaseBtn: {
    width: '100%',
    border: '1px solid #03212F',
    borderRadius: 4,
    boxSizing: 'border-box',
    height: 48,
    marginTop: 32,
    marginBottom: 32,
  },
  continuePurchaseText: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: 7,
  },
}
