export default {
  container: {
    backgroundColor: 'white',
    paddingTop: 32,
    paddingBottom: 80,
    paddingLeft: 20,
    paddingRight: 20,
  },
  icon: {
    alignSelf: 'center',
    marginRight: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: 700,
    color: '#03212F',
  },
  slider: {
    width: 180,
  },
  sliderWrapper: {
    position: 'relative',
  },
  leftArrow: {
    position: 'absolute',
    zIndex: 1,
    left: -18,
    top: '40%',
    cursor: 'pointer'
  },
  rightArrow: {
    position: 'absolute',
    zIndex: 1,
    right: -18,
    top: '40%',
    cursor: 'pointer'
  }
}
