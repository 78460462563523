export default {
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 20,
    justifyContent: 'center'
  },
  text: {
    fontSize: 20,
    fontFamily: 'Montserrat',
    fontWeight: 200,
    marginBottom: 10,
  },
  fullWidth: {
    width: '100%',
    textAlign: 'center'
  },
  subTitle: {
    width: '100%',
    textAlign: 'center',
    marginBottom: '10px'
  }
}
