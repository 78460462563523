import React, { useState, useEffect } from "react"
import { Col, Row, Typography, Button, notification, Spin } from "antd"
import { OrderInfo } from '../../components/Orders/OrderInfo'
import { ShippingAddress } from '../../components/Orders/ShippingAddress'
import { ErrorDialog } from "../ErrorDialog"
import styles from './styles'
import { t } from "../../helpers/translation"
import { confirmOrder } from '../../services/orders'
import { getCoupon, getUsePoint, getCartItems, getPaymentMethod, clearAll } from '../../helpers/carts'
import { get } from 'lodash'

const OrderConfirmation = ({ user, shipping_fee, prefectures }) => {
  const [loading, setLoading] = useState(false)
  const [cartItems, setCartItems] = useState([])
  const [userInfo, setUserInfo] = useState(user)
  const [methodID, setMethodID] = useState(null)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    const items = getCartItems();
    setCartItems(items);

    const id = getPaymentMethod() 
    setMethodID(id)
  }, [])

  const coupon = getCoupon()
  const discount = coupon ? coupon.discount : 0

  const handleConfirmation = async() => {
    setLoading(true)
    const items = cartItems?.map(item => ({ product_id: item.id, quantity: item.quantity, staff_id: item.staffId }))

    const data = {
      order:
        {
          coupon_code: coupon?.coupon,
          items,
          shipping_address:
            {
              kanji_name: user.first_name,
              kana_name: user.first_name_kana,
              post_code: user.zip_code,
              prefectural: user.prefecture,
              municipality: user.municipality,
              street: user.address,
              building_name: user.building,
            },
          used_points: getUsePoint(),
          stripe_payment_id: methodID
        }
    }
    let res
    try {
      res = await confirmOrder(data)
      setLoading(false)
    } catch (err) {
      setErrorMessage(get(err, 'response.data.message', t('common.system_error')));
      setShowErrorModal(true);
      // notification.error({
      //   message: get(err, 'response.data.message', t('common.system_error')),
      // });
      setLoading(false)
    }
    const order_id = res.data?.order?.id

    if (res && res.data) {
      clearAll()
      window.location.href = `/ec_users/order_confirmed?order_id=${order_id}`
    }
  }

  const handleErrorDialogRedo = async() => {
    setLoading(true)
    window.location.href = `/ec_users/carts/show`
  }

  const handleErrorDialogCancel = async() => {
    setLoading(true)
    clearAll()
    window.location.href = `/ec_users`
  }

  const freeshipThreshold = get(shipping_fee, 'freeship_threshold', 0)
  const subTotalPrice = cartItems && cartItems.reduce((acc, item) => { return (acc + (item.priceWithTax * item.quantity))}, 0)
  const originalFee = shipping_fee ? shipping_fee.fee : 0
  const fee = subTotalPrice < freeshipThreshold ? originalFee : 0

  return (
    <>
      {!cartItems && (
        <Row justify="center">
          <Typography style={styles.noItem}>
            {t('orders.no_product')}
          </Typography>
        </Row>
      )}
      {cartItems && (
        <Spin spinning={loading}>
          <div style={styles.container}>
            <Row style={styles.header}>
              <Col>
                <img src="/images/order.svg" width={20} height={20} />
              </Col>
              <Col>
                <Typography style={styles.orderText}>
                  {t('orders.confirmation')}
                </Typography>
              </Col>
            </Row>
            <OrderInfo items={cartItems} fee={fee} coupon={coupon} user={user} freeshipThreshold={freeshipThreshold}/>
            <div style={styles.space} />
            <ShippingAddress user={userInfo} prefectures={prefectures} onUpdateUserInfo={setUserInfo} isConfirmation/>
            <Row className="mb-2" style={styles.row}>
              <Typography className="text-sm" dangerouslySetInnerHTML={{ __html: t('orders.delivery_policy') }}>
              </Typography>
            </Row>
            <Row style={styles.row}>
              <Typography className="text-sm" dangerouslySetInnerHTML={{ __html: t('carts.refund_clause') }}>
              </Typography>
            </Row>
            <Row>
              <Button style={styles.orderBtn} onClick={handleConfirmation} disabled={loading}>
                <Typography style={styles.orderConfirmText}>
                  {t('orders.confirm_buy')}
                </Typography>
              </Button>
            </Row>
            <ErrorDialog title={t('orders.confirm_buy_error')} message={errorMessage} visible={showErrorModal} onRedo={handleErrorDialogRedo} onCancel={handleErrorDialogCancel} />
          </div>
        </Spin>
      )}
    </>
  )
}

export default OrderConfirmation
