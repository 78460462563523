import React, { useState } from "react"
import {Card, Typography, Row, Col, Button} from 'antd';
import { get } from 'lodash'
import { discountValueFormatter } from '../../../helpers/bookings'
import {convertMinsToHour, currency} from "../../../utils/number";
import { t } from '../../../helpers/translation'
import { sumBy } from 'lodash'
import './styles.scss'

const { Paragraph } = Typography;
const MENU_LIMIT_DISPLAY = 3;

export const CouponCard = ({ coupon, isShowTagAll = true, onDelete }) => {
  const [ellipsis, setEllipsis] = useState(true);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [timeRequired, setTimeRequired] = useState(0);

  React.useEffect(() => {
    const serviceCategoriesData = get(coupon, 'serviceCategories');
    if (!serviceCategoriesData) { return; }
    setServiceCategories(serviceCategoriesData.slice(0, MENU_LIMIT_DISPLAY));
    setTimeRequired(sumBy(coupon, 'serviceCategories'))
  }, [coupon])

  return (
    <Card className="coupon__menu__card" bordered={false}>
      <Row className="coupon-info">
        {isShowTagAll &&
          <Col span={2} className="all">{t('coupon.all')}</Col>
        }

        <Col span={isShowTagAll ? 21 : 24} offset={isShowTagAll ? 1 : 0}>
          <Row>
            <Col span={16}>
              <div className="flex flex-nowrap gap-1">
                {Array.isArray(serviceCategories) &&  serviceCategories.map(category => {
                  return (
                    <div key={category} className="tag text-2xs"><span>{t(`coupon.enums.serviceCategories.${category}`)}</span></div>
                  )
                })}
              </div>
            </Col>
            <Col span={8}>
              <Typography className="coupon__price flex-1 text-right font-bold">{discountValueFormatter(coupon)}</Typography>
            </Col>
          </Row>
          <Row className="coupon-name">
            <Col span={16}>
              <Paragraph className="coupon__name flex-2" ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: '詳細' } : false}>{get(coupon, 'name')}</Paragraph>
            </ Col>
            <Col span={8}>
              { get(coupon, 'treatmentDuration') &&
                <Typography className="flex-1 text-right font-bold">{convertMinsToHour(coupon.treatmentDuration)}</Typography>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="flex flex-row py-2 px-2">
        <div className="coupon__image flex-none rounded" style={{ backgroundImage: `url("${get(coupon, 'image')}")` }} />
        <div className="pl-2 space-y-2">
          <div className="flex flex-row">
            <Typography className="font-bold text-2xs flex-none">{t('coupon.termOfUse')}</Typography>
            <Typography className="text-2xs pl-2">{get(coupon, 'termOfUse')}</Typography>
          </div>
          <div className="flex flex-row">
            <Typography className="font-bold text-2xs flex-none">{t('coupon.secondTermOfUse')}</Typography>
            <Typography className="text-2xs pl-2">{get(coupon, 'secondaryTermOfUse')}</Typography>
          </div>
        </div>
      </div>
      {
        onDelete && (
          <div className='flex content-right'>
            <Button className="float-right-btn btn-default btn btn-small my-4 text-sm" onClick={onDelete}>
              {t('booking.delete')}
            </Button>
          </div>
        )
      }
    </Card>
  );
}
