import React from 'react';
import { t } from '../../../helpers/translation';
import styles from './styles';

export const OrderConfirmationAddress = ({ user, shipping_address }) => {
  return (
    <div style={styles.container}>
      <div style={styles.box}>
        <div className="flex">
          <div style={styles.shippingText} className="text-xs mr-1">{`${t("orders.shipping_to")} `}: </div>
          <div style={styles.shippingAddress} className="text-xs text-left">
            <p>{user?.fullName}</p>
            <p>{user?.fullNameKana}</p>
            <p>{shipping_address?.post_code}</p>
            <p>{`${shipping_address?.prefectural} ${shipping_address?.municipality} ${shipping_address.street} ${shipping_address.building_name}`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
