export default {
  container: {
    paddingTop: 24,
    paddingBottom: 24,
  },
  title: {
    marginBottom: 12,
    fontSize: 16,
    fontWeight: 'bold'
  },
  applyText: {
    color: '#E96E75',
    fontSize: 12,
  },
  input: {
    borderRadius: 8,
    height: 56,
    border: '1px solid #E3E3E3',
  },
  btn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FCEEF1',
    borderRadius: 4,
    height: 30,
    width: 48,
    border: 'none',
  },
  errorText: {
    marginTop: 5,
    marginBottom: 12,
    fontSize: 12,
    fontWeight: 400,
  },
  successText: {
    marginTop: 5,
    marginBottom: 12,
    fontSize: 12,
    fontWeight: 400,
    color: 'green'
  }
}
