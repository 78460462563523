import React, { useEffect, useState } from 'react'
import { Row, Col, Typography, Checkbox, Divider, Select } from 'antd';
import { t } from '../../../helpers/translation'
import { currency } from '../../../utils/number'
import { getUsePoint, setUsePoint, MININUM_AMOUNT, discountValue, pointCalculate } from '../../../helpers/carts'
import styles from './styles'

export const CartInfo = ({ items = [], fee, user, onUsePoint, totalPrice, freeshipThreshold, subTotalPrice, totalDiscount, pointUsage, pointOptions, totalPriceExceptPoints }) => {
  const [pointError, setPointError] = useState(false)
  const [usingPoint, setUsingPoint] = useState(false)
  const [pointErrorMessage, setPointErrorMessage] = useState(t('carts.minBalanceCanUsedMessage'))
  const pointBalance = user && user.point_balances || null
  const pointsEarneds = items && items.reduce((acc, item) => { return (acc + item.pointEarned * item.quantity)}, 0) || 0
  const pointUsageMax = pointCalculate(pointBalance, totalPriceExceptPoints, pointBalance)

  const pointOptionsMap = pointOptions.filter((point) => point <= pointUsageMax)
  .filter((point) => !usingPoint || point > 0)
  .map((option) => {
    return { value: option, label: `${option}pt` }
  });

  useEffect(() => {
    const isUsePoint = getUsePoint()
    onUsePoint(isUsePoint)
    if(pointUsage > 0) {
      setUsingPoint(true)
    }
  })

  useEffect(() => {
    if (totalPriceExceptPoints <= MININUM_AMOUNT) {
      setUsePoint(0)
      onUsePoint(0)
      setPointError(true)
      setPointErrorMessage(t('carts.possessedErrorMessage'))
    }
  }, [totalPriceExceptPoints])

  const handleCalcUsePoint = (value) => {
    const isUse = value > 0;

    if (totalPriceExceptPoints <= MININUM_AMOUNT && isUse) {
      setPointErrorMessage(t('carts.possessedErrorMessage'))
      setPointError(true)
    } else {
      setPointErrorMessage(null)
      setPointError(false)
      setUsePoint(value)
      onUsePoint(value)
    }
  }

  const handleUsePoint = (value) => {
    setUsingPoint(value)
    if(!value) {
      pointUsage = 0
      handleCalcUsePoint(0)
    } else {
      pointUsage = pointOptions && pointOptions.length > 0 ? pointOptions[0].value : 0
      handleCalcUsePoint(pointUsage)
    }
  }

  return (
    <div style={styles.container}>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('orders.product_total_amount')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.value}>
            {currency(subTotalPrice)}
          </span>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('carts.shipping_fee')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.value}>
            {currency(fee)}
          </span>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Typography className='text-xs'>
          {t('orders.threshold_of_free', { value: currency(freeshipThreshold, false, '') })}
        </Typography>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('orders.discount')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.discountText}>
            {currency(totalDiscount)}
          </span>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('carts.point_balance')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.value} className='point'>
            {pointBalance ? pointBalance : 0}
          </span>
        </Col>
      </Row>
      {pointBalance > 0 && (
        <>
          <Row justify="space-between" align="middle">
            <Col>
              <Checkbox checked={usingPoint} style={styles.usePoints} onChange={(e) => handleUsePoint(e.target.checked)}>{t('carts.use_points')}</Checkbox>
            </Col>
          </Row>
          <Row justify="space-between" align="middle">
            <Col>
              <div style={styles.usePoints}>{t('carts.used_points')}</div>
            </Col>
            <Col>
              <Select
                value={pointUsage}
                style={{ width: 120, fontSize: 20 }}
                disabled={pointBalance < MININUM_AMOUNT || !usingPoint}
                onChange={handleCalcUsePoint}
                options={pointOptionsMap}
              />
            </Col>
          </Row>
          <Row>
            { (pointBalance < MININUM_AMOUNT || pointError) && 
              <Col col="24">
                <p className='text-red-500 text-xs'>
                  {pointErrorMessage}
                </p>
              </Col>
            }
          </Row>
        </>
      )}
      <Divider />
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('carts.total')}
          </Typography>
        </Col>
        <Col>
          <span style={styles.value}>
            {`${currency(totalPrice)}`}
          </span>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col>
          <Typography style={styles.label}>
            {t('carts.points_earned')}
          </Typography>
        </Col>
        <Col>
          <span className='point'>
            {pointsEarneds}
          </span>
        </Col>
      </Row>
    </div>
  )
}
