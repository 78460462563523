import React, { useEffect } from "react"
import { addStore } from '../../helpers/bookings'
import { Banner } from '../../reservation_components/Stores/Banner'
import { TabInfo } from '../../reservation_components/Stores/TabInfo'

const StoreDetail = ({ store, firstVisitCoupons, secondVisitCoupons, everyOneCoupons, activeTab, staffs, menuIds, couponIds }) => {
  useEffect(() => {
    addStore(store)
  }, [store])

  return (
    <>
      <Banner store={store} />
      <TabInfo 
        store={store} 
        firstVisitCouponsData={firstVisitCoupons} 
        secondVisitCouponsData={secondVisitCoupons} 
        everyOneCoupons={everyOneCoupons}
        activeTab={activeTab}
        staffs={staffs}
        menuIds={menuIds}
        couponIds={couponIds}
      />
    </>
  )
}

export default StoreDetail
