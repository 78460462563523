import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import LightBox from "../../common/LightBox";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const AsNavFor = ({ images }) => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openLightBox, setOpenLightBox] = useState(false);

  const slider1 = useRef(null)
  const slider2 = useRef(null)

  useEffect(() => {
    setNav1(slider1.current)
    setNav2(slider2.current)
  }, [])

  const nextArr = <img src="../../images/next_arrow.svg" />;

  const prevArr = <img src="../../images/prev_arrow.svg" />;

  return (
    <>
      <LightBox
        images={images.map((img) => img.thumbnail)}
        currentIndex={currentIndex}
        handleChange={(index) => setCurrentIndex(index)}
        open={openLightBox}
        handleOpen={(v) => setOpenLightBox(v)}
      />
      <div className="product-image-slider">
        <Slider
          asNavFor={nav2}
          ref={(slider) => (slider1.current = slider)}
          afterChange={(index) => setCurrentIndex(index)}
          style={{ background: "white" }}
        >
          {images.map((item, idx) => {
            return (
              <div key={idx} onClick={() => setOpenLightBox(true)}>
                <img src={item.thumbnail} />
              </div>
            );
          })}
        </Slider>
        <div style={{ marginTop: 15, background: "#FAFAFA" }}>
          <Slider
            asNavFor={nav1}
            ref={(slider) => (slider2.current = slider)}
            slidesToShow={images.length < 4 ? images.length : 4}
            swipeToSlide={true}
            focusOnSelect={true}
            infinite={true}
            className="product-image-slider-2"
            nextArrow={nextArr}
            prevArrow={prevArr}
          >
            {images.map((item, idx) => {
              return (
                <div key={idx} className="image-wrapper">
                  <img src={item.thumbnail} />
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default AsNavFor;
