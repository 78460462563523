import React, { useRef } from "react";
import Slider from "react-slick";
import { Col, Row, Typography, Button } from "antd"
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import styles from './styles.js'
import ProductCard from '../ProductCard'
import Review from '../../common/Review'
import { t } from '../../../helpers/translation';

const RelatedProducts = ({ products = [] }) => {
  const sliderRef = useRef()

  const settings = {
    className: "slider variable-width related-products-slider",
    dots: false,
    autoplay: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  }

  const handlePrev = () => sliderRef.current.slickPrev();
  const handleNext = () => sliderRef.current.slickNext();

  return (
    <div style={styles.container}>
      <Row>
        <span style={styles.icon}>
          <img src="../../images/gift.svg" />
        </span>
        <span style={styles.title}>
          {t('products.related_recomendation')}
        </span>
      </Row>
      <div style={styles.sliderWrapper}>
      <LeftOutlined style={styles.leftArrow} onClick={handlePrev} />
      <Slider {...settings} ref={sliderRef}>
        {products.map((product, index) => {
          return (
            <div style={styles.slider} key={index}>
              <ProductCard product={product} />
              <Review product={product} />
            </div>
          )
        })}
      </Slider>
      <RightOutlined style={styles.rightArrow} onClick={handleNext} />
      </div>
    </div>
  );
}

export default RelatedProducts
