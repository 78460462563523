import variables from '../../../styles/ec_users/_variables.scss';

export default {
  container: {
    backgroundColor: 'white',
    marginRight: -20,
    marginLeft: -20,
    padding: '24px 20px',
  },
  label: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#03212F',
  },
  feeNote: {
    color: '#C21829',
    fontWeight: 'bold',
    fontSize: 12,
  },
  unit: {
    fontSize: 12,
    fontWeight: 'bold',
    color: "#03212F",
  },
  value: {
    fontSize: 24,
    fontWeight: 'bold',
    color: '#03212F',
  },
  usePoints: {
    fontSize: 16,
    color: '#03212F',
  },
  discountText: {
    color: variables.primaryColor,
    fontSize: 24,
    fontWeight: 'bold'
  },
  pointUsage: {
    color: variables.primaryColor,
  }
}