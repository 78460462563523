import $ from "jquery";

$(document).on("turbolinks:load", () => {
  const mainContent = document.querySelector("#main");
  const btn = document.querySelector("button.mobile-menu-button");
  const menu = document.querySelector(".mobile-menu");
  const menuOverlay = document.querySelector(".mobile-menu-overlay");
  const closeBtn = document.querySelector(".menu-close-button");
  const cartIcon = document.querySelector(".header-cart-icon");
  const search = document.querySelector(".header-search");
  const alert = document.querySelector(".alert-msg");
  const closeAlert = document.querySelector(".alert-close-button");
  const categorySearch = document.querySelector(".category-search");
  const brandSearch = document.querySelector(".brand-search");
  const menuSearch = document.querySelector(".menu-search");
  const menuInput = document.querySelector(".menu-input");

  const category = "category";
  const brand = "brand";
  const logoutBtn = document.querySelector(".logout-btn")

  if (btn) {
    btn.addEventListener("click", () => {
      menu.classList.toggle("hidden");
      menuOverlay.classList.toggle("hidden");
      mainContent.classList.toggle("fixed");
      mainContent.classList.toggle("w-full");
    });
  }

  closeBtn.addEventListener("click", () => {
    menu.classList.toggle("hidden");
    menuOverlay.classList.toggle("hidden");
    mainContent.classList.toggle("fixed");
    mainContent.classList.toggle("w-full");
  })

  cartIcon.addEventListener("click", () => {
    window.location.href = "/ec_users/carts/show"
  })

  search.addEventListener("click", () => {
    window.location.href = "/ec_users/products/search"
  })

  closeAlert && closeAlert.addEventListener("click", () => {
    alert.classList.toggle("hidden");
  });

  categorySearch && categorySearch.addEventListener("click", () => {
    window.location.href = "/ec_users/products/search?selected_type=" + category
  });

  brandSearch && brandSearch.addEventListener("click", () => {
    window.location.href = "/ec_users/products/search?selected_type=" + brand
  });

  menuSearch && menuSearch.addEventListener("click", () => {
    window.location.href = `/ec_users/products/search?q=${menuInput.value}`
  });
  logoutBtn && logoutBtn.addEventListener("click", () => {
    localStorage.clear()
  })
});
