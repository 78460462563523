import { Col, Row, Typography } from 'antd'
import React from 'react'
import ListWithMoreButton from '../../components/ProductList/ListWithMoreButton/index.js'
import styles from './styles.js'

const BrandProducts = ({ products = [], brand }) => {
  return (
    <>
      <Row style={styles.row}>
        <Col>
          <Typography style={styles.text}>
            {brand.name}
          </Typography>
        </Col>
      </Row>
      <ListWithMoreButton products={products} />
    </>
  )
}

export default BrandProducts
