import { Button, Form, Input } from 'antd'
import React from 'react'
import { t } from '../../../helpers/translation'
import phoneNumberRegExp from '../../../utils/phoneNumberRegExp'
import styles from './styles'

const PhoneUpdate = () => {
  return (
    <div style={styles.container}>
      <Form.Item
        name="phone_number_mobile"
        label={t("new_user.phone")}
        rules={[
          {
            required: true,
            message: t("common.validates.required", { field: t("new_user.phone") }),
          },
          {
            pattern: phoneNumberRegExp,
            message: t("new_user.please_input_valid_phone_number")
          },
        ]}
        hasFeedback
      >
        <Input style={styles.inputHeight} />
      </Form.Item>

      <Form.Item
        name="password"
        label={t("new_user.password")}
        rules={[
          {
            required: true,
            message: t("new_user.password_required")
          }
        ]}
        hasFeedback
      >
        <Input.Password style={styles.inputHeight} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={styles.button} className="primary-btn-bg">
          {t('account_setting.save')}
        </Button>
      </Form.Item>
    </div>
  )
}

export { PhoneUpdate }
