import React, { useState, useRef } from 'react'
import { Button, Row, Carousel } from 'antd'
import Slider from "react-slick";
import { NewProductCard } from '../NewProductCard'
import { t } from '../../../helpers/translation'
import styles from './styles'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import './style.scss'

export const ProductList = ({ products = [], show_rank_image = false }) => {
  const sliderRef = useRef()

  const settings = {
    className: "slider variable-width related-products-slider",
    dots: false,
    autoplay: false,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
    swipeToSlide: true,
  }

  const handlePrev = () => sliderRef.current.slickPrev();
  const handleNext = () => { 
    if(sliderRef.current.innerSlider.state.currentSlide < sliderRef.current.innerSlider.state.slideCount - 3){
      sliderRef.current.slickNext();
    }
  }

  return (
    <div style={styles.container} className="px-2.5">
      <div style={styles.sliderWrapper}>
        { products.length > 3 &&
          <LeftOutlined style={styles.leftArrow} onClick={handlePrev} />
        }
        <Slider {...settings} ref={sliderRef} className='home-page-product-slider'>
          {products.map((product, index) => {
            return (
              <div className="card-slider" key={index}>
                <NewProductCard product={product} key={index} rank_image = {index+1} show_rank_image={show_rank_image} />
              </div>
            )
          })}
        </Slider>
        { products.length > 3 &&
          <RightOutlined style={styles.rightArrow} onClick={handleNext} />
        }
      </div>
    </div>
  );
}
