export default {
	row: {
		margin: "32px 20px 16px 20px",
	},
	col: {
		display: "flex",
		justifyConent: "center",
		marginRight: 10,
	},
	text: {
		fontSize: 20,
		fontWeight: "bold",
		color: "#03212F",
	},
	form: {
		width: "100%",
		position: "relative",
	},
	typeRow: {
		margin: "32px 20px 24px 20px",
	},
	defaultButton: {
		backgroundColor: "white",
		padding: "16px",
		borderRadius: "50px",
		marginBottom: "5px",
	},
	contentContainer: {
		marginLeft: 20,
		marginBottom: 24,
	},
	typeCol: {
		flex: "1",
		textAlign: "center",
	},
	icon: {
		position: "absolute",
	},
};