import { Form, notification, Typography, Spin } from "antd";
import React, { useState } from "react";
import { t } from '../../helpers/translation';
import { sendInquiry } from "../../services/users";
import InquiryForm from "./InquiryForm";
import styles from "./styles";

const Inquiry = ({ categories }) => {
  const [form] = Form.useForm();
  const [ submitting, setSubmitting ] = useState(false)

  const notify_error = (type) => (error) =>
    notification[type]({
      message: t("inquiry.error"),
      description: error,
    });

  const onFinish = async (values) => {
    setSubmitting(true)
    sendInquiry({ inquiry: values})
      .then((res) => {
        if (res && res.data && res.data.success) {
          window.location.href = '/ec_users';
        }
        else
        {
          notify_error("error")(res.message);
          setSubmitting(false)
        }
      })
      .catch(() => setSubmitting(false))
  }

  return (
    <div style={styles.container}>
      <Typography.Title level={3}>
        {t("inquiry.heading")}
      </Typography.Title>
      <Spin spinning={submitting}>
        <InquiryForm form={form} onFinish={onFinish} categories={categories} submitting={submitting} />
      </Spin>
      <br />
    </div>
  );
};

export default Inquiry;
