export default {
  card: {
    marginBottom: 12,
    marginTop: 12,
    marginLeft: 20,
    marginRight: 20,
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    padding: '16px 12px 16px 12px',
  },
  name: {
    fontSize: 16,
    color: '#03212F',
    marginLeft: 20,
  },
  logo: {
    width: 60,
    height: 60,
    border: '1px solid grey',
    padding: 6,
  }
}
