import React, { useState } from "react"
import { SliderImage } from '../Slider'
import { Typography, Button, Row, Col, Divider } from 'antd';
import { get } from 'lodash'
const { Paragraph, Title } = Typography;
import { clearBooking } from '../../../../helpers/bookings'
import { t } from '../../../../helpers/translation'
import './styles.scss'

export const Info = ({ store, onSetTabActive }) => {
  const [counter, setCounter] = useState(0)
  const [expand, setExpand] = useState(false)

  const typoExpand = () => {
    setExpand(true)
    setCounter(!expand ? counter + 0 : counter + 1)
  }

  const typoClose = () => {
    setExpand(false)
    setCounter(!expand ? counter + 0 : counter + 1)
  }

  const storeReservation = () => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('from_booking_schedule')) {
      clearBooking();
      window.location.href = `/reservation_users/stores/${store.id}?active_tab=2`
    } else {
      onSetTabActive(2)
    }
  }

  return (
    <React.Fragment>
      <SliderImage images={store.images} />
      <Typography className="store__name my-6">
        {get(store, 'catchphrase')}
      </Typography>
      <div>
        <pre className="whitespace-pre-wrap" key={counter}>
          <Paragraph className="stylist__desciption" ellipsis={{ rows: 5, expandable: true, onExpand: typoExpand, symbol: t('common.expandEllips') }}>
            {get(store, 'description')}
          </Paragraph>
        </pre>
        {expand && <a onClick={typoClose}>{t('staff.close')}</a>}
      </div> 
      <Button className="btn-default btn" onClick={storeReservation}>
        {t('store.reservatedBtn')}
      </Button>
      <div>
        <div className="flex flex-row items-center my-6 mt-10">
          <div className="mr-2"><img src='/images/map.svg' /></div>
          <Typography className="store__map-title">{t('store.mapTitle')}</Typography>
        </div>
        <div>
          <div
            className="map__url"
            dangerouslySetInnerHTML={{ __html: get(store, 'mapUrl') }}></div>
        </div>
      </div>
      <div className="my-4 mb-16">
        <Row gutter={16} align="middle">
          <Col className="gutter-row" span={8}>
            <div className="font-bold">{t('store.address')}</div>
          </Col>
          <Col className="gutter-row" span={16}>
            <div>{get(store, 'address')}</div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}  align="middle">
          <Col className="gutter-row" span={8}>
            <div className="font-bold">{t('store.phone')}</div>
          </Col>
          <Col className="gutter-row" span={16}>
            <div className="flex flex-row items-center bg-gray-50 rounded py-2">
              <div className="mx-2" style={{ width: '14px', height: '14px' }}><img src='/images/phone.svg' /></div>
              <a className="store__phone" href={`tel:${get(store, 'phoneNumber')}`}>{get(store, 'phoneNumber')}</a>
            </div>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}  align="middle">
          <Col className="gutter-row" span={8}>
            <div className="font-bold">{t('store.businessHour')}</div>
          </Col>
          <Col className="gutter-row" span={16}>
            <div>{get(store, 'businessHoursStr')}</div>
          </Col>
        </Row>
        <Row gutter={16} align="middle" className="mt-2">
          <Col className="gutter-row" span={8}></Col>
          <Col className="gutter-row" span={16}>
            <div dangerouslySetInnerHTML={{ __html: get(store, 'businessHourOtherStr').replace(/\r\n/g, '<br>') }} />
          </Col>
          
        </Row>
      </div>
    </React.Fragment>
  );
}
