import React, { useState } from 'react'
import { Row, Typography, Select, Input, Radio, Modal, Form, Button, Col, notification } from 'antd'
import { RightOutlined } from '@ant-design/icons'
import postalCode from 'japan-postal-code'
import { updateUser, checkPostCode } from '../../services/users'
import { t } from '../../helpers/translation'
import styles from './styles'

export const ShippingAddressDialog = ({ title, showModal, onHideModal, onUpdateUserInfo, user, prefectures, isConfirmation }) => {
  const [zipCode, setZipCode] = useState(user?.zip_code)
  const [form] = Form.useForm()

  const onFinish = async (values) => {
    try {
      const res = await updateUser({ user: { ...user, ...values, zip_code: zipCode}, id: user.id })
      if (res?.data?.success) {
        onUpdateUserInfo(res.data.user)
        onHideModal(false)
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message
      });
    }
  };

  const handleCancel = () => {
    onHideModal(false)
  };

  const handlePostCode = async () => {
    try{
      let res = await checkPostCode({zip_code: zipCode});

      if (res.data.success) {
        postalCode.get(zipCode, (address) => {
          form.setFieldsValue({
            municipality: address?.city,
            address: address?.street,
            prefecture: address?.prefecture
          });
        });
      }
    } catch(err) {
      notification.error({
        message: err?.response?.data?.message
      });
      form.setFieldsValue({
        municipality: null,
        address: null,
        prefecture: null
      });
    }
  }

  const checkAddress = async () => {
    try{
      let res = await checkPostCode({zip_code: zipCode});

      if (res.data.success) {
        form.submit();
      }
    } catch(err) {
      notification.error({
        message: err?.response?.data?.message
      });
    }
  }

  return (
    <Modal
      title={title}
      visible={showModal}
      style={{ maxWidth: 400 }}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        layout="horizontal"
        initialValues={user}
        onFinish={onFinish}
      >
        <Row>
          <Typography style={styles.title}>
            {t('new_user.customer_name')}
          </Typography>
        </Row>
        <Form.Item
          label={t('new_user.last_name_kanji')}
          name="last_name"
          rules={[
            {
              required: true,
              message: t('common.validates.required', { field: t('new_user.last_name_kanji') })
            },
            {
              max: 50,
              message: t("new_user.last_name_length"),
            },
          ]}
        >
          <Input disabled={isConfirmation} style={styles.inputHeight} />
        </Form.Item>
        <Form.Item
          label={t('new_user.first_name_kanji')}
          name="first_name"
          rules={[
            {
              required: true,
              message: t('common.validates.required', { field: t('new_user.first_name_kanji') })
            },
            {
              max: 50,
              message: t("new_user.first_name_length"),
            },
          ]}
        >
          <Input disabled={isConfirmation} style={styles.inputHeight} />
        </Form.Item>
        <Form.Item
          label={t('new_user.last_name_kana')}
          name="last_name_kana"
          rules={[
            {
              required: true,
              message: t('common.validates.required', { field: t('new_user.last_name_kana') })
            },
            {
              max: 50,
              message: t("new_user.last_name_length"),
            },
          ]}
        >
          <Input disabled={isConfirmation} style={styles.inputHeight} />
        </Form.Item>
        <Form.Item
          label={t('new_user.first_name_kana')}
          name="first_name_kana"
          rules={[
            {
              required: true,
              message: t('common.validates.required', { field: t('new_user.first_name_kana') })
            },
            {
              max: 50,
              message: t("new_user.first_name_length"),
            },
          ]}
        >
          <Input disabled={isConfirmation} style={styles.inputHeight} />
        </Form.Item>
        <Row>
          <Typography style={styles.title}>
            {t('new_user.address')}
          </Typography>
        </Row>
        <Form.Item
          label={t('new_user.postcode')}
          name="zip_code"
          rules={[
            {
              required: true,
              message: t('common.validates.required', { field: t('new_user.postcode') })
            },
            {
              max: 10,
              message: t("new_user.zip_code_length")
            }
          ]}
        >
          <Input disabled={isConfirmation} style={styles.inputHeight} value={zipCode} onChange={(e) => setZipCode(e.target.value)} />
        </Form.Item>
        <Row>
          <Button onClick={handlePostCode} style={styles.searchAddress}>{t('new_user.address_search')}</Button>
        </Row>
        <Form.Item
          label={t('new_user.prefecture')}
          name="prefecture"
          rules={[
            {
              required: true,
              message: t('common.validates.required', { field: t('new_user.prefecture') })
            },
          ]}
        >
          <Select className='w-full' disabled={isConfirmation}>
            {prefectures && prefectures.map((item) => (
              <Select.Option key={item["code"]} value={item["name"]}>{item["name"]}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label={t('new_user.municipality')}
          name="municipality"
          rules={[
            {
              required: true,
              message: t('common.validates.required', { field: t('new_user.municipality') })
            }
          ]}
        >
          <Input disabled={isConfirmation} style={styles.inputHeight} />
        </Form.Item>
        <Form.Item
          label={t('new_user.street')}
          name="address"
          rules={[
            {
              required: true,
              message: t('common.validates.required', { field: t('new_user.street') })
            },
            {
              max: 150,
              message: t("new_user.address_length"),
            },
          ]}
        >
          <Input
            disabled={isConfirmation}
            style={styles.inputHeight}
          />
        </Form.Item>
        <Form.Item
          label={t('new_user.building')}
          name="building"
          rules={[
            {
              max: 50,
              message: t("new_user.building_length"),
            }
          ]}
        >
          <Input disabled={isConfirmation} style={styles.inputHeight} />
        </Form.Item>
        {!isConfirmation && <Form.Item>
          <Button onClick={checkAddress} className="btn-default btn"  type="primary">
            {zipCode ? t('new_user.update') : t('new_user.submit') }
          </Button>
        </Form.Item>}
      </Form>
    </Modal>
  )
}
