import React from "react"

import "./index.scss";

const ComingSoon = () => {
    return (
        <section className="bgImage text-center relative">
            <div className="bgImage__content">
                <div className="mb-28">
                    <h1 className="text-5xl text-white font-medium">
                        近日公開予定
                    </h1>
                    <p className="text-white font-light">COMING SOON</p>
                </div>
                <a href={`/`} className="flex rounded-sm justify-center items-center bg-bgColor-300 flex-col w-fit mx-auto text-xl text-white font-light px-5 py-2.5" >
                    ホームへ戻る
                </a>
            </div>
            <span className="bgImage__bgGradient"></span>
        </section>
    )
}

export default ComingSoon