import React from 'react'
import CategoryCard from '../../components/Categories/CategoryCard/index.js'
import styles from './styles.js'

const Category = ({ categories }) => {
  return (
    <div style={styles.container}>
      <div style={styles.elementContainer}>
        {categories.map((category, index) => {
          return (
            <div key={index}>
              <a href={`/ec_users/categories/${category.id}/category_products`}>
                <CategoryCard category={category} />
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Category
