export default {
  button: {
    marginTop: 20,
    marginBottom: 30,
    backgroundColor: '#E96E75'
  },
  container: {
    marginTop: 24
  },
  payRow: {
    margin: 15,
    borderRadius: 20,
    height: 80,
    boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.1)',
    padding: 15,
  },
  addNew: {
    fontSize: 16,
    color: '#03212F',
  },
  addCard: {
    fontSize: 16,
    color: '#03212F',
    marginBottom: 20,
  }
}
