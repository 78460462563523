import { Row, Col } from 'antd'
import React from "react"
import { ProductList } from '../ProductList'
import styles from './styles'

export const CategoryRankingProducts = ({ products = [], title, applyWhiteBG=false, subTitle=null }) => {
  return (
    <Row style={styles.row} className={`px-2.5 ${applyWhiteBG && 'bg-white'}`}>
      <Col style={styles.fullWidth}>
        <span style={styles.text}>
          {title}
        </span>
      </Col>
      {subTitle && 
        <Col style={styles.subTitle}>
          <span style={styles.text}>
            {subTitle}
          </span>
        </Col>
      }
      <ProductList products={products} show_rank_image={true} />
    </Row>
  );
}
