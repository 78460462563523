export default {
  container: {
    backgroundColor: '#F6F6F6',
    padding: '32px 20px 0 20px',
  },
  header: {
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
  },
  cartText: {
    marginLeft: 10,
    fontWeight: 700,
    fontSize: 20,
  },
  checkoutBtn: {
    height: '100%',
    backgroundColor: '#E96E75',
    width: '100%',
    borderRadius: 4,
    height: 48,
    border: 'none'
  },
  continuePurchaseBtn: {
    width: '100%',
    border: '1px solid #03212F',
    borderRadius: 4,
    boxSizing: 'border-box',
    height: 48,
    marginBottom: 32,
  },
  continuePurchaseText: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: 7,
  },
  checkoutText: {
    color: 'white',
    fontWeight: 700,
    fontSize: 14,
  },
  footerCart: {
    backgroundColor: 'white',
    marginRight: -20,
    marginLeft: -20,
    padding: '9px 20px 9px 20px',
  },
  totalText: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  noItem: {
    marginTop: 32,
    fontSize: 20,
    fontWeight: 'bold',
    color: '#999999',
    textAlign: 'center'
  },
  continueBuyingWrapper: {
    marginTop: 80,
    marginBottom: 80,
    border: '1px solid #E96E75',
    backgroundColor: 'white',
    height: 64,
    width: '90%',
  },
  continueBuying: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#E96E75',
    paddingTop: 15
  },
}
