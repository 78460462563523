import React from "react"
import { Card, Typography, Divider, Row, Col, Avatar, Checkbox, Tag, Radio, Space, Input, Form, Select } from 'antd';
import { get, sumBy } from 'lodash'
import { currency, convertMinsToHour } from '../../../utils/number'
import { CouponCard } from '../CouponCard'
import moment from 'moment'
import { TIME_FORMAT, DATE_FORMAT } from '../../../utils/datetime'
import { calculateDiscount, pointCalculate, getUsePoint, setUsePoint, MININUM_AMOUNT } from '../../../helpers/bookings'
import variables from '../../../styles/ec_users/_variables.scss';
import { t } from '../../../helpers/translation'
import './styles.scss'

const { TextArea } = Input;

export const Summary = ({ store, schedule, stylist, coupons = [], selectedMenus = [], currentUser, pointOptions, totalCouponPrice, onUsePoint }) => {
  const [totalTreatmentTime, setTotalTreatmentTime] = React.useState(0);
  const [totalTreatmentPrice, setTotalTreatmentPrice] = React.useState(0);
  const [selectedMenuSummary, setSelectedMenuSummary] = React.useState(null);
  const [selectedMenuPrice, setSelectedMenuPrice] = React.useState(null);
  const [selectedMenuCouponPrice, setSelectedMenuCouponPrice] = React.useState(0);
  const [subtotalAmount, setSubtotalAmount] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalAmountExceptPoints, setTotalAmountExceptPoints] = React.useState(0);
  const [discount, setDiscount] = React.useState(0);
  const [point, setPoint] = React.useState(get(currentUser, 'pointBalances') || 0);
  const [pointUsage, setPointUsage] = React.useState(0);
  const [menuShowMark, setMenuShowMark] = React.useState(false);
  const [pointsEarned, setPointsEarned] = React.useState(null);
  const pointBalance = currentUser && get(currentUser, 'pointBalances') || null;
  const pointUsageMax = pointCalculate(pointBalance, totalAmountExceptPoints, pointBalance)
  
  const [pointError, setPointError] = React.useState(false)
  const [usingPoint, setUsingPoint] = React.useState(false)
  const [pointErrorMessage, setPointErrorMessage] = React.useState(t('booking.possessedErrorMessage'))
  
  React.useEffect(() => {
    const menuSummary = selectedMenus?.map((menu) => menu.name).join('+')
    const menuCoupons = coupons?.filter((coupon) => coupon?.defineCategory)
    const totalTime = sumBy(selectedMenus, 'requiredTime') + sumBy(menuCoupons, 'treatmentDuration');
    const totalMenuPrice = sumBy(selectedMenus, 'priceWithTax')
    const totalMenuCouponPrice = sumBy(menuCoupons, 'price');
    const totalPrice = totalMenuPrice + totalMenuCouponPrice
    const discountCoupons = coupons?.filter((coupon) => !coupon.defineCategory)
    const discount = calculateDiscount(discountCoupons, totalPrice)
    const menuHasMark = selectedMenus?.find((el) => el.isShowMark)
    setPointsEarned(sumBy(selectedMenus, 'pointEarned') + sumBy(menuCoupons, 'pointEarned'))
    setMenuShowMark(!!menuHasMark)
    setSelectedMenuPrice(totalMenuPrice)
    setSelectedMenuCouponPrice(totalMenuCouponPrice)
    setTotalTreatmentTime(totalTime);
    setTotalTreatmentPrice(totalPrice - discount);
    setSelectedMenuSummary(menuSummary)
    setDiscount(discount)
  }, [selectedMenus, coupons])

  React.useEffect(() => {
    const nominatedFee = get(stylist, 'nominated?') ? get(stylist, 'nominationFeeValue') || 0 : 0;
    const amount = nominatedFee + selectedMenuCouponPrice + selectedMenuPrice;
    const amountWithDiscount = nominatedFee + totalTreatmentPrice;
    setSubtotalAmount(amount)
    setTotalAmount(amountWithDiscount);
    setTotalAmountExceptPoints(amountWithDiscount)
  }, [selectedMenuPrice, selectedMenuCouponPrice, totalTreatmentPrice, stylist, discount])

  React.useEffect(() => {
    const isUsePoint = getUsePoint()
    onUsePoint(isUsePoint)
    if(pointUsage > 0) {
      setUsingPoint(true)
    }
  })

  React.useEffect(() => {
    if (totalAmountExceptPoints <= MININUM_AMOUNT) {
      setUsePoint(0)
      onUsePoint(0)
      setPointError(true)
      setPointErrorMessage(null)
    }
  }, [totalAmountExceptPoints])
  
  const onCalculatePoint = (value) => {
    const isUse = value > 0;

    if (totalAmountExceptPoints <= MININUM_AMOUNT && isUse) {
      setPointErrorMessage(t('booking.possessedErrorMessage'))
      setPointError(true)
    } else {
      setTotalAmount(subtotalAmount - discount - value)
      setPointErrorMessage(null)
      setPointError(false)
      setUsePoint(value)
      onUsePoint(value)
      setPointUsage(value)
    }
  }

  const pointOptionsMap = pointOptions.filter((point) => point <= pointUsageMax)
  .filter((point) => !usingPoint || point > 0)
  .map((option) => {
    return { value: option, label: `${option}pt` }
  });

  const handleUsePoint = (value) => {
    setUsingPoint(value)
    if(!value) {
      onCalculatePoint(0)
    } else {
      let pointUsage = pointOptionsMap && pointOptionsMap.length > 0 ? pointOptionsMap[0].value : 0
      onCalculatePoint(pointUsage)
    }
  }

  return (
    <div  className="booking__summary">
      <Card type="inner" title={t('booking.summaryTitle')}>
        <Row align="middle">
          <Col span={8} className="font-bold">{t('booking.salonName')}</Col>
          <Col span={16}>{get(store, 'name')}</Col>
          <Divider />
          <Col span={8} className="font-bold">{t('booking.dateTimeVisit')}</Col>
          <Col span={16}>{moment.utc(get(schedule, 'day')).format(DATE_FORMAT)} {moment.utc(get(schedule, 'time'), TIME_FORMAT).format(TIME_FORMAT)}~</Col>
          <Divider />
          <Col span={8} className="font-bold">{t('booking.treatmentTime')}</Col>
          <Col span={16}>{convertMinsToHour(totalTreatmentTime)}</Col>
          <Divider />
          {coupons?.map(coupon => (
            <React.Fragment>
              <Col span={8} className="font-bold">{t('booking.coupon')}</Col>
              <Col span={16}><CouponCard coupon={coupon} isShowTagAll={false} /></Col>
              <Divider />
            </React.Fragment>
          ))}
          <Col span={8} className="font-bold">{t('booking.menuTitle')}</Col>
          <Col span={12}>{selectedMenuSummary}</Col>
          <Col span={4} className="font-bold text-right">
            {currency(selectedMenuPrice, menuShowMark)}
            <span className='text-xs'>{t('common.taxInclude')}</span>
          </Col>
          {(stylist && !stylist.isVirtual) &&
            <React.Fragment>
              <Divider />
              <Col span={8} className="font-bold">{t('booking.stylistTitle')}</Col>
              <Col span={12} className="font-bold">
                <div className="flex flex-row items-center justify-center">
                  <Avatar src={get(stylist, 'avatar')}/>
                  <span className="break-all pl-2">{get(stylist, 'displayName')}</span>
                </div>
              </Col>
              { get(stylist, 'nominated?') &&
                <Col span={4} className="font-bold text-right">
                  <Typography className="font-bold">{currency(get(stylist, 'nominationFeeValue'))}</Typography>
                </Col>
              }
            </React.Fragment>
          }
        </Row>
      </Card>
      <Card type="inner" title={t('booking.estimatedPaymentTitle')}>
        <Row align="middle">
          <Col span={8} className="font-bold">{t('booking.ownerPoint')}</Col>
          <Col span={16} className="font-bold text-right">{(point)?.toString()?.replace('-', '')}pt</Col>
          {false && point > 0 && (
            <>
            <Divider />
            <Col span={8} className="font-bold">{t('booking.usagePoint')}</Col>
            <Col span={16} className="text-right">
              <Form.Item name="usage_point" valuePropName="checked">
                <Checkbox checked={usingPoint} value={1} onChange={(e) => handleUsePoint(e.target.checked)} />
              </Form.Item>
            </Col>
            <Divider />
              <Col span={8} className="font-bold">{t('booking.usedPoint')}</Col>
              <Col span={16} className="font-bold text-right point-small text-red-200">
                <Select
                  value={pointUsage}
                  style={{ width: 120, fontSize: 20 }}
                  disabled={pointBalance < MININUM_AMOUNT || !usingPoint}
                  onChange={onCalculatePoint}
                  options={pointOptionsMap}
                />
              </Col>
              <Row >
                { (pointBalance < MININUM_AMOUNT || pointError) && 
                  <Col col="24">
                    <p className='text-red-500 text-xs'>
                      {pointErrorMessage}
                    </p>
                  </Col>
                }
              </Row>
            </>
          )}
          <Divider />
          {totalCouponPrice > 0 &&
            <>
              <Col span={8} className="font-bold">{t('booking.couponPrice')}</Col>
              <Col span={16} className="text-right">{totalCouponPrice}</Col>
              <Divider />
            </>
          }
          <Col span={8} className="font-bold">{t('booking.totalAmount')}</Col>
          <Col span={16} className="font-bold text-right">
            {currency(totalAmount)}
            <span className='text-xs'>{t('common.taxInclude')}</span>
          </Col>
          <Divider />
          <Col span={8} className="font-bold">{t('booking.pointsToBeEarned')}</Col>
          <Col span={16} className="text-right">{`${pointsEarned} ${t('booking.points')}`}</Col>
        </Row>
      </Card>
      <Card type="inner" title={t('booking.confirmItemTitle')}>
        <Row align="middle">
          <Col span={24}>
            <div className="flex flex-row items-center">
              <Tag color={variables.redBold}>{t('common.required')}</Tag>
              <span className="ml-2 font-bold text-sm">{t('booking.isFirstTimeDesc')}</span>
            </div>
          </Col>
          <Col span={24} className="mt-2">
            <Form.Item name="visit_time" rules={[{ required: true, message: t('common.required') }]}>
              <Radio.Group value={0}>
                <Space direction="vertical">
                  <Radio value={'first_time'}>{t('booking.yesMyFirstTime')}</Radio>
                  <Radio value={'visited_before'}>{t('booking.noIdoneBefore')}</Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24}>
            <div className="flex flex-row items-center">
              <Tag color={variables.redBold}>{t('common.required')}</Tag>
              <span className="ml-2 font-bold text-sm">{t('booking.notedOnVisit')}</span>
            </div>
          </Col>
          <Col span={24} className="mt-2 text-xs text-gray-777">
            {store.noteVisit}
          </Col>
          <Col span={24} className="mt-2">
            <Form.Item name="agree" valuePropName="checked" rules={[{ required: true, message: t('common.required') }]}>
              <Checkbox>{t('booking.notedAgree')}</Checkbox>
            </Form.Item>
          </Col>
          <Divider />
          <Col span={24}>
            <div className="flex flex-row items-center">
              <Tag color={variables.redBold}>{t('common.required')}</Tag>
              <span className="ml-2 font-bold text-sm">{t('booking.questionForSalon')}</span>
            </div>
          </Col>
          <Col className="mt-2 text-xs text-gray-777">
            {store.questionsOnVisit}
          </Col>
          <Col span={24} className="mt-2">
            <Form.Item name="agreeable_question" rules={[{ required: true, message: t('common.required') }, { max: 1024, message: t('common.too_long') }]}>
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
