import consumer from "./consumer";
import { notification } from "antd";
import { t } from "../../javascript/helpers/translation";
import { cancelSettlement } from "../../javascript/services/dashboards/pos/settlement";

const POS_SETTLEMENT_HISTORY = '/dashboard/settlement_histories'

consumer.subscriptions.create("CreditTerminalChannel", {
  received(data) {
    if (window.location.pathname == POS_SETTLEMENT_HISTORY && (data.type == "POS_CREDIT_REFUND" || data.type == "POS_CREDIT_VOID")) {
      if (data.response == "success") {
        handleSubmit(data.settlement_id, data.staff_id);
      } else if (data.response == "error") {
        handleError(data);
      }
    }
  },

  subscribed: function () {
    return this.perform("subscribed");
  },
});

const handleSubmit = async (settlementId, staffId) => {
  try {
    const response = await cancelSettlement({
      id: settlementId,
      staff_id: staffId,
    });

    if (response?.data?.success) {
      notification.success({
        message: t(`settlement.credit_stera_refund_success`),
      });
      window.location.reload();
    }
  } catch (err) {
    notification.error({
      message: err?.response?.data?.message,
    });
  }
};

const handleError = (e) => {
  try {
    const ngContent = e.ng_content;
    if (ngContent) {
      notification.error({
        message: t(`settlement.credit_stera_errors.${ngContent}`),
      });
    } else {
      notification.error({
        message: t(`settlement.credit_stera_errors.undefined`),
      });
    }
      
    setTimeout(function() {
      window.location.reload();
    }, 2000);
  } catch (err) {
    notification.error({
      message: err?.response?.data?.message,
    });
  }
};
