import React, { useState } from "react"
import { Col, Row, Typography, Button } from "antd"
import { get } from 'lodash'
import styles from './styles'
import { t } from '../../../helpers/translation';
import { currency } from '../../../utils/number'

const { Paragraph } = Typography;

const ProductCard = ({ product, stylist }) => {
  const [ellipsis, setEllipsis] = useState(true);

  const productDetailHref = () => {
    if (stylist) {
      return `/ec_users/products/${product.id}?staff_id=${stylist.id}`;
    }
    
    return `/ec_users/products/${product.id}`
  }

  return (
    <div style={styles.card}>
      <Row>
        <a href={productDetailHref()}>
          <img src={product.image} style={styles.image} />
        </a>
      </Row>
      <Row style={styles.content}>
        <Row style={styles.wrapper}>
          <Typography style={styles.brand}>
            {get(product, 'productBrand.name')}
          </Typography>
        </Row>
        <Row style={styles.wrapper}>
          <a href={productDetailHref()}>
            <Paragraph ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: 'more' } : false} style={styles.name}>
              {get(product, 'nameWithVariant')}
            </Paragraph>
          </a>
        </Row>
        <Row style={styles.wrapper}>
          <span style={styles.price}>
            {currency(get(product, 'priceWithTax'))}
          </span>
          <span style={styles.extraPrice}>
            ({t('products.taxt_include')})
          </span>
        </Row>
      </Row>
    </div>
  )
}

export default ProductCard
