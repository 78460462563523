export default {
  image: {
    height: 80,
    width: 80,
    objectFit: 'cover',
    borderRadius: 4,
    mixBlendMode: 'multiply',
    border: '1px solid #E3E3E3',
  },
  contentWrapper: {
    marginBottom: 14,
    width: '100%',
  },
  info: {
    marginLeft: 10,
  },
  brandText: {
    fontWeight: 400,
    fontSize: 12,
  },
  prdNameText: {
    fontWeight: 700,
    fontSize: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    fontVariant: 'proportional-width',
  },
  priceText: {
    fontWeight: 700,
    fontSize: 16,
  },
  priceNote: {
    fontWeight: 700,
    fontSize: 12,
    marginLeft: 5,
    alignSelf: 'center',
  },
  storeText: {
    fontSize: 10,
    fontWeight: 400,
    color: '#999999'
  },
  addItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 24,
  },
  icon: {
    color: '#E96E75',
  },
  iconBtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  removeBtn: {
    height: 34,
    width: 88,
    borderRadius: 4,
    border: '1px solid #E3E3E3',
  },
  removeText: {
    color: '#777777',
    fontSize: 12,
  },
  errorMessage: {
    fontSize: 12,
    color: '#E96E75',
    marginTop: 5
  }
}
