import React, { useState } from "react"
import { Col, Row, Typography, Button } from "antd"
import { get } from 'lodash'
import styles from './styles'
import { t } from '../../../helpers/translation'
import { currency } from "../../../utils/number";

const { Paragraph } = Typography;

const ProductCard = ({ product }) => {
  const [ellipsis, setEllipsis] = useState(true);

  return (
    <div style={styles.card}>
      <Row>
        <a href={`/ec_users/products/${product.id}`}>
          <img src={product.image} style={styles.image} />
        </a>
      </Row>
      <Row style={styles.content}>
        <div style={styles.contentFixed}>
          <Row style={styles.wrapper}>
            <Paragraph ellipsis={ellipsis ? { rows: 1, expandable: false } : false} style={styles.brand}>
              {get(product, 'productBrand.name')}
            </Paragraph>
          </Row>
          <Row style={styles.wrapper}>
            <a href={`/ec_users/products/${product.id}`}>
              <Paragraph ellipsis={ellipsis ? { rows: 2, expandable: false } : false} style={styles.name}>
                {get(product, 'nameWithVariant')}
              </Paragraph>
            </a>
          </Row>
          <Row style={styles.wrapper}>
            <span style={styles.price}>
              {currency(get(product, 'priceWithTax'))}
            </span>
            <span style={styles.extraPrice}>
              { t('common.taxInclude') }
            </span>
          </Row>
        </div>
      </Row>
    </div>
  )
}

export default ProductCard
