import React, {useState, useEffect} from 'react';
import { Modal } from 'antd';

const PWAInstallDialog = ({}) => {
  const [isModalVisible, setIsModalVisible] = useState(true);
  let deferredPrompt;

  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    deferredPrompt = event;
  });

  window.addEventListener('appinstalled', () => {
    hideInstallPromotion();
    deferredPrompt = null;
  });
  
  const handleOk = (async () => {
    if (deferredPrompt) {
      try {
        setIsModalVisible(false);
        deferredPrompt.prompt();
      } 
      catch (error) {
        console.error('Error handling install prompt:', error);
      }
      deferredPrompt = null;
    }
  });

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      Modal.confirm({
        icon: null,
        title: 'ホーム画面にMB アプリのショートカットを作成しますか？',
        cancelText: '戻る',
        okText: '登録',
        onOk: handleOk,
        onCancel: handleCancel,
      });
    }
  }, [isModalVisible]);

  return (
    <>
    </>
  );
};

export default PWAInstallDialog;