import React, { useState } from "react";
import { Rate, Modal, Row, Typography } from "antd"
import { toNumber, round } from 'lodash'
import { t } from '../../../helpers/translation'
import styles from './styles'


const Rating = ({ average_rating }) => {
  let averageRating = toNumber(average_rating);
  const divider = averageRating / 1;
  const mode = averageRating % 1

  if (mode !== 0 && mode < 0.5 ) {
    averageRating = divider - mode + 0.5
  } else {
    averageRating = round(averageRating)
  }

  return (
    <Row>
      <Rate
        allowHalf
        value={averageRating}
        disabled
      />
    </Row>
  )
}

export default Rating
