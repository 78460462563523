import consumer from "./consumer"

const POS_SETTLEMENT_HISTORY = '/dashboard/settlement_histories'
const POS_UNPAID = '/dashboard/unpaid'
const POS_UNPAID_SPOT_USAGE_FEE = '/dashboard/unpaid_spot_usage_fee'
const POS_SETTLEMENT = '/dashboard/pos/settlements'
const POS_DEPOSIT = '/dashboard/deposits'
const POS_WITHDRAWAL = '/dashboard/pos/settlements'
const POS_COLLECT = '/dashboard/pos/settlements'

consumer.subscriptions.create('CashRegisterChannel', {
  received(data) {
    const urls_arr = [POS_SETTLEMENT_HISTORY, POS_UNPAID, POS_UNPAID_SPOT_USAGE_FEE, POS_SETTLEMENT, POS_DEPOSIT, POS_WITHDRAWAL, POS_COLLECT]
    if(urls_arr.includes(window.location.pathname)) {

      if(data.response == 'success'){
        window.location.reload()
      }
    }
  },

  subscribed: function() {
    return this.perform('subscribed');
  }
});
