import React from "react"
import { Row, Col, Typography } from "antd"
import Icon from '@ant-design/icons';
import styles from './styles.js'
import TiktokSvg from '-!react-svg-loader!/public/images/tiktok.svg';
import InstagramSvg from '-!react-svg-loader!/public/images/instagram.svg';
import YoutubeSvg from '-!react-svg-loader!/public/images/youtube.svg';
import TwitterSvg from '-!react-svg-loader!/public/images/twitter.svg';
import { Anchor } from 'antd';

const { Link } = Anchor;

const SnsUrls = ({ youtubeUrl, tiktokUrl, instagramUrl, twitterUrl, mode = 'svg__primary' }) => {
  return (
    <div style={styles.container} className="flex">
      <div className="flex flex-row justify-center space-x-10 items-center" style={styles.row}>
        {twitterUrl && 
          <a href={twitterUrl} target="_blank">
            <TwitterSvg className={mode} />
          </a>
        }
        
        {tiktokUrl && 
          <a href={tiktokUrl} target="_blank">
            <TiktokSvg className={mode} />
          </a>
        }

        {instagramUrl && 
          <a href={instagramUrl} target="_blank">
            <InstagramSvg className={mode} />
          </a>
        }

        {youtubeUrl &&
          <a href={youtubeUrl} target="_blank">
            <YoutubeSvg className={mode} />
          </a>
        }
      </div>
    </div>
  )
}

export default SnsUrls
