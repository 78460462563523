import React from "react"
import { Card, Row, Button, Collapse, Typography } from 'antd';
import { get } from 'lodash'
import { NoData } from '../../NoData/'
import locationSvg from 'images/location.svg'
import { NewStoreCard } from '../../NewStoreCard';
import { t } from '../../../helpers/translation'
import './styles.scss'

const { Meta } = Card;

export const StoreList = ({ stores }) => {
  const { Panel } = Collapse;

  const imageWithLocation = (location) => {
    return (
      <Row>
        <img src={locationSvg} />
        <Typography className='font-bold ml-2'>{location}</Typography>
      </Row>
    )
  }

  const publishedStoresCount = (stores) =>{
    return stores.filter(store => store.isShow).length
  }

  const totalPublishedStores = stores.reduce((count, [, storeRecords]) => {
    const showCount = storeRecords.filter(store => store.isShow).length;
    return count + showCount;
  }, 0);

  return (
    <>
      {(totalPublishedStores == 0)?
       <NoData />
      :
      <Row className="px-4">
        {stores.map((data, idx) => {
          return (
            (publishedStoresCount(data[1]) > 0 &&
              <Collapse expandIconPosition='right' className='stores-collapse'>
                <Panel header={imageWithLocation(data[0])} key={idx}>
                  {data[1].map((store, idx) => {
                    return (
                      ((store.isShow) && 
                        <NewStoreCard store={store} key={idx} />
                      )
                    )
                  })}
                </Panel>
              </Collapse>
            ))
        })}
      </Row>}
    </>
  );
}
