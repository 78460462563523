import React, { useState } from "react"
import { StylistEcInfo } from '../../reservation_components/StylistEcInfo'
import { RecommendProducts } from '../../components/RecommendProducts'
import { Button } from 'antd'
import { t } from '../../helpers/translation'
import './styles.scss'

const StylistEcDetail = ({ stylist, recommendedProducts }) => {
  const [recProducts, setRecProducts] = useState(recommendedProducts.slice(0, 2))
  const [showRecommendedProductsBtn, setShowRecommendedProductsBtn] = useState(recommendedProducts.length > 2)
  const [offsetProduct, setOffsetProduct] = useState(0)

  const handleGoToStylistDetail = () => {
    window.location = `/reservation_users/staffs/${stylist.id}`
  }

  const disabledReservation = () => {
    return stylist && stylist?.notAllowNomination
  }

  const handleShowRecommendedProducts = () => {
    setRecProducts(recommendedProducts.slice(0, 2 + offsetProduct + 10))
    setOffsetProduct(offsetProduct + 10)
    if (offsetProduct + 10 >= recommendedProducts.length) {
      setShowRecommendedProductsBtn(false)
    }
  }
  
  return (
    <div className="bg-white">
      <StylistEcInfo stylist={stylist} />
      {recProducts && recProducts.length > 0 &&
        <React.Fragment>
          <RecommendProducts
            className="staff__recommendedProducts"
            products={recProducts}
            heading={t('staff.headingRecommendedProduct')}
            stylist={stylist}
          />
          { showRecommendedProductsBtn &&
            <div className="py-10 px-20 bg-gray-50 w-100">
              <Button className="btn-outline-primary btn btn-small" onClick={handleShowRecommendedProducts}>
                {t('staff.seeAllRecommendedProductBtn')}
              </Button>
            </div>
          }
        </React.Fragment> 
      }

      {!disabledReservation() && 
        <div className="pt-6 px-4 pb-10">
          <Button
            className="btn-default btn"
            onClick={handleGoToStylistDetail}
            disabled={disabledReservation()}
          >
            {t('staff.make_a_reservation')}
          </Button>
        </div>
      }
    </div>
  )
}

export default StylistEcDetail
