export default {
  container: {
    marginRight: 10,
    marginLeft: 10,
  },
  inputHeight: {
    height: 45,
  },
  button: {
    width: '100%',
    height: 48,
    backgroundColor: '#E96E75'
  }
}
