import React from "react"
import Search from '../../components/ProductList/Search'
import List from '../../components/ProductList/List'

const ProductList = ({ products }) => {
  const params = (new URL(document.location)).searchParams;
  let p = params.get('p');

  return (
    <>
      <Search searchText={p} />
      <List products={products} />
    </>
  )
}

export default ProductList