import React, {useEffect} from "react"
import { Banner as Carousel } from '../../components/Home/Banner'
import { StoreList } from '../../reservation_components/Home/StoreList'
import {clearBooking} from "../../helpers/bookings";

const HomeReservation = ({ banners, stores }) => {
  useEffect(() => {
    clearBooking()
  }, []);

  return (
    <>
      <Carousel images={banners} />
      <StoreList stores={stores} />
    </>
  )
}

export default HomeReservation
