export default {
  container: {
    width: '100%'
  },
  sliderWrapper: {
    position: 'relative',
  },
  leftArrow: {
    position: 'absolute',
    zIndex: 1,
    left: -18,
    top: '40%',
    cursor: 'pointer'
  },
  rightArrow: {
    position: 'absolute',
    zIndex: 1,
    right: -18,
    top: '40%',
    cursor: 'pointer'
  }
}