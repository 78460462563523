import React, { useEffect, useState } from "react"
import { Card, Typography, Button, Row, Col, notification } from 'antd';
import { get } from 'lodash'
import { t } from '../../helpers/translation'
import {convertMinsToHour} from "../../utils/number";
import { discountValueFormatter, getCoupon, getMenus, calculateDiscount } from '../../helpers/bookings'
import { addCoupon, getStore, clearBooking, getStylist, setAddCouponMenuFlag } from '../../helpers/bookings'
import { sumBy } from 'lodash'
import './styles.scss'

const { Paragraph } = Typography;
const MENU_LIMIT_DISPLAY = 3;

export const CouponCard = ({ coupon, storeId, showButtons = true }) => {
  const [ellipsis, setEllipsis] = useState(true);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [timeRequired, setTimeRequired] = useState(0);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  useEffect(() => {
    const serviceCategoriesData = get(coupon, 'serviceCategories');
    if (!serviceCategoriesData) { return; }
    setServiceCategories(serviceCategoriesData.slice(0, MENU_LIMIT_DISPLAY));
    setTimeRequired(sumBy(coupon, 'treatmentDuration'))
  }, [coupon])

  const isDiffStore = () => {
    const selectedStore = getStore();
    const selectedStaff = getStylist();
    return (selectedStore && selectedStore.id !== storeId) || (selectedStaff && !selectedStaff.couponIds.includes(coupon.id))
  }

  const onBooking = () => {
    if (isDiffStore()) {
      clearBooking()
    }
    
    const coupons = getCoupon() || []
    const menuCoupons = coupons?.filter((coupon) => coupon?.defineCategory) || []
    const menus = getMenus() || []

    if (!coupon.defineCategory) {
      if (menus.length == 0 && menuCoupons.length === 0) {
        notification.error({
          message: t('coupon.errors.menuNotSelected')
        });
        return;
      }

      const totalPrice = sumBy(menus, 'priceWithTax') + sumBy(menuCoupons, 'price');
      const discount = calculateDiscount([coupon], totalPrice);
      if (discount > totalPrice) {
        notification.error({
          message: t('coupon.errors.couponDiscountGreaterThanPrice')
        });
        return;
      }
    }
    addCoupon(coupon);
    setAddCouponMenuFlag(true)
    
    if (params.ref) {
      window.location = params.ref
    } else {
      window.location = `/reservation_users/stores/${storeId}/bookings/schedule`
    }
  }

  return (
    <Card className="coupon__card my-4" bordered={false}>
      <Row className="px-4 coupon-info">
        <Col span={2} className="all">{t(`coupon.enums.primary_category.${get(coupon, 'primaryCategory')}`)}</Col>
        <Col span={21} offset={1}>
          <Row>
            <Col span={17}>
              <div className="flex flex-nowrap gap-1">
                {Array.isArray(serviceCategories) && serviceCategories.map(category => {
                  return (
                    <div key={category} className="tag text-2xs"><span>{t(`coupon.enums.serviceCategories.${category}`)}</span></div>
                  )
                })}
              </div>
            </Col>
            <Col span={7}>
              <Typography className="coupon__price flex-2 text-right font-bold">
                {discountValueFormatter(coupon)}
              </Typography>
            </Col>
          </Row>
          <Row className="coupon-name">
            <Col span={17}>
              <Paragraph className="coupon__name flex-2" ellipsis={ellipsis ? { rows: 3, expandable: true, symbol: t('common.expandEllips') } : false}>
                {get(coupon, 'name')}
              </Paragraph>
            </ Col>
            <Col span={7}>
              { get(coupon, 'treatmentDuration') &&
                <Typography className="flex-1 text-right font-bold">{convertMinsToHour(coupon.treatmentDuration)}</Typography>
              }
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="flex flex-row py-2 px-4">
        <div className="coupon__image flex-none rounded" style={{ backgroundImage: `url("${get(coupon, 'image')}")` }} />
        <div className="pl-2 space-y-2">
          <div className="flex flex-row">
            <Typography className="font-bold w-full flex-none">{get(coupon, 'description')}</Typography>
          </div>
          <div className="flex flex-row">
            <Typography className="font-bold flex-none">{t('coupon.termOfUse')}</Typography>
            <Typography className="pl-2">{get(coupon, 'termOfUse')}</Typography>
          </div>
          <div className="flex flex-row">
            <Typography className="font-bold flex-none">{t('coupon.secondTermOfUse')}</Typography>
            <Typography className="pl-2">{get(coupon, 'secondaryTermOfUse')}</Typography>
          </div>
        </div>
      </div>
      { showButtons &&
        <Row className="flex flex-row py-4 px-4" gutter={[8, 2]}>
          <Col span={12}/>
          <Col span={12}>
            <Button className="btn-default btn btn-small my-4 text-sm" onClick={onBooking}>
              {t('coupon.bookingBtn')}
            </Button>
          </Col>
        </Row>
      }
    </Card>
  );
}
