import React from "react";
import { Typography, Card, notification, Button } from "antd";
import { t } from '../../helpers/translation';
import './styles.scss'

const LineLinking = ({ csrf_token, from_account_page }) => {
  const handleCancel = () => {
    if(from_account_page)
      window.location.href = '/users/profiles/edit?from_account_page=true';
    else
      window.location.href = '/users/line_linking/cancel';
  }

  return (
    <Card className="line-linking">
      <div className="grid content-center h-full gap-y-3">
        <Typography.Title level={3} className="text-center">
          {window.i18n.t("new_user.line_linking")}
        </Typography.Title>
        <div>
          <form action="/users/auth/line" method="post" className="login-with-line mb-4">
            <input type="hidden" name="authenticity_token" value={csrf_token} />
            <button type="submit">{t("new_user.line_linking")}</button>
          </form>
          <Button onClick={handleCancel} className="btn-gray btn">
            {t("new_user.cancel")}
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default LineLinking;
