import React from "react"
import { Card, Typography, Button } from 'antd';
import { get } from 'lodash'
import { t } from '../../../helpers/translation'

const { Paragraph } = Typography;
const TOTAL_STEP = 4;

export const NavStep = ({ step, title }) => {
  const percent = (step * 100 / TOTAL_STEP);

  return (
    <div className="relative py-10 px-4">
      <div className="flex justify-between">
        <h3 className="text-xl self-end font-bold leading-6 text-gray-900 mb-4">{title}</h3>
        <div className="div">
          <span className="text-xl font-bold text-primary">{step}</span>
          <span className="font-bold text-gray-400">{`/ ${TOTAL_STEP}`}</span>
        </div>
      </div>
      <div className="overflow-hidden h-2 text-xs flex rounded bg-primary-100">
        <div className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-primary" style={{ width: `${percent}%` }}>
        </div>
      </div>
    </div>
  );
}
