export default {
  card: {
    background: '#FFFFFF',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.12)',
    borderRadius: 8,
    marginBottom: 12,
    marginTop: 2,
    marginLeft: 2,
    overflow: 'hidden',
  },
  content: {
    padding: '8px 12px 16px 12px',
  },
  contentFixed: {
    height: '120px',
  },
  brand: {
    fontSize: 10,
    color: '#03212F',
    opacity: 0.6,
  },
  name: {
    fontSize: 14,
    color: '#03212F'
  },
  price: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#03212F'
  },
  extraPrice: {
    fontSize: 10,
    color: '#03212F',
    alignSelf: 'center'
  },
  image: {
    width: 178,
    height: 178,
    objectFit: 'cover',
  },
  wrapper: {
    width: '100%',
  }
}