import React from "react"
import { Row, Typography, Button } from "antd"
import styles from './styles'
import { t } from "../../helpers/translation"

const OrderCancelled = () => {
  return (
    <div style={styles.container}>
      <Typography style={styles.orderCancellationText}>
        {t('orders.order_cancelled')}
      </Typography>
      <Row>
        <Button href="/ec_users" style={styles.continuePurchaseBtn}>
          <Typography style={styles.continuePurchaseText}>
            {t('carts.continue_purchase')}
          </Typography>
        </Button>
      </Row>
    </div>
  )
}

export default OrderCancelled
