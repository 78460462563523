import axios from "axios"

import api from 'dashboards/global/middleware.js'

const makeRequest = axios

export const updateUser = async (data) =>
  makeRequest({
    method: "put",
    url: `/api/users`,
    data: data
  })

export const updateUserPass = async (data) =>
  makeRequest({
    method: "put",
    url: `/api/users/update_password`,
    data: data
  })

export const updateUserPhone = async (data) =>
  makeRequest({
    method: "put",
    url: `/api/users/update_phone`,
    data: data
  })

export const cancelUser = async (data) =>
  makeRequest({
    method: "delete",
    url: `/api/users/cancel`,
    data: data
  })

export const deleteCard = async (data) =>
  makeRequest({
    method: "post",
    url: `/api/stripe/intents/delete`,
    data: data
  })

export const sendInquiry = async (data) =>
  makeRequest({
    method: "post",
    url: `/ec_users/inquiries`,
    data: data
  })

export const resendOtpCode = async (current_user) => {
  return api.post(`/api/sms_authentications/resend_otp_code`, current_user)
}

export const authenticateOtp = async data => {
  return api.post(`/users/sms_authentications/authenticate_otp`, data)
}

export const signup = async data => {
  return api.post(`/users/create_v2`, data)
}

export const registerTempUser = async data => {
  return api.post(`/users/register_temp_user`, data)
}

export const login = async data => {
  return api.post(`/users/sign_in_v2`, data)
}

export const checkPostCode = async (params) => {
  return api.get('/api/zip_code/verify', { params })
}
