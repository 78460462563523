import variables from '../../../styles/ec_users/_variables.scss';

export default {
  container: {
    paddingBottom: '30px',
    backgroundColor: variables.grayf6,
    cursor: 'pointer'
  },
  imageWrapper: {
    position: 'relative',
  },
  imageStyle: {
    width: '-webkit-fill-available',
    height: 177,
    objectFit: 'cover'
  }
};
