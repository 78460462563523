import React, { } from 'react';
import { Banner as Carousel } from '../../components/Home/Banner'
import { PickupProducts } from '../../components/Home/PickupProducts'
import { CategoryRankingProducts } from '../../components/Home/CategoryRankingProducts'
import { t } from '../../helpers/translation';
import PWAInstallDialog from "./PWAInstallDialog";
import './styles.scss'
import HomeProductSearch from '../HomeProductSearch';

const Home = ({isNewRegister, banners, pickup_products, popular_products, shampoo_products, treatment_products, outbath_products, styling_products }) => {
  return (
    <>
      { isNewRegister &&
        <PWAInstallDialog/>
      }
      <Carousel images={banners} />
      <HomeProductSearch />
      { popular_products.length > 0 &&
        <CategoryRankingProducts products={popular_products} title={t('products.popular_products')} subTitle={t('products.popular_products_jp')} applyWhiteBG={true}/>
      }
      { pickup_products.length > 0 &&
        <PickupProducts products={pickup_products || []} />
      }
      { shampoo_products.length > 0 &&
        <CategoryRankingProducts products={shampoo_products} title={t('products.shampoo_products')}/>
      }
      { treatment_products.length > 0 &&
        <CategoryRankingProducts products={treatment_products} title={t('products.treatment_products')} applyWhiteBG={true}/>
      }
      { outbath_products.length > 0 &&
        <CategoryRankingProducts products={outbath_products} title={t('products.outbath_products')}/>
      }
      { styling_products.length > 0 &&
        <CategoryRankingProducts products={styling_products} title={t('products.styling_products')} applyWhiteBG={true}/>
      }
    </>
  )
}

export default Home
