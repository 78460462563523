import React, { useState } from 'react'
import { Row, Col, Typography, Button, notification } from 'antd';
import { get } from 'lodash';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { t } from '../../../helpers/translation';
import { currency } from '../../../utils/number';
import { removeCartItem, addCartItem, subCartItem, setCoupon, setUsePoint } from '../../../helpers/carts';
import styles from './styles';
import { checkInventory } from '../../../services/orders'

const ButtonGroup = Button.Group

export const CartItemList = ({ item = {}, onAdd, onSub, onRemove, canEditQuantity = true, errorMessage = null }) => {
  const [disableAddButton, setDisableAddButton] = useState(false)

  const handleRemove = () => {
    removeCartItem(item)
    onRemove(item)
    setCoupon(null);
    setUsePoint(false);
  } 

  const handleAdd = async () => {
    setDisableAddButton(true)
    if(item.quantity < item.limitQtyPerOrder){
      try {
        let res = await checkInventory({ product: {
            id : item.id,
            quantity: item.quantity + 1
          }
        });
        if(res.status == 200){  
          addCartItem(item)
          onAdd(item)
        }
      } catch (err) {
        notification.error({
          message: err.response.data.message
        });
      }
    }
    setDisableAddButton(false)
  }

  const handleSub = () => {
    subCartItem(item)
    onSub(item)
  }

  return (
    <Row>
      <Row style={styles.contentWrapper}>
        <Col span={5}>
          <img src={get(item, 'image')} style={styles.image}/>
        </Col>
        <Col style={styles.info} span={18}>
          <Row>
            <Typography style={styles.brandText}>
              {get(item, 'productBrand.name')}
            </Typography>
          </Row>
          <Row>
            <Typography style={styles.prdNameText}>
              {get(item, 'name')}
              {get(item, 'product_categories[0]') && (get(item, 'product_categories[0]'))}
            </Typography>
          </Row>
          <Row>
            <Typography style={styles.priceText}>
              {currency(item.priceWithTax)}
            </Typography>
            <Typography style={styles.priceNote}>
              { t('common.taxInclude') }
            </Typography>
          </Row>
        </Col>
      </Row>
      <Row justify="space-between" style={styles.addItem}>
        <Col>
          <ButtonGroup>
            <Button
              style={styles.iconBtn}
              className="quantity-selector-action"
              onClick={handleSub}
              disabled={!canEditQuantity}
            >
              <MinusOutlined style={styles.icon} />
            </Button>
            <Button style={styles.iconBtn} className="quantity-selector-number">
              {item.quantity}
            </Button>
            <Button 
              style={styles.iconBtn}
              className="quantity-selector-action"
              onClick={handleAdd}  
              disabled={disableAddButton || !canEditQuantity}
            >
              <PlusOutlined style={styles.icon} />
            </Button>
          </ButtonGroup>
        </Col>
        {canEditQuantity && (
          <Col>
            <Button style={styles.removeBtn} onClick={handleRemove}>
              <Typography style={styles.removeText}>
                {t('carts.removeItem')}
              </Typography>
            </Button>
          </Col>
        )}
        {errorMessage && 
          <Typography style={styles.errorMessage}>
            {errorMessage}
          </Typography>
        }
      </Row>
    </Row>
  )
}
