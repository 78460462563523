import React from 'react'
import { Modal, Button, Typography, Space } from 'antd'
import { t } from '../../helpers/translation'
import styles from './styles'
import { FacebookIcon } from 'react-share'

export const ErrorDialogMilbon = ({ title, visible, onCancel}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      style={{ maxWidth: 400 }}
      footer={null}
      closable={false}
      maskClosable={false}
    >
     
      <Button style={styles.btnPrimary} onClick={onCancel}>
        <Typography style={styles.btnPrimaryText}>{t('carts.confirmation')}</Typography>
      </Button>
     
    </Modal>
  )
}
