import React, { useEffect, useState } from "react"
import { Typography, Row, Col, Space, Card, Divider, Tag, Avatar, Button } from "antd"
import UpcomingReservation from '../UpcomingReservation'
import styles from './styles'
import { t } from '../../helpers/translation'
import { upcomingReservations } from '../../services/upcomingReservations'
import { ShippingAddressDialog } from '../../components/ShippingAddressDialog'

const { Title, Text } = Typography;

const Info = ({ name, balance, currentUser }) => {
  const [reservationsStore, setReservationsStore] = useState([])

  const handleSetReservationStore = async () => {
    const res = await upcomingReservations(currentUser)
    if (res && res.data && res.data.success) {
      setReservationsStore(res['data']['reservationsStore'])
    }
  }

  useEffect(() => {
    handleSetReservationStore()
  }, [])

  return (
    <>
      <div style={styles.infoWrapper} className="mt-8">
        <Card style={styles.infoCard} bodyStyle={styles.bodyStyle}>
          <Row align="middle">
            <Col span={12}>
              <div style={styles.name}>
                {name}
                <span style={styles.mr}>{t("account_setting.mr")}</span>
              </div>
              <div style={styles.hello}>{t("account_setting.hello")}</div>
            </Col>
            <Col span={12} align="end">
              <div style={styles.pointWrapper}>
                <span style={styles.textColor}>
                  {t("account_setting.owned_points")}
                </span>
                <span style={styles.pointStyle}>
                  {balance}
                  <span style={styles.pointStyleSymbol}>pt</span>
                </span>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
      <UpcomingReservation reservationsStore={reservationsStore}  />
    </>
  );
};

const Others = ({ userId, currentUser, prefectures }) => {
  const [showModal, setShowModal] = useState(false)
  const [user, setUser] = useState(currentUser)

  const handlePhoneUpdate = () => {
    window.location.href = `accounts/${userId}/edit?tab=1`
  }
  const handlePasswordUpdate = () => {
    window.location.href = `accounts/${userId}/edit?tab=3`
  }
  const handlePaymentUpdate = () => {
    window.location.href = `accounts/${userId}/edit?tab=2`
  }
  const handleProfileUpdate= () => {
    window.location.href = `/users/profiles/edit?from_account_page=true`
  }

  const handleAddressUpdate = () => {
    setShowModal(true)
  }

  return (
    <Card title={t("account_setting.profile_info")} style={styles.cardOther}>
      <Button type="link" href="#" style={styles.link} onClick={handleProfileUpdate}>{t('account_setting.update_profile')}</Button>
      <Divider style={styles.divider} />
      <Button type="link" href="#" style={styles.link} onClick={handlePaymentUpdate}>{t('account_setting.change_cards')}</Button>
      <Divider style={styles.divider} />
      <Button type="link" href="#" style={styles.link} onClick={handlePhoneUpdate}>{t('account_setting.change_phone')}</Button>
      <Divider style={styles.divider} />
      <Button type="link" href="#" style={styles.link} onClick={handlePasswordUpdate}>{t('account_setting.change_password')}</Button>
      <Divider style={styles.divider} />
      <Button type="link" style={styles.link} onClick={handleAddressUpdate}>{t('account_setting.change_address')}</Button>
      <Divider style={styles.divider} />

      <ShippingAddressDialog
        title={t('account_setting.change_address')}
        onUpdateUserInfo={setUser}
        user={user}
        prefectures={prefectures}
        isConfirmation={false}
        showModal={showModal}
        onHideModal={setShowModal}
      />
    </Card>
  );
}

const Account = ({ balance, user_id, name, reservation, currentUser, prefectures }) => {
  return (
    <>
      <Info name={name} balance={Number(balance)} currentUser={currentUser} />
      {/* <Reservation {...reservation} /> */}
      <Others userId={user_id} currentUser={currentUser} prefectures={prefectures} />
      <br />
    </>
  );
};

export default Account;
