import variables from '../styles/ec_users/_variables.scss';

export const yearsList = () => {
  const currentYear = new Date().getFullYear();
  const startingYear = (currentYear - 100)
  const length = (currentYear - startingYear) + 1

  return Array.from({length: length}, (v, i) => currentYear - i);
}

export const monthsList = () => {
  const startingMonth = 1

  return Array.from({length: 12}, (v, i) => startingMonth + i);
}

export const daysList = (m = new Date().getMonth() + 1, y = new Date().getFullYear()) => {
  const d = new Date(y, m, 0).getDate();
  const startingDay = 1

  return Array.from({length: d}, (_, i) => startingDay + i);
}

export const getCatergoryColor = (value) => {
  switch (value) {
    case 'color':
      return variables.yellow
    case 'hair_straightening':
      return variables.green
    case 'cut':
      return variables.redBold
    case 'perm':
      return variables.pink
    case 'hair_set':
      return variables.blue
    case 'head_spa':
      return variables.purple
    case 'treatment':
      return variables.orange
    default:
      break;
  }
}

