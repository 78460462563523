import React from "react"
import SnsUrls from '../../SnsUrls'
import StoreSvg from '-!react-svg-loader!/public/images/store.svg';
import { Row, Col, Card, Typography, Space, Button } from 'antd'
import { get } from 'lodash'
import { t } from '../../../helpers/translation'
import './styles.scss'
import { currency } from '../../../utils/number'
import { addStylist, clearBooking } from '../../../helpers/bookings'

const { Text } = Typography;

export const StaffInfo = ({ staff }) => {

  return (
    <div className="staff-info">
      <Row>
        <Col>
          <img src={get(staff, 'avatar')} className="staff-info__image" />
        </Col>
      </Row>
      <Row className="flex flex-row justify-center staff-info__card-wrapper">
        <Col span={22}>
          <Card className="staff-info__card">
            <Space direction="vertical">
              <Text className="staff-info__store-name">{get(staff, 'store.name')}</Text>
              <Text className="staff-info__staff-name">{get(staff, 'displayName')}</Text>
              {get(staff, 'youtubeUrl') || get(staff, 'instagramUrl') || get(staff, 'tiktokUrl') || get(staff, 'twitterUrl') &&
                <div className="my-4">
                  <SnsUrls
                    mode="svg__black"
                    youtubeUrl={get(staff, 'youtubeUrl')}
                    instagramUrl={get(staff, 'instagramUrl')}
                    tiktokUrl={get(staff, 'tiktokUrl')}
                    twitterUrl={get(staff, 'twitterUrl')}
                  />
                </div>
              }
              { (get(staff, 'nominationFeeValue') && get(staff, 'nominated?')) &&
                <Text className="staff-info__nomination">
                  <span className="staff-info__nomination-title">{t('staff.nominationFee')}</span>
                  <span className="staff-info__nomination-fee">{currency(get(staff, 'nominationFeeValue'))}</span>
                </Text>
              }
            </Space>
          </Card>
        </Col>

        {!get(staff, 'freelancer?') &&
          <Col span={22} align="center" className="mt-6">
            <a href={`/reservation_users/stores/${get(staff, 'storeId')}`}>
              <StoreSvg className={'svg__primary'} />
              <Typography className="font-bold">{t('staff.storeDetail')}</Typography>
            </a>
          </Col>
        }

      </Row>
    </div>
  )
}

export default StaffInfo
