export default {
  container: {
    marginRight: -20,
    marginLeft: -20,
    padding: '0 20px',
  },
  title: {
    fontSize: 12,
    fontWeight: '400',
    color: '#03212F',
    lineHeight: '18px'
  },
  value: {
    fontSize: 12,
    fontWeight: '700',
    color: '#03212F',
    lineHeight: '18px'
  },
  box: {
    width: '100%',
    border: '1px solid #E3E3E3',
    background: 'white',
    borderRadius: 4,
    padding: '12px 16px',
    position:'relative',
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    marginBottom: 24,
    cursor: 'pointer',
  },
  parentDiv: {
    display: 'inline-block',
    width: '100%'
  },
  floatLeft: {
    float: 'left',
    maxWidth: '50%'
  },
  floatRight: {
    float: 'right',
    maxWidth: '50%',
    textAlign: 'right'
  },
  shippingText: {
    whiteSpace: 'nowrap'
  },
  shippingAddress: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#03212F',
  }
}
