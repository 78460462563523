import React from "react"
import { Row, Typography, Button } from "antd"
import { OrderConfirmationInfo } from '../../components/Orders/OrderConfirmationInfo'
import { OrderConfirmationAddress } from '../../components/Orders/OrderConfirmationAddress'
import styles from './styles'
import { t } from "../../helpers/translation"

const OrderConfirmed = ({ order = {}, shipping_address, order_lines = [], user = {}, item_count, shipping_fee }) => {
  return (
    <div style={styles.container}>
      <Typography>
        {t('orders.order_confirmation_thanks')}
      </Typography>
      <Typography>
        {t('orders.order_confirmation_note')}
      </Typography>
      <OrderConfirmationInfo item_count={item_count} total_fee={order?.total_amount} order={order} orderLines={order_lines} shipping_fee={shipping_fee} />
      <OrderConfirmationAddress shipping_address={shipping_address} user={user} />
      <Row justify="center">
        <Button style={styles.continueShippingBtn} href='/ec_users'>
          <div className="text-white font-thin text-xs">
            {t('orders.continue_shopping')}
          </div>
        </Button>
      </Row>
    </div>
  )
}

export default OrderConfirmed
