import api from 'dashboards/global/middleware.js'

export const confirmReservation = async (data) => {
  return api.post('/api/reservations', data)
}

export const cancelReservation = async (bookingId) => {
  return api.post(`/api/reservations/${bookingId}/cancellations`)
}

export const changeReservation = async (bookingId, data) => {
  return api.put(`/api/reservations/${bookingId}`, data)
}
