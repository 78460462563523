import { Row, Col, Typography } from 'antd'
import React from "react"
import { ProductList } from '../ProductList'
import { t } from '../../../helpers/translation'
import styles from './styles'

export const PickupProducts = ({ products = [], applyWhiteBG=false }) => {
  return (
    <Row style={styles.row} className={`px-2.5 ${applyWhiteBG && 'bg-white'}`}>
      <Col style={styles.fullWidth}>
        <span style={styles.text}>
          {t('products.pickup_products')}
        </span>
      </Col>
      <Col style={styles.subTitle}>
        <span style={styles.text}>
          {t('products.pickup_products_jp')}
        </span>
      </Col>
      <ProductList products={products} />
    </Row>
  );
}
