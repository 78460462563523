import React, { useState, useEffect, useRef, useMemo } from 'react'
import {loadStripe} from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js'
import { Button, Radio, Row, Col, Typography, notification } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { getPaymentMethods, createCustomerInfo } from '../../../services/orders'
import { get } from 'lodash'
import styles from './styles'
import { t } from '../../../helpers/translation'
import { errorMaps, fundingNotSupported } from '../../../utils/stripe'
import { deleteCard } from '../../../services/users'

const MAX_CARD = 5

export const CheckoutForm = ({ secret, setMethods, setShowElement, setSecret }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (elements == null) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    stripe.confirmCardSetup(secret, {
      payment_method: {
        card: cardElement,
      }
    }).then(async function (result) {
      let errorMessage = errorMaps[get(result, 'error.code')]
      if(get(result, 'error.decline_code')) {
        errorMessage = errorMaps[get(result, 'error.decline_code')]
      }
      if (errorMessage) {
        notification.error({
          message: errorMessage,
        });
        return
      }
      if(get(result, 'error')) {
        notification.error({
          message: get(result, 'error.message'),
        });
        return
      }
      const res = await getPaymentMethods()

      const methods = get(res, 'data.payment_method')

      const method = get(res, 'data.lastest_card')
      if(method && fundingNotSupported.includes(method.card.funding)) {
        const result = await createCustomerInfo()
        if (result && result.data) {
          setSecret(result.data.intent_secret)
        }
        notification.error({
          message: t('stripe.funding_not_supported'),
        });
        return
      }
      if (methods) {
        setMethods(methods)
      }
      setShowElement(false)
     });
  };

  return (
    <form>
      <CardElement options={{
        style: {
          base: {
            fontSize: '16px',
            color: '#424770',
            '::placeholder': {
              color: '#aab7c4',
            },
          },
          invalid: {
            color: '#9e2146',
          },
        },
        hidePostalCode: true,
      }}/>
      <Button type="primary" disabled={!stripe || !elements} onClick={handleSubmit} style={styles.button} className="primary-btn-bg">
        {t('common.submit')}
      </Button>
    </form>
  );
};

const PaymentUpdate = ({ pb_key }) => {
  const [secret, setSecret] = useState('')
  const [methods, setMethods] = useState([])
  const [showElement, setShowElement] = useState(false)

  const stripePromise = useMemo(() => loadStripe(pb_key), [pb_key])

  useEffect(() => {
    stripePromise.current = loadStripe(pb_key, {locale: 'ja'});
  }, [pb_key])

  useEffect(() => {
    const getMethods = async () => {
      const res = await getPaymentMethods()

      const methods = get(res, 'data.payment_method')
      if (!methods || !methods.length) {
        const result = await createCustomerInfo()
        if (result && result.data) {
          setSecret(result.data.intent_secret)
        }
      } else {
        setMethods(methods)
      }
    }
    getMethods()
  }, [])

  const handleShowElement = async () => {
    const result = await createCustomerInfo()
    if (result && result.data) {
      setSecret(result.data.intent_secret)
      setShowElement(true)
    }
  }

  const handleRemoveCard = async (card) => {
    const res = await deleteCard(card)
    if (res && res.data && res.data.success) {
      window.location.reload()
    }
  }

  if (methods && methods.length) {
    return (
      <div style={styles.container}>
        {methods.map((me, idx) => {
          const cardBrand = me?.card?.brand.charAt(0).toUpperCase() + me?.card?.brand.slice(1)
          return (
            <Row key={idx} justify="space-between" align="middle" style={styles.payRow}>
              <Col>
                <Row>
                  <Typography style={styles.addNew}>
                    {`${cardBrand} **** **** ****${me?.card?.last4}`}
                  </Typography>
                </Row>
                <Row>
                  <Typography style={styles.addNew}>
                    {`有効期限 ${me?.card?.exp_year}/${me?.card?.exp_month}`}
                  </Typography>
                </Row>
              </Col>
              <Col>
                <CloseOutlined onClick={() => handleRemoveCard(me)}/>
              </Col>
            </Row>
          )
        })}
        { methods.length < MAX_CARD &&
          <Row justify="start">
            <Col>
              <Radio onChange={handleShowElement} checked={showElement} />
            </Col>
            <Col>
              <Typography style={styles.addCard}>
                {t('orders.create_new_payment')}
              </Typography>
            </Col>
          </Row>
        }
        {showElement && methods.length < MAX_CARD && (
          <>
            <Row justify="start">
              <Col>
                <Typography style={styles.addCard}>
                  {t('orders.card_information')}
                </Typography>
              </Col>
            </Row>
            <Elements stripe={stripePromise} options={{ locale: 'ja' }}>
              <CheckoutForm secret={secret} setMethods={setMethods} setShowElement={setShowElement} setSecret={setSecret}/>
            </Elements>
          </>
        )}
      </div>
    )
  }

  return (
    <div style={styles.container}>
      <Elements stripe={stripePromise} options={{ locale: 'ja' }}>
        <CheckoutForm secret={secret} setMethods={setMethods} setShowElement={setShowElement} setSecret={setSecret} />
      </Elements>
    </div>
  )
}

export { PaymentUpdate }
