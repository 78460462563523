import React from 'react'
import { NavStep } from '../../reservation_components/BookingInfo/NavStep';
import { CancelSuccess } from '../../reservation_components/BookingInfo/CancelSuccess';
import { StoreInfo } from '../../reservation_components/BookingInfo/StoreInfo';
import { Button } from 'antd';
import { t } from '../../helpers/translation';

const BookingCancelled = ({ store, current_user, reservation}) => {
  return (
    <React.Fragment>
      <NavStep step={4} title={t('booking.cancelledHeading')}/>
      <CancelSuccess  />
      <StoreInfo store={store}/>
    </React.Fragment>
  )
}

export default BookingCancelled
