import React from 'react'
import { NavStep } from '../../reservation_components/BookingInfo/NavStep';
import { Success } from '../../reservation_components/BookingInfo/Success';
import { StoreInfo } from '../../reservation_components/BookingInfo/StoreInfo';
import { Reservation } from '../../reservation_components/BookingInfo/Reservation';
import { PaymentAmount } from '../../reservation_components/BookingInfo/PaymentAmount';
import { t } from '../../helpers/translation';

const BookingCompleted = ({coupons, store, current_user, reservation, coupon, can_cancel, is_cancelled}) => {
  const totalTaxFee = (reservation?.menus?.reduce((acc, item) => { return (acc + item.calculatedTaxFee)}, 0) || 0);

  return (
    <div>
      <NavStep step={4} title={t('booking.completedHeading')}/>
      <Success
        reservation={reservation}
        store={store}
        user={current_user}
        canCancel={can_cancel}
        isCancelled={is_cancelled}
      />
      <StoreInfo store={store}/>
      <Reservation
        coupons={coupons}
        reservation={reservation}
        menus={reservation.menus}
        store={store}
        stylist={reservation.staff}
        totalTreatmentTime={reservation.timeRequired}
        coupon={coupon}
      />
      <PaymentAmount
        reservation={reservation}
      />
    </div>
  )
}

export default BookingCompleted
