import React from 'react'
import { Card, Typography, Row, Col } from 'antd';
import { get } from 'lodash'
import { t } from '../../helpers/translation'
import reservationSvg from 'images/reservation.svg' 
import './styles.scss'

export const NewStoreCard = ({ store }) => {
  const onGoStoreDetail = () => {
    window.location.pathname = `/reservation_users/stores/${store.id}`
  }

  return (
    <Card className='store__card my-4' bordered={false}>
      <div className='flex flex-row py-4 px-4'>
        <img className='store__image flex-none rounded-sm' src={get(store, 'image')} />
        <div className='px-4 w-full'>
          <Typography className='font-thin text-base'>{get(store, 'name')}</Typography>
          <Row className='mt-2' gutter={2}>
            <Col span={2} className='gutter-row'>
              <img src={reservationSvg} className='store__reservation-image'/>
            </Col>
            <Col span={22} className='gutter-row'>
              <Typography className='text-xs'>{get(store, 'nearestStation')}</Typography>
            </Col>
          </Row>
          <button className='store__details-btn mt-2 w-full rounded py-1.5' onClick={onGoStoreDetail}>
            {t('store.goDetailBtn')}
          </button>
        </div>
      </div>
    </Card>
  );
}
