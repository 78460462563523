import React, { useState, useEffect } from 'react'
import { Row, Typography, Button, Input } from 'antd'
import { validateEcCouponCode } from '../../../services/carts'
import { t } from '../../../helpers/translation'
import { getCoupon, MININUM_AMOUNT } from '../../../helpers/carts'
import styles from './styles'

export const Coupon = ({ setForceRemovePoint, onSetDiscount, totalPrice }) => {
  const [coupon, setCoupon] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [success, setSuccess] = useState(null)

  const [savedCoupon, setSavedCoupon] = useState(getCoupon())

  useEffect(() => {
    if (!!savedCoupon) {
      setCoupon(savedCoupon.coupon)
    }
  }, [])

  useEffect(() => {
    if (totalPrice <= MININUM_AMOUNT) {
      handleDelete();
      setErrorMessage(t('carts.couponMinAmountErrorMessage'));
      setSuccess(false)
    } else if (success === false) {
      setErrorMessage('');
      setSuccess(null)
    }
  }, [totalPrice])

  useEffect( async() => {
    if (!!savedCoupon) {
      const result = await validateEcCouponCode({
        ec_coupon: {
          coupon_code: savedCoupon.coupon,
          total_price: totalPrice
        }
      })
  
      if (result && result.data && result.data.success) {
        onSetDiscount({ discount: result.data.discount, coupon: result.data.coupon.code })
      } else {
        setErrorMessage(result.data?.message)
        onSetDiscount({ discount: 0, coupon: null })
      }
    }
  }, [totalPrice])

  const handleChange = (e) => {
    setCoupon(e.target.value)

    if (e.target.value == '') setSuccess(null)
  }
  
  const handleValidate = async () => {
    const result = await validateEcCouponCode({
      ec_coupon: {
        coupon_code: coupon,
        total_price: totalPrice
      }
    })

    if (result && result.data && result.data.success) {
      setSuccess(true)
      onSetDiscount({ discount: result.data.discount, coupon })
      setSavedCoupon(getCoupon())
    } else {
      setErrorMessage(result.data?.message)
      setSuccess(false)
      onSetDiscount({ discount: 0, coupon: null })
    }
  }

  const handleDelete = () => {
    onSetDiscount({ discount: 0, coupon: null })
    setCoupon(null);
    setSavedCoupon(getCoupon())
    setSuccess(null)
  }

  return (
    <div style={styles.container}>
      <Row>
        <Typography style={styles.title}>
          {t('carts.cart_coupon')}
        </Typography>
      </Row>
      <Row>
        <Input
          value={coupon}
          onChange={handleChange}
          style={styles.input}
          placeholder={t('carts.cart_coupon_desc')} 
          suffix={<Button style={styles.btn} onClick={savedCoupon?.coupon ? handleDelete : handleValidate}><Typography style={styles.applyText}>{savedCoupon?.coupon ? t('carts.cart_coupon_delete') : t('carts.cart_coupon_apply')}</Typography></Button>}
        />
        { success &&
          <Typography style={styles.successText}>
            {t('carts.valid_coupon')}
          </Typography>
        }
        { (success === false) && 
          <p style={styles.errorText} className="text-red-500">
            {errorMessage || t('carts.invalid_coupon')}
          </p>
        }
      </Row>
    </div>
  )
}
