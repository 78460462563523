import React from "react"
import { InboxOutlined } from '@ant-design/icons';
import './styles.scss'

export const NoData = () => {
    return(
        <div className='no-data-box'>
            <InboxOutlined className='inbox-outline' />
            <p className='text-style'>
                予約可能する店舗がありません。
            </p>
        </div>
    )
}
