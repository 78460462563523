import React from "react"
import { Row, Col, Typography } from "antd"
import Icon from '@ant-design/icons';
import styles from './styles.js'

import FacebookSvg from '-!react-svg-loader!/public/images/fb.svg';
import TwitterSvg from '-!react-svg-loader!/public/images/twitter.svg';


import {
  TwitterShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon
} from "react-share";

const MenuShare = ({ mode = 'svg__primary' }) => {
  const url = window.location.href
  return (
    <div style={styles.container} className="flex">
      <Row justify="center" align="center" gutter={12} style={styles.row} className="items-center">
        <Col style={styles.iconCenter}>
          <TwitterShareButton url={url}>
            <TwitterSvg className={mode} />
          </TwitterShareButton>
        </Col>
        <Col offset={2} style={styles.iconCenter}>
          <FacebookShareButton url={url}>
            <FacebookSvg className={mode} />
          </FacebookShareButton>
        </Col>
      </Row>
    </div>
  )
}

export default MenuShare