import React, { useEffect, useState } from 'react'
import { Row, Col, Tabs, Typography, Form, notification } from 'antd'
import { get } from 'lodash'
import { t } from '../../helpers/translation'
import styles from './styles'
import { PhoneUpdate } from '../../components/Accounts/PhoneUpdate'
import { PaymentUpdate } from '../../components/Accounts/PaymentUpdate'
import { PasswordUpdate } from '../../components/Accounts/PasswordUpdate'
import { updateUserPass, updateUserPhone } from '../../services/users'
import { CloseOutlined } from '@ant-design/icons'

const { TabPane } = Tabs;

const AccountUpdate = ({ user, pb_key }) => {
  const [defaultTab, setDefaultTab] = useState('1')
  const [initTab, setInitTab] = useState('1')
  const showChangePhoneTab = ['1', '2']
  const showChangePassTab = ['3']

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.has('tab')) {
      setDefaultTab(String(searchParams.get('tab')))
      setInitTab(String(searchParams.get('tab')))
    }
  }, [])

  const callback = key => {
    setDefaultTab(String(key));
  }

  const onFinish = async (values) => {
    let res
    try {
      res = await updateUserPhone({ user: values , id: user.id })
      if (res && res.data && res.data.success) {
        notification.success({
          message: t('account_setting.update_success')
        })
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || t('account_setting.update_failed')
      })
    }
  }

  const onUpdatePass = async (values) => {
    let res
    try {
      res = await updateUserPass({ user: values , id: user.id })
      if (res && res.data && res.data.success) {
        notification.success({
          message: t('account_setting.update_success')
        })
      }
    } catch (err) {
      notification.error({
        message: err?.response?.data?.message || t('account_setting.update_failed')
      })
    }
  }
  const handleBackButton = () => {
    window.location.href = '/ec_users/accounts'
  }

  return (
    <div>
      <Row justify="space-between" align="middle" className='mx-4'>
        <Col>
          <Row>
            <Typography>{t('account_setting.account_update')}</Typography>
          </Row>
        </Col>
        <Col>
          <CloseOutlined onClick={() => handleBackButton()}/>
        </Col>
      </Row>
      <Tabs activeKey={defaultTab} onChange={callback} type="card" style={styles.tabs}>
        {(showChangePhoneTab.includes(initTab) && 
          <TabPane tab={t('account_setting.change_phone')} key="1">
            <Form
                layout="vertical"
                name="update"
                onFinish={onFinish}
                initialValues={{ phone_number_mobile: get(user , 'phone_number_mobile') }}
                scrollToFirstError
              >
              <PhoneUpdate />
            </Form>
          </TabPane>
        )}
        {(showChangePassTab.includes(initTab) && 
          <TabPane tab={t('account_setting.change_password')} key="3">
            <Form
                layout="vertical"
                name="update"
                onFinish={onUpdatePass}
                scrollToFirstError
              >
              <PasswordUpdate />
            </Form>
          </TabPane>
        )}
        <TabPane tab={t('account_setting.change_cards')} key="2">
          <PaymentUpdate pb_key={pb_key} />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default AccountUpdate
