export default {
  container: {
    padding: '32px 20px 0 20px',
  },
  dataInput: {
    width: 175,
    height: 45,
  },
  inputHeight: {
    height: 45,
  },
  button: {
    width: '100%',
    height: 48,
    backgroundColor: '#E96E75'
  },
  inquiryMessage: {
    color: '#777777'
  }
}
