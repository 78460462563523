import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Button } from "antd";

const SliderImage = ({ images }) => {
  const [nav1, setNav1] = useState(null)
  const [nav2, setNav2] = useState(null)

  const slider1 = useRef(null)
  const slider2 = useRef(null)

  useEffect(() => {
    setNav1(slider1.current)
    setNav2(slider2.current)
  }, [])

  const nextArr = 
    (
      <img src="../../images/next_arrow.svg" />
    )

  const prevArr = 
    (
      <img src="../../images/prev_arrow.svg" />
    )

  return (
    <div className="product-image-slider store-image-slider mt-6">
      <Slider
        asNavFor={nav2}
        ref={slider => (slider1.current = slider)}
        infinite={false}
        style={{ background: 'white' }}
      >
        {images.map((item, idx) => {
          return (
            <div key={idx}>
              <img src={item.thumbnail} />
            </div>
          )
        })}
      </Slider>
      <div style={{ marginTop: 15 }}>
        <Slider
          asNavFor={nav1}
          ref={slider => (slider2.current = slider)}
          slidesToShow={4}
          swipeToSlide={true}
          focusOnSelect={true}
          infinite={false}
          className="product-image-slider-2"
          nextArrow={nextArr}
          prevArrow={prevArr}
        >
          {images.map((item, idx) => {
            return (
              <div key={idx} className="image-wrapper-cover">
                <div style={{ backgroundImage: `url("${item.thumbnail}")` }} />
              </div>
            )
          })}
        </Slider>
      </div>
    </div>
  );
  }

export { SliderImage }
